import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[numericRange]'
})
export class NumericRangeDirective {
  @Input('min') min: number = 0;
  @Input('max') max: number = 999;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = parseFloat(input.value);
    if (isNaN(value)) {
      value = this.min;
    }

    if (value < this.min) {
      value = this.min;
    } else if (value > this.max) {
      value = this.max;
    }

    input.value = value.toString();
  }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = parseFloat(input.value);

    if (isNaN(value) || value < this.min) {
      value = this.min;
    } else if (value > this.max) {
      value = this.max;
    }

    input.value = value.toString();
  }
}
