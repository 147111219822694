import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tooltip-mult',
  templateUrl: './tooltip-mult.component.html',
  styleUrls: ['./tooltip-mult.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TooltipMultComponent implements OnInit {
  @Input() tooltip;
  classMatMenu: string;
  yPosition: string;
  xPosition: string;
  arrayPositions = ['top', 'left', 'right', 'bottom'];
  timedOutCloser;

  ngOnInit(): void {
    this.definePosition(this.tooltip?.position);
    this.defineClassMatMenu(this.tooltip?.position);
  }

  definePosition(position: string) {
    if (this.arrayPositions.indexOf(position) !== 1) {
      this.yPosition = (position === 'top' ? 'above' : 'below')
    }
  }

  defineClassMatMenu(position: string) {
    const indexPositon = this.arrayPositions.indexOf(position);
    if (indexPositon !== 1) {
      this.classMatMenu = (!this.tooltip?.icon ? 'tooltip-mult' : `tooltip-mult-${this.arrayPositions[indexPositon]}`);
    }
  }

  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }

}
