import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { NG_VALIDATORS } from "@angular/forms";
import { MatFormField, MAT_FORM_FIELD } from "@angular/material/form-field";

/**
 * The infinite Scroll Validation With icon directive can be used
 * in `mat-form-field` tags whith `infinite-scroll` or `select` child,
 * to do validation with x icon, cancel icon.
 *  All the code below is executed after the html is rendered in Angular.
 * When modifying the styling directive do the tests with styling after the html is rendered.
 * @public
 */

@Directive({
  selector: "mat-form-field[appHourSuffix]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: HourSuffixDirective,
      multi: true,
    },
  ],
})
export class HourSuffixDirective implements OnInit, AfterViewInit {
  flex: any = undefined;
  private readonly wrapper: any = undefined;
  @Input("appHourSuffix") text = "h";
  @Input() leftStyle: string;
  style: string;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(MAT_FORM_FIELD) private readonly mat: MatFormField
  ) {}

  ngAfterViewInit(): void {
    if (this.leftStyle !== undefined && this.leftStyle !== null) {
      this.applyStyle(this.leftStyle);
    }
  }

  ngOnInit(): void {
    this.style = `
      font-size: 16px;
      position: absolute;
      left: 50px;
      bottom: 0.0px;
      color:#CCCCCC;
    `;
    this.decideSuffix();
  }

  decideSuffix() {
    const nativeElement: HTMLElement = this.elementRef.nativeElement;
    const input = nativeElement.getElementsByTagName("input");

    if (input[0]) {
      input[0].style.letterSpacing = "0.05em";
      input[0].style.fontFamily = "Arial !important";
      input[0].insertAdjacentHTML(
        "afterend",
        `<span style="${this.style}">h</span>`
      );
      input[0].classList.add("appHourSuffix");
    }
  }

  applyStyle(style): void {
    const nativeElement: HTMLElement = this.elementRef.nativeElement;
    const input = nativeElement.getElementsByTagName("input");
    const appHourSuffixElement = nativeElement.querySelector(".appHourSuffix");

    if (input[0] && appHourSuffixElement) {
      const spanElement = input[0].nextElementSibling as HTMLElement;
      if (spanElement) {
        spanElement.style.left = style;
      }
    }
  }
}
