import { Component, Input } from "@angular/core";
import { TooltipInterface } from "../../../../../utils/models/miscelanea.interface";

@Component({
  selector: "tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponent {
  @Input() tooltip: TooltipInterface;
}
