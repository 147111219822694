import { Component, Input, OnChanges, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { PlotlyService } from './services/plotly-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'graphic-plotly',
  templateUrl: './graphic-plotly.component.html',
  styleUrls: ['./graphic-plotly.component.scss']
})
export class GraphicPlotlyComponent implements OnInit, OnChanges {
  @ViewChild('chartGenericPlot', { static: true }) chartGenericPlot: any;
  @Input() layoutGrafic = {};
  @Input() dataGrafic = [];
  @Input() configGrafic = [];
  @Input() idChart = 'chartGenericPlot'

  @Output() getClickPoint: EventEmitter<any> = new EventEmitter();
  @Output() getAnnotationsPoint: EventEmitter<any> = new EventEmitter();
  @Output() getEventHoverPoint: EventEmitter<any> = new EventEmitter();
  @Output() getEventUnHoverPoint: EventEmitter<any> = new EventEmitter();

  plotlyGrafic
  sub: Subscription;
  subSelect: Subscription;


  constructor(
    private readonly plotService: PlotlyService
  ) { }

  ngOnInit(): void {
    this.sub = this.plotService.exportEvent.subscribe((e) => {
      if (e) {
        this.updateGrafic(e)
      }
    })
    this.subSelect = this.plotService.selectEvent.subscribe((e) => {
      if (e) {
        this.selectEvent(e)
      }
    })
  }

  ngOnChanges() {
    this.initGrafic();
    this.plotlyGrafic = this.plotService.getPlotly();

    this.chartGenericPlot.nativeElement.on('plotly_click', (data: any) => {
      if (data.points[0].fullData.type === 'bar') {
        this.getClickPoint.emit(data.points[0]);
      }
    });
    this.chartGenericPlot.nativeElement.on('plotly_clickannotation', (data) => {
      this.getAnnotationsPoint.emit(data);
    })

    this.chartGenericPlot.nativeElement.on('plotly_hover', (data: any) => {
      this.getEventHoverPoint.emit(data);
    });

    this.chartGenericPlot.nativeElement.on('plotly_unhover', (data: any) => {
      this.getEventUnHoverPoint.emit(data);
    });

    const modeBarGroup = document.querySelectorAll('.modebar-group')

    modeBarGroup.forEach((item, index) => {
      const botoesModebar = item.querySelectorAll('.modebar-btn')
      botoesModebar[0]?.setAttribute('data-title', 'Aumentar zoom')
      botoesModebar[1]?.setAttribute('data-title', 'Diminuir zoom')
      botoesModebar[2]?.setAttribute('data-title', 'Voltar para visualização padrão')
    })
  }

  initGrafic() {
    if (this.configGrafic) {
      this.plotService.plotGrafic(this.chartGenericPlot.nativeElement, this.dataGrafic, this.layoutGrafic, this.configGrafic);
    } else {
      this.plotService.plotGrafic(this.chartGenericPlot.nativeElement, this.dataGrafic, this.layoutGrafic);
    }
  }

  updateGrafic(e) {
    const newLayout = {
      'mapbox.style': e
    };
    this.plotlyGrafic.update(this.chartGenericPlot.nativeElement, {}, newLayout, this.configGrafic);
  }

  selectEvent(selectedPoints) {
    const updatedData = this.dataGrafic
    updatedData[0].lon = selectedPoints.map(data => data.lon)
    updatedData[0].lat = selectedPoints.map(data => data.lat)
    updatedData[0].z = selectedPoints.map(data => data.value)
    updatedData[0].customdata = selectedPoints.map(data => data.id)

    updatedData[1].lon = selectedPoints.map(data => data.lon)
    updatedData[1].lat = selectedPoints.map(data => data.lat)

    this.plotlyGrafic.update(this.chartGenericPlot.nativeElement, updatedData, this.layoutGrafic, this.configGrafic);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.subSelect?.unsubscribe();
  }
}
