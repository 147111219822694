import { OverlayContainer } from '@angular/cdk/overlay';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import moment from 'moment';
import { ControlRange, Warning } from 'src/utils/models/input.interface';

@Component({
  selector: 'generic-picker-induxtry',
  templateUrl: './generic-picker.component.html',
  styleUrls: ['./generic-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GenericPickerComponent,
      multi: true,
    },
  ],
})
export class GenericPickerComponent implements OnInit, ControlValueAccessor, OnChanges,AfterContentChecked {
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;

  @ViewChildren('menuItem') menuItems: QueryList<any>;
  @ViewChildren('headerButton') headerButtons: QueryList<any>;

  @Input() set min(value: Date) {
    this._minYear = value?.getFullYear();
    this._minMonth = value?.getMonth();
    this._minDay = value?.getDate();
  }
  @Input() set max(value: Date) {
    this._maxYear = value?.getFullYear();
    this._maxMonth = value?.getMonth();
    this._maxDay = value?.getDate();
  }
  @Input() placeholder = 'placeholder';
  @Input() label = 'Label';
  @Input() subLabel = 'subLabel';
  @Input() styleLabel: any;
  @Input() styleSubLabel: any;
  @Input() mode = 'YEAR PERIOD';
  @Input() showTime: boolean;
  @Input() disabled = false;
  @Input() required = false;
  @Input() customClass: any;
  @Input() warning: Warning
  @Input() id: string;
  @Input() useEndDate = false;

  @Output() closed = new EventEmitter();

  _minYear!: number;
  _maxYear!: number;
  _minMonth!: number;
  _maxMonth!: number;
  _minDay!: number;
  _maxDay!: number;

  headerValue: string | number;

  currentMode!: string;

  options = [];

  pageOptions = [];

  valueRange = [];
  value = null;

  pageIndex = 0;

  pages = {};

  yearsHeader = [];

  isStartSelected = false;
  isEndSelected = false;
  ControlRange: ControlRange | null;

  monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Outubre',
    'Noviembre',
    'Diciembre',
  ];

  yearsAux: any;
  monthAux: any;
  dayAux: any;
  hourAux: any;
  minuteAux: any;
  indexAux: any;

  dateTimeYears: any;
  dateTimeMonth: any;
  dateTimeDay: any;
  dateTimeHour: any;
  dateTimeMinute: any;

  weekDays = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];

  indexActual = 0;

  after = false;
  before = false;
  openedMenu = false
  cdr
  momentDate = moment(new Date())

  writeValue(obj: any): void {
    if (obj) {
      this._writeValue(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  onChange: (obj: any) => void;

  constructor(private overlayContainer: OverlayContainer,  cdr: ChangeDetectorRef) {
    this.cdr = cdr;
  }

  ngOnInit(): void {
    this.pageOptions = this.createRange();
    this.setPages();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.max?.firstChange === false || changes?.min?.firstChange === false) {
      this.updateDisabledFlags();
    }
  }

  updateDisabledFlags(): void {
    if (this.mode === 'YEAR PERIOD' || this.mode === 'YEAR') {
      this.pageOptions.forEach(option => {
        option.dateOptions.forEach(item => {
          item.disabled = item.viewValue < this._minYear || item.viewValue > this._maxYear;
        });
      });
    } else if (this.mode === 'MONTH PERIOD' || this.mode === 'MONTH') {
      this.pageOptions.forEach(option => {
        option.months.forEach(item => {
          item.disabled = 
            option.year < this._minYear || option.year > this._maxYear ||
            (option.year === this._minYear && this.monthNames.indexOf(item.viewValue as string) < this._minMonth) ||
            (option.year === this._maxYear && this.monthNames.indexOf(item.viewValue as string) > this._maxMonth);
        });
      });
    } else if (this.mode === 'DAY PERIOD' || this.mode === 'DAY') {
      this.pageOptions.forEach(option => {
        option.days.forEach(item => {
          if (!item.blocked) { // Verifica se o item não está bloqueado
            item.disabled = 
              option.year < this._minYear || option.year > this._maxYear ||
              (option.year === this._minYear && option.month < this._minMonth) ||
              (option.year === this._maxYear && option.month > this._maxMonth) ||
              (option.year === this._minYear && option.month === this._minMonth && item.viewValue < this._minDay) ||
              (option.year === this._maxYear && option.month === this._maxMonth && item.viewValue > this._maxDay);
          }
        });
      });
    }
  }

  ngAfterViewInit() {
    this.matMenuTrigger.menuOpened.subscribe(() => {
      this.focusFirstItem();
    });

    this.matMenuTrigger.menuClosed.subscribe(() => {
      setTimeout(() => {
        this.setPages(true);
        this.openedMenu = false
      }, 100);
    });

    this.matMenuTrigger.menuOpened.subscribe(() => {
      setTimeout(() => {
        this.adjustMenuPosition();
      });
    });
  }

  adjustMenuPosition() {
    const overlayContainerElement = this.overlayContainer.getContainerElement();

    const menuElement = overlayContainerElement.querySelector('.mat-menu-panel') as HTMLElement;
    if (menuElement) {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const menuRect = menuElement.getBoundingClientRect();
      if (menuRect.left < 0) {
        menuElement.style.left = `0px`;
      }
      let soma = this.mode === 'DAY PERIOD'? 100 : 40;
      if (menuRect.bottom + soma > window.innerHeight) {
        const adjustment = menuRect.bottom + 100 - windowHeight;
        menuElement.style.top = `${menuElement.offsetTop - adjustment}px`;
      }
    }
  }
  setMode() {
    if (this.mode === 'YEAR' || this.mode === 'YEAR PERIOD') {
     return 'Ano';
    } else if (this.mode === 'DAY' || this.mode === 'DAY PERIOD') {
      return `${
        this.monthNames[this.pageOptions[this.indexActual].month]
      } de ${this.pageOptions[this.indexActual].year}`;
    } else if(this.mode === 'MONTH' || this.mode === 'MONTH PERIOD') {
      return `${this.pageOptions[this.indexActual].year}`
    } else {
      return new Date().getFullYear();
    }
  }

  createRange(): { viewValue: number | string }[] { 
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const startYear = 1900;
    const endYear = 3000;
    const arrayToReturn = [];
    let currentObject = { dateOptions: [] };

    if (this.mode === 'YEAR PERIOD' || this.mode === 'YEAR') {
      for (let year = startYear; year <= endYear; year++) {
        if (currentObject.dateOptions.length === 12) {
          arrayToReturn.push(currentObject);
          currentObject = { dateOptions: [] };
        }
        if (currentYear === year) {
          currentObject.dateOptions.push({
            viewValue: year,
            isCurrentDate: true,
            disabled: year < this._minYear || year > this._maxYear,
          });
        } else if (year < this._minYear || year > this._maxYear) {
          currentObject.dateOptions.push({ viewValue: year, disabled: true });
        } else {
          currentObject.dateOptions.push({ viewValue: year });
        }
      }
      if (currentObject.dateOptions.length > 0) {
        arrayToReturn.push(currentObject);
      }
      return arrayToReturn;
    } else if (this.mode === 'MONTH PERIOD' || this.mode === 'MONTH') {

      let arrayToReturn = [];
      for (let year = startYear; year <= endYear; year++) {
        let yearArray = [];
        for (let month = 0; month < 12; month++) {
      
          yearArray.push({
            viewValue: this.monthNames[month],
            isCurrentDate: currentYear === year && currentMonth === month,
            disabled: year <= this._minYear && month < this._minMonth || year >= this._maxYear && month > this._maxMonth ||
                      year < this._minYear || year > this._maxYear
          });

          if (
            currentYear === year &&
            currentMonth === month
          ) {
            this.indexActual = arrayToReturn.length;
          }

        }
        arrayToReturn.push({
          year: year,
          months: yearArray,
        });
      }
      return arrayToReturn;
    } else if (this.mode === 'DAY PERIOD' || this.mode === 'DAY') {
      let arrayToReturn = [];

      for (let year = startYear; year <= endYear; year++) {
        for (let month = 0; month < 12; month++) {
          const daysInMonth = new Date(year, month + 1, 0).getDate();
          let monthArray = [];

          // Obter o dia da semana do primeiro dia do mês
          const firstDayOfWeek = new Date(year, month, 1).getDay();

          // Calcular os dias do mês anterior
          const prevMonthDays = new Date(year, month, 0).getDate();

          // Adicionar dias do mês anterior antes do primeiro dia do mês atual, se necessário
          for (let i = 0; i < firstDayOfWeek; i++) {
            monthArray.unshift({ viewValue: prevMonthDays - i, disabled: true, blocked: true });
          }

          for (let day = 1; day <= daysInMonth; day++) {
            if (
              currentYear === year &&
              currentMonth === month &&
              day === currentDay
            ) {
              this.indexActual = arrayToReturn.length;
            }

            monthArray.push({
              viewValue: day,
              isCurrentDate:
                currentYear === year &&
                currentMonth === month &&
                day === currentDay,
                disabled: 
                year < this._minYear || year > this._maxYear ||
                year === this._minYear && month < this._minMonth || year === this._maxYear && month > this._maxMonth ||
                year === this._minYear && month === this._minMonth && day < this._minDay  || 
                year === this._maxYear && month === this._maxMonth && day > this._maxDay 
            });
          }

          // Obter o dia da semana do último dia do mês
          const lastDayOfWeek = new Date(year, month, daysInMonth).getDay();

          // Adicionar dias do próximo mês após o último dia do mês atual, se necessário
          for (let i = lastDayOfWeek + 1; i < 7; i++) {
            monthArray.push({ viewValue: i - lastDayOfWeek, disabled: true, blocked: true });
          }

          arrayToReturn.push({
            year: year,
            month: month,
            days: monthArray,
          });
        }
      }
      return arrayToReturn;
    }
  }

  //Selecionar Data
  isProcessing: boolean = false;
  selectionDate(event: any, value) {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;
    event?.stopPropagation();
    if (this.valueRange.length >= 2) {
      this.valueRange = [];
      this.isStartSelected = false;
      this.isEndSelected = false;
    }
    if (this.valueRange.length === 0) {
      if (this.mode === 'YEAR') {
        this.value = value;
        this.isStartSelected = true;
        this.isEndSelected = true;     
      } else if (this.mode === 'DAY') {
        this.dayAux = value;
        this.monthAux = this.pageOptions[this.indexActual].month;
        this.yearsAux = this.pageOptions[this.indexActual].year;
        this.indexAux = this.indexActual;
        this.isStartSelected = true;
        if (!this.showTime) {
          this.isEndSelected = true;
        }
        this.value = value;
      } else if (this.mode === 'MONTH') {
        this.monthAux = this.monthNames.indexOf(value);
        this.yearsAux = this.pageOptions[this.indexActual].year;
        this.indexAux = this.indexActual;
        this.isStartSelected = true;
        this.isEndSelected = true;
        this.value = this.monthNames.indexOf(value);
      } else if (this.mode === 'DAY PERIOD') {
        this.valueRange.push(value);
        this.ControlRange = {
          initial: {
            day: value,
            month: this.pageOptions[this.indexActual].month,
            year: this.pageOptions[this.indexActual].year,
            index: this.indexActual,
          },
          end: {
            day: null,
            month: null,
            year: null,
            index: null,
          },
        };
        this.isStartSelected = true;
      } else if (this.mode === 'MONTH PERIOD') {
        this.valueRange.push(value);
        this.ControlRange = {
          initial: {
            month: this.monthNames.indexOf(value),
            year: this.pageOptions[this.indexActual].year,
            index: this.indexActual,
          },
          end: {
            month: null,
            year: null,
            index: null,
          },
        };
        this.isStartSelected = true;
        this.isEndSelected = false;
      } else {
        this.valueRange.push(value);
        this.isStartSelected = true;
        this.isEndSelected = false;
      }
      if(this.id ==='alertHistoryTable'){
      this.max = new Date( this.pageOptions[this.indexActual].year,this.pageOptions[this.indexActual].month,value + 61) > new Date() ? new Date() : new Date( this.pageOptions[this.indexActual].year,this.pageOptions[this.indexActual].month,value + 61)
      this.updateDisabledFlags();
      }
    } else if (this.valueRange.length === 1) {
      if (this.mode === 'YEAR PERIOD') {
        if (value < this.valueRange[0]) {
          this.valueRange = [];
          this.valueRange[0] = value;
          this.isStartSelected = true;
        } else if (value > this.valueRange[0] || value < this.valueRange[0]) {
          this.valueRange.push(value);
          this.isEndSelected = true;
        }
      } else if (this.mode === 'MONTH PERIOD') {
        //Mês selecionado seja menor que o mês inicial
        if (
          this.pageOptions[this.indexActual]?.year < this.ControlRange?.initial?.year ||
          (this.pageOptions[this.indexActual]?.year === this.ControlRange?.initial?.year &&
          this.monthNames.indexOf(value) <= this.ControlRange?.initial?.month)
        ) {
          this.valueRange = [];
          this.valueRange[0] = value;
          this.ControlRange = {
            initial: {
              month: this.monthNames.indexOf(value),
              year: this.pageOptions[this.indexActual].year,
              index: this.indexActual,
            },
            end: {
              month: null,
              year: null,
              index: null,
            },
          };
          this.isStartSelected = true;
        }
        //Mês selecionado seja maior que o inicial
         else if (
          this.pageOptions[this.indexActual]?.year > this.ControlRange?.initial?.year ||
          (this.pageOptions[this.indexActual]?.year === this.ControlRange?.initial?.year &&
            this.monthNames.indexOf(value) > this.ControlRange?.initial?.month)
        ) {
          this.valueRange.push(value);
          this.isEndSelected = true;
          this.ControlRange.end = {
            month: this.monthNames.indexOf(value),
            year: this.pageOptions[this.indexActual].year,
            index: this.indexActual,
          };
        }

      } else if (this.mode === 'DAY PERIOD') {
        //Dia selecionado seja menor que o dia inicial
        if (
          this.pageOptions[this.indexActual]?.year < this.ControlRange?.initial?.year ||
          (this.pageOptions[this.indexActual]?.year === this.ControlRange?.initial?.year &&
            this.pageOptions[this.indexActual]?.month === this.ControlRange?.initial?.month &&
            value <= this.ControlRange?.initial?.day) ||
          (this.pageOptions[this.indexActual]?.year === this.ControlRange?.initial?.year &&
            this.pageOptions[this.indexActual]?.month < this.ControlRange?.initial?.month)
        ) {
          this.valueRange = [];
          this.valueRange[0] = value;
          this.ControlRange = {
            initial: {
              day: value,
              month: this.pageOptions[this.indexActual].month,
              year: this.pageOptions[this.indexActual].year,
              index: this.indexActual,
            },
            end: {
              day: null,
              month: null,
              year: null,
              index: null,
            },
          };
          this.isStartSelected = true;
        } 
        //Dia selecionado seja maior que o dia inicial
        else if (
          this.pageOptions[this.indexActual]?.year > this.ControlRange?.initial?.year ||
          (this.pageOptions[this.indexActual]?.year === this.ControlRange?.initial?.year &&
            this.pageOptions[this.indexActual]?.month === this.ControlRange?.initial?.month &&
            value > this.ControlRange?.initial?.day) ||
          (this.pageOptions[this.indexActual]?.year === this.ControlRange?.initial?.year &&
            this.pageOptions[this.indexActual]?.month > this.ControlRange?.initial?.month)
        ) {
          this.valueRange.push(value);
          this.isEndSelected = true;
          this.ControlRange.end = {
            day: value,
            month: this.pageOptions[this.indexActual].month,
            year: this.pageOptions[this.indexActual].year,
            index: this.indexActual,
          };
        }
      }
    }

    this.setConfig();
      if (this.isStartSelected && this.isEndSelected && this.openedMenu) {
        this?.closeCalendar();
      }
      this.isProcessing = false;
  
  }

  //Aplicar estilização
  setConfig() {
    if (this.mode === 'YEAR PERIOD') {
      this.pageOptions.forEach((item) => {
        for (const obj of item.dateOptions) {
          obj['isRange'] = false;
          if (obj.viewValue === this.valueRange[0]) {
            obj['startDate'] = true;
          } else if (obj.viewValue === this.valueRange[1]) {
            obj['endDate'] = true;
          } else {
            obj['startDate'] = false;
            obj['endDate'] = false;
          }
          if (
            obj.viewValue > this.valueRange[0] &&
            obj.viewValue < this.valueRange[1] &&
            !obj?.startDate &&
            !obj?.endDate
          ) {
            obj['isRange'] = true;
          }
        }
      });
    } else if (this.mode === 'MONTH PERIOD') {
      if (
        (this.ControlRange?.initial?.month !== null && this.ControlRange?.initial?.month !== undefined) && 
        !this.ControlRange?.end?.month && this.ControlRange?.end?.month !== 0 || 
        this.ControlRange?.initial?.month && this.ControlRange?.end?.month === null
      ) {
        const indexStart = this.ControlRange.initial.index;
        //Selecionar Data inicial
        this.pageOptions[indexStart]?.months?.forEach((item, index) => {
          if (this.monthNames.indexOf(item.viewValue) === this.ControlRange.initial.month) {
            item.startDate = true;
            item.isRange = false;
          } else {
            item.startDate = false;
            item.endDate = false;
            item.isRange = false;
          }
        });
      } else if (this.ControlRange.initial.month !== null  && this.ControlRange.end.month !== null ) {
        const indexStart = this.ControlRange.initial.index;
        const indexEnd = this.ControlRange.end.index;
        //Selecionar Data inicial
        this.pageOptions[indexStart].months?.forEach((item, index) => {
          if (this.monthNames.indexOf(item.viewValue) === this.ControlRange.initial.month) {
            item.startDate = true;
          } else {
            item.startDate = false;
          }
        });
        //Selecionar Data final
        this.pageOptions[indexEnd]?.months?.forEach((item, index) => {
          if (this.monthNames.indexOf(item.viewValue) === this.ControlRange.end.month) {
            item.endDate = true;
          } else {
            item.endDate = false;
          }
        });

        this.checkOptions();
      }
    } else if (this.mode === 'YEAR') {
      this.pageOptions.forEach((item) => {
        for (const obj of item.dateOptions) {
          if (obj.viewValue === this.value) {
            obj['uniqDate'] = true;
          } else {
            obj['uniqDate'] = false;
          }
        }
      });
    } else if (this.mode === 'MONTH') {
      for (const obj of this.options) {
        if (this.monthNames.indexOf(obj.viewValue) === this.value) {
          obj['uniqDate'] = true;
        } else {
          obj['uniqDate'] = false;
        }
      }
    } else if (this.mode === 'DAY') {
      for (const obj of this.options) {
        if (obj.viewValue === this.dayAux && !obj.disabled) {
          obj['uniqDate'] = true;
        } else {
          obj['uniqDate'] = false;
        }
      }
    } else if (this.mode === 'DAY PERIOD') {
      if (this.ControlRange.initial.day && !this.ControlRange.end.day) {
        const indexStart = this.ControlRange.initial.index;
        
        //Selecionar Data inicial
        this.pageOptions[indexStart].days?.forEach((item, index) => {
          if (item.viewValue === this.ControlRange.initial.day && !item.disabled) {
            item.startDate = true;
            item.isRange = false;
          } else {
            item.startDate = false;
            item.endDate = false;
          }
        });
      } else if (
        this.ControlRange.initial.day &&
        this.ControlRange.end.day
      ) {
        const indexStart = this.ControlRange.initial.index;
        const indexEnd = this.ControlRange.end.index;
        //Selecionar Data inicial
        this.pageOptions[indexStart].days?.forEach((item, index) => {
          if (item.viewValue === this.ControlRange.initial.day && !item.disabled) {
            item.startDate = true;
          } else {
            item.startDate = false;
          }
        });
        //Selecionar Data inicial
        this.pageOptions[indexEnd].days?.forEach((item, index) => {
          if (item.viewValue === this.ControlRange.end.day && !item.disabled) {
            item.endDate = true;
          } else {
            item.endDate = false;
          }
        });
      }
      this.checkOptions();
    }
  }

  getMonthIndex(month: string) {
    return this.monthNames.indexOf(month);
  }

  setPages(closedMenu?: boolean) { 
    const date = new Date();
    const currentYear = date.getFullYear();
    if (this.mode === 'YEAR PERIOD' || this.mode === 'YEAR') {
      for (const [idx, item] of this.pageOptions.entries()) {
        this.pages[`${idx}`] = item.dateOptions;
        const hasStartSelected = item.dateOptions.find(
          (item) => 
           this.mode === 'YEAR' ? item.uniqDate 
          :
           this.valueRange?.length === 1 ? item.startDate : item.endDate 
        );
        if (hasStartSelected) {
          this.options = this.pageOptions[idx].dateOptions;
          this.pageIndex = idx;
          break;
        } else if (
          item.dateOptions.find((item) => item.viewValue === currentYear)
        ) {
          this.options = this.pageOptions[idx].dateOptions;
          this.pageIndex = idx;
        }
      }
    } else if (this.mode === 'MONTH PERIOD' || this.mode === 'MONTH') {
      if(closedMenu){ //Caso fechar o menu
        const indexInitial = this.mode === 'MONTH' ? this.indexAux : this.ControlRange?.end?.index ? this.ControlRange?.end?.index : this.ControlRange?.initial?.index  
        this.indexActual = indexInitial ? indexInitial : this.indexActual;
      }
      this.options = this.pageOptions[this.indexActual]['months'];
      this.checkOptions();
    } else if (this.mode === 'DAY PERIOD' || this.mode === 'DAY') {
      if(closedMenu){
        const indexInitial = this.mode === 'DAY' ? this.indexAux : this.ControlRange?.end?.index ? this.ControlRange?.end?.index : this.ControlRange?.initial?.index
        this.indexActual = indexInitial ? indexInitial : this.indexActual;
      }
      this.options = this.pageOptions[this.indexActual].days;
      this.checkOptions();

    }
  }

  previousPage(event) {
    if (this.pageIndex > 0) {
      this.pageIndex -= 1;
    }
    if (this.mode === 'YEAR PERIOD' || this.mode === 'YEAR') {
      this.options = this.pages[`${this.pageIndex}`];
    } else if (this.mode === 'MONTH PERIOD' || this.mode === 'MONTH' || this.mode === 'DAY PERIOD' || this.mode === 'DAY') {
      this.options = this.generateCalendar('-');
      this.checkOptions();
    } 
  }

  nextPage(event) {
    if (this.mode === 'YEAR PERIOD' || this.mode === 'YEAR') {
      if (this.pageIndex < this.pageOptions.length - 1) {
        this.pageIndex += 1;
      }
      this.options = this.pages[`${this.pageIndex}`];
    } else if (this.mode === 'MONTH PERIOD' || this.mode === 'MONTH' || this.mode === 'DAY PERIOD' || this.mode === 'DAY') {
      this.options = this.generateCalendar('+')
      this.checkOptions();
    }
  }

  disableButton(value?) {
    let after: any
    let allDisabledAfter: any 
    let before: any
    let allDisabledBefore: any
    if(this.mode === 'DAY' || this.mode === 'DAY PERIOD') {
       after = this.pageOptions[this.indexActual + 1] 
       allDisabledAfter = after.days.every(day => day.disabled === true);
       before = this.pageOptions[this.indexActual - 1] 
       allDisabledBefore = before.days.every(day => day.disabled === true);

    } else if(this.mode === "MONTH" || this.mode === 'MONTH PERIOD') {
       after = this.pageOptions[this.indexActual + 1] 
       allDisabledAfter = after.months.every(month => month.disabled === true);

       before = this.pageOptions[this.indexActual - 1] 
       allDisabledBefore = before.months.every(month => month.disabled === true);

    } else if(this.mode === 'YEAR' || this.mode === 'YEAR PERIOD') {
       after = this.pageOptions[this.pageIndex + 1]?.dateOptions
       allDisabledAfter = after.every(year => year.disabled === true);

       before = this.pageOptions[this.pageIndex - 1]?.dateOptions
       allDisabledBefore = before?.every(year => year.disabled === true);

    }
    this.after = allDisabledAfter;
    this.before = allDisabledBefore;

    switch(value) {
      case 'after': 
      return this.after
      case 'before': 
      return this.before
    }
  }

  checkOptions() { 
    if (this.mode === 'DAY PERIOD') {
      if (this.valueRange.length === 1) {
        this.options.forEach((item) => {
          if (item.startDate !== true) {
            item.endDate = false;
            item.isRange = false;
          }
          if (this.indexActual !== this.ControlRange.initial.index) {
            item.startDate = false;
            item.endDate = false;
            item.isRange = false;
          }
        });
      } else if (this.valueRange.length === 2) {
        //Controlar seleção
        if (
          this.indexActual > this.ControlRange.initial.index &&
          this.indexActual <= this.ControlRange.end.index
        ) {
          let findEndDate = false;
          this.options.forEach((item) => {
            if (
              item.endDate !== true &&
              !findEndDate &&
              item.viewValue !== '' &&
              !item.disabled
            ) {
              item.isRange = true;
            } else if (item.endDate === true) {
              findEndDate = true;
            }
          });
        } else if (
          this.indexActual === this.ControlRange.initial.index &&
          this.indexActual === this.ControlRange.end.index
        ) {
          let startDate = this.ControlRange.initial.day;
          let endDate = this.ControlRange.end.day;
          this.options.forEach((item) => {
            if (
              item.viewValue > startDate &&
              item.viewValue < endDate &&
              item.viewValue !== '' && 
              !item.disabled
            ) {
              item.isRange = true;
            } else {
              item.isRange = false;
            }
          });
        } else if (
          this.indexActual < this.ControlRange.end.index &&
          this.indexActual >= this.ControlRange.initial.index
        ) {
          let startDate = this.ControlRange.initial.day;
          let endDate = this.ControlRange.end.day;
          this.options.forEach((item) => {
            if (item.viewValue > startDate && item.viewValue !== '' && !item.disabled) {
              item.isRange = true;
            } else {
              item.isRange = false;
            }
          });
        } else if (
          (this.indexActual > this.ControlRange.initial.index &&
            this.indexActual > this.ControlRange.end.index) ||
          this.indexActual < this.ControlRange.initial.index
        ) {
          this.options.forEach((item) => {
            item.isRange = false;
            item.startDate = false;
            item.endDate = false;
          });
        }
      }
    } else if (this.mode === 'DAY') {
      for (const obj of this.options) {
        if (
          obj.viewValue === this.dayAux &&
          this.indexActual === this.indexAux &&
          !obj.disabled
        ) {
          obj['uniqDate'] = true;
        } else {
          obj['uniqDate'] = false;
        }
      }
    }else if (this.mode === 'MONTH') {
      for (const obj of this.options) {
        if (
          this.monthNames.indexOf(obj.viewValue) === this.monthAux &&
          this.indexActual === this.indexAux &&
          !obj.disabled
        ) {
          obj['uniqDate'] = true;
        } else {
          obj['uniqDate'] = false;
        }
      }
    } else if (this.mode === 'MONTH PERIOD') {
      if (this.valueRange.length === 1) {
        this.options.forEach((item) => {
          if (item.startDate !== true) {
            item.endDate = false;
            item.isRange = false;
          }
          if (this.indexActual !== this.ControlRange.initial.index) {
            item.startDate = false;
            item.endDate = false;
            item.isRange = false;
          }
        });
      } else if (this.valueRange.length === 2) {
        //Controlar seleção
        if (
          this.indexActual > this.ControlRange.initial.index &&
          this.indexActual <= this.ControlRange.end.index
        ) {
          let findEndDate = false;
          this.options.forEach((item) => {
            if (
              item.endDate !== true &&
              !findEndDate &&
              item.viewValue !== ''
            ) {
              item.isRange = true;
            } else if (item.endDate === true) {
              findEndDate = true;
            }
          });
        } else if (
          this.indexActual === this.ControlRange.initial.index &&
          this.indexActual === this.ControlRange.end.index
        ) {
          let startDate = this.ControlRange.initial.month;
          let endDate = this.ControlRange.end.month;
          this.options.forEach((item) => {
            if (
              this.monthNames.indexOf(item.viewValue) > startDate &&
              this.monthNames.indexOf(item.viewValue) < endDate &&
              item.viewValue !== ''
            ) {
              item.isRange = true;
            } else {
              item.isRange = false;
            }
          });
        } else if (
          this.indexActual < this.ControlRange.end.index &&
          this.indexActual >= this.ControlRange.initial.index
        ) {
          let startDate = this.ControlRange.initial.month;
          let endDate = this.ControlRange.end.month;
          this.options.forEach((item) => {
            if (this.monthNames.indexOf(item.viewValue) > startDate && item.viewValue !== '') {
              item.isRange = true;
            } else {
              item.isRange = false;
            }
          });
        } else if (
          (this.indexActual > this.ControlRange.initial.index &&
            this.indexActual > this.ControlRange.end.index) ||
          this.indexActual < this.ControlRange.initial.index
        ) {
          this.options.forEach((item) => {
            item.isRange = false;
            item.startDate = false;
            item.endDate = false;
          });
        }
      }
    }
  }

  closeCalendar() { 
    this.matMenuTrigger?.closeMenu();
    this.isStartSelected = false;
    this.isEndSelected = false;
    let objectToEmit: any;
    if (this.mode === 'YEAR PERIOD') {
      objectToEmit = {
        start_period: this.useEndDate ? new Date(this.valueRange[0], 11, 31) : new Date(this.valueRange[0], 0, 1),
        end_period: this.useEndDate ? new Date(this.valueRange[1], 11, 31) : new Date(this.valueRange[1], 0, 1),
      };
    } else if (this.mode === 'MONTH PERIOD') {
      const yearStart = Number(this.ControlRange.initial.year);
      const yearEnd = Number(this.ControlRange.end.year);
      const start = this.getMonthIndex(this.valueRange[0]);
      const end = this.getMonthIndex(this.valueRange[1]);


      const lastDayOfMonth = new Date(yearEnd, end + 1, 0).getDate(); 
      objectToEmit = {
        start_period: new Date(yearStart, start),
        end_period: new Date(yearEnd, end, lastDayOfMonth),
      };

    } else if (this.mode === 'DAY PERIOD') {
      const start = this.valueRange[0];
      const end = this.valueRange[1];

      objectToEmit = {
        start_period: new Date(
          this.ControlRange?.initial?.year,
          this.ControlRange?.initial?.month,
          start
        ),
        end_period: new Date(
          this.ControlRange?.end?.year,
          this.ControlRange?.end?.month,
          end
        ),
      };
    } else if (this.mode === 'YEAR') {
      objectToEmit = this.useEndDate ?  new Date(this.value, 11, 31) :  new Date(this.value, 0, 1);
    } else if (this.mode === 'MONTH') {
      objectToEmit = new Date(this.yearsAux, this.value)
    } else if (this.mode === 'DAY') {
      if (this.showTime) {
        objectToEmit = new Date(
          this.yearsAux,
          this.monthAux,
          this.dayAux,
          this.hourAux,
          this.minuteAux
        );
      } else {
        objectToEmit = new Date(
          this.yearsAux,
          this.monthAux,
          this.dayAux
        );
      }
    }
    this.onChange(objectToEmit);
    this?.closed?.emit(objectToEmit);
  }

  openCalendar() {
    this.openedMenu = true;
    if (!this.hourAux && !this.minuteAux) {
      const date = new Date();
      this.hourAux = date.getHours();
      this.minuteAux = date.getMinutes();
    }
    this.matMenuTrigger.openMenu();
  }

  changeView(event) {
    event.stopPropagation();
  }

  focusFirstItem() {
    setTimeout(() => {
      const firstMenuItem = this.headerButtons.first;
      if (firstMenuItem) {
        firstMenuItem.nativeElement.focus();
      }
    });
  }

  handleKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    const headerButtonsArray = this.headerButtons.toArray();
    const menuItemsArray = this.menuItems.toArray();
    const activeElement = document.activeElement;
    const activeHeaderIndex = headerButtonsArray.findIndex(
      (item) => item.nativeElement === activeElement
    );
    const activeMenuItemIndex = menuItemsArray.findIndex(
      (item) => item._elementRef.nativeElement === activeElement
    );

    if (event.key === 'Tab') {
      event.preventDefault();
      let nextIndex = (activeHeaderIndex + 1) % headerButtonsArray.length;
      headerButtonsArray[nextIndex].nativeElement.focus();
    } else if (
      ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)
    ) {
      event.preventDefault();

      let nextIndex = activeMenuItemIndex;

      if (activeMenuItemIndex === -1) {
        nextIndex = menuItemsArray.findIndex(
          (item) => !item._elementRef.nativeElement.disabled
        );
      } else {
        if (event.key === 'ArrowUp') {
          do {
            nextIndex =
              nextIndex > 0 ? nextIndex - 1 : menuItemsArray.length - 1;
          } while (
            menuItemsArray[nextIndex]._elementRef.nativeElement.disabled
          );
        } else if (event.key === 'ArrowDown') {
          do {
            nextIndex = (nextIndex + 1) % menuItemsArray.length;
          } while (
            menuItemsArray[nextIndex]._elementRef.nativeElement.disabled
          );
        } else if (event.key === 'ArrowLeft') {
          do {
            nextIndex =
              nextIndex > 0 ? nextIndex - 1 : menuItemsArray.length - 1;
          } while (
            menuItemsArray[nextIndex]._elementRef.nativeElement.disabled
          );
        } else if (event.key === 'ArrowRight') {
          do {
            nextIndex = (nextIndex + 1) % menuItemsArray.length;
          } while (
            menuItemsArray[nextIndex]._elementRef.nativeElement.disabled
          );
        }
      }

      if (
        nextIndex !== -1 &&
        !menuItemsArray[nextIndex]._elementRef.nativeElement.disabled
      ) {
        menuItemsArray[nextIndex]._elementRef.nativeElement.focus();
      }
    } else if (event.key === 'Escape') {
      this?.closeCalendar();
    }
  }


  formatValue() {
    if (this.mode === 'YEAR PERIOD') {
      if (this.valueRange.length > 1) {
        return `${this.valueRange[0]} hasta ${this.valueRange[1]}`;
      } else if (this.valueRange.length === 1) {
        return `${this.valueRange[0]} hasta `;
      }
      return '';
    } else if (this.mode === 'MONTH PERIOD') {

      const startDate = new Date(
        this.ControlRange?.initial.year,
        this.ControlRange?.initial.month
      );
      const endDate = new Date(
        this.ControlRange?.end.year,
        this.ControlRange?.end.month
      );

      const formattedStartDate = `${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;

      const formattedEndDate = `${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;


      if (this.valueRange.length > 1) {
        return `${formattedStartDate} hasta ${formattedEndDate}`;
      } else if (this.valueRange.length === 1) {
        return `${formattedStartDate} hasta `;
      }
      return '';

    } else if (this.mode === 'DAY PERIOD') {
      const start = this.valueRange[0];
      const end = this.valueRange[1];

      const formatMonthYear = (monthIndex, year) => {
        const date = new Date(this.yearsAux, monthIndex);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${month}/${year}`;
      };

      const startDate = new Date(
        this.ControlRange?.initial.year,
        this.ControlRange?.initial.month,
        this.ControlRange?.initial.day
      );
      const endDate = new Date(
        this.ControlRange?.end.year,
        this.ControlRange?.end.month,
        this.ControlRange?.end.day
      );

      const formattedStartDate = `${startDate
        .getDate()
        .toString()
        .padStart(2, '0')}/${(startDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${startDate.getFullYear()}`;

      const formattedEndDate = `${endDate
        .getDate()
        .toString()
        .padStart(2, '0')}/${(endDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${endDate.getFullYear()}`;

      if (this.valueRange.length > 1) {
        return `${formattedStartDate} hasta ${formattedEndDate}`;
      } else if (this.valueRange.length === 1) {
        return `${formattedStartDate} hasta `;
      }
      return '';
    } else if (this.mode === 'YEAR') {
      return this.value;
    } else if (this.mode === 'MONTH') {
      const date = new Date(
        this.yearsAux,
        this.monthAux
      );

      const formattedDate = `${(date?.getMonth() + 1).toString().padStart(2, '0')}/${date?.getFullYear()}`;
      if (isNaN(date?.getTime())) {
        return null;
      } else {
        return formattedDate;
      }
    } else if (this.mode === 'DAY') {
      let date: Date;
      let formattedDate;
      if (this.showTime) {
        date = new Date(
          this.dateTimeYears,
          this.dateTimeMonth,
          this.dateTimeDay,
          this.dateTimeHour,
          this.dateTimeMinute
        );
        formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${date.getFullYear()} ${date
          .getHours()
          .toString()
          .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      } else {
        date = new Date(
          this.yearsAux,
          this.monthAux,
          this.dayAux
        );
        formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${date.getFullYear()}`;
      }
      if (isNaN(date?.getTime())) {
        return null;
      } else {
        return formattedDate;
      }
    }
  }

  generateCalendar(value: string) {
    switch (value) {
      case '+':
        this.indexActual = this.indexActual + 1; // Incrementa o valor de indexActual
        break;
      case '-':
        this.indexActual = this.indexActual - 1; // Decrementa o valor de indexActual
        break;
    }

    // Garantir que indexActual está dentro do intervalo válido
    if (this.indexActual < 0) {
      this.indexActual = 0;
    } else if (this.indexActual >= this.pageOptions.length) {
      this.indexActual = this.pageOptions.length - 1;
    }

    if (this.mode === 'MONTH PERIOD' || this.mode === 'MONTH') {
      return this.pageOptions[this.indexActual]?.months || [];
    } else {
      return this.pageOptions[this.indexActual]?.days || [];
    }
  }

  changeDateTimePicker(event) {
    this.hourAux = event.hour();
    this.minuteAux = event.minute();
  }

  genericButton(event) {
  switch (event[1]) {
    case '1':
      this.dateTimeYears = this.yearsAux;
      this.dateTimeMonth = this.monthAux;
      this.dateTimeDay = this.dayAux;
      this.dateTimeHour = this.hourAux;
      this.dateTimeMinute = this.minuteAux;
      this.closeCalendar();
    break;
  }
  }

  stopPropagate(event) {
    if(this.showTime){
      event.stopPropagation();
    }
  }


  private _writeValue(date: any): any {
    if (!date) {
      return;
    }

    let startDate: any
    let endDate: any
    let indexInitial 
    let indexEnd
    switch (this.mode) {
      case 'DAY':
        if (date instanceof Date) {
          const year = date.getFullYear();
          const month = date.getMonth();
          const day = date.getDate();
          const hour = date.getHours();
          const minute = date.getMinutes();
          this.yearsAux = year;
          this.monthAux = month;
          this.dayAux = day;
          this.hourAux = hour;
          this.minuteAux = minute;
          this.dateTimeYears = year;
          this.dateTimeMonth = month;
          this.dateTimeDay = day;
          this.dateTimeHour = hour;
          this.dateTimeMinute = minute;
          if(this.showTime) {
            this.momentDate = moment(date);
          }
            this.indexActual = this.pageOptions.findIndex(item => item.year === year && item.month === month)
          this.options = this.pageOptions[this.indexActual]?.days
          this.selectionDate(null, day)
        }
      break;
      case 'YEAR':
        if (date instanceof Date) {
          const year = date.getFullYear();
          const index = this.getIndexYear(year);
          this.options = this.pageOptions[index]?.dateOptions
          this.pageIndex = index
          this.value = year;
          this.selectionDate(null, year)
        }
      break;
      case 'MONTH':
        if (date instanceof Date) {
          const year = date.getFullYear();
          const month = date.getMonth();
          this.yearsAux = year;
          this.monthAux = month;

          this.indexActual = this.pageOptions.findIndex(item => item.year === year)

          this.options = this.pageOptions[this.indexActual]?.months
          this.selectionDate(null, this.monthNames[month])
          // this.checkOptions();
        }
      break;
      case 'DAY PERIOD' :
        startDate = new Date(date?.startDate)
        endDate = new Date(date?.endDate)
        indexInitial = this.pageOptions.findIndex(item => item.year === startDate.getFullYear() && item.month === startDate.getMonth());
        indexEnd = this.pageOptions.findIndex(item => item.year === endDate.getFullYear() && item.month === endDate.getMonth())
  
        this.indexActual = indexInitial;
        this.selectionDate(null, startDate.getDate())
        this.indexActual = indexEnd;
        this.selectionDate(null, endDate.getDate())

        this.options = this.pageOptions[indexEnd? indexEnd : indexInitial]?.days
        this.checkOptions();
      break;
      case 'MONTH PERIOD' :
        startDate = new Date(date?.startDate)
        endDate = new Date(date?.endDate)
        indexInitial = this.pageOptions.findIndex(item => item.year === startDate.getFullYear())
        indexEnd = this.pageOptions.findIndex(item => item.year === endDate.getFullYear())
        this.valueRange = [startDate.getMonth(), endDate.getMonth()]

        this.indexActual = indexInitial
        this.selectionDate(null, this.monthNames[startDate.getMonth()])
        this.indexActual = indexEnd
        this.selectionDate(null, this.monthNames[endDate.getMonth()])
        this.options = this.pageOptions[indexEnd? indexEnd : indexInitial]?.months
        this.checkOptions();
      break;
      case 'YEAR PERIOD' :
        startDate = new Date(date?.startDate)
        endDate = new Date(date?.endDate)
        const index = this.getIndexYear(endDate.getFullYear());
        this.selectionDate(null, startDate.getFullYear())
        this.selectionDate(null, endDate.getFullYear())
        this.options = this.pageOptions[index]?.dateOptions
        this.pageIndex = index
      break;
    }
  }

  getIndexYear(year){
    for (const [idx, item] of this.pageOptions.entries()) {
      this.pages[`${idx}`] = item.dateOptions;
      const hasStartSelected = item.dateOptions.find(dateOption => dateOption.viewValue === year);
      if (hasStartSelected) {
        return idx
      }
    }
  }

}
