import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-loading-sec',
  templateUrl: './modal-loading-sec.component.html',
  styleUrls: ['./modal-loading-sec.component.scss']
})
export class ModalLoadingSecComponent   {

  constructor(public dialogRef: MatDialogRef<ModalLoadingSecComponent>) { }



}
