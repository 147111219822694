import { Router } from "@angular/router";
import { Component, Input, EventEmitter, Output } from "@angular/core";

import { Brand, PageTitle } from "../../../../../utils/models/layout.interface";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map } from "rxjs/operators";

@Component({
  selector: "app-topnavbar",
  templateUrl: "./topnavbar.component.html",
  styleUrls: ["./topnavbar.component.scss"],
})
export class TopnavbarComponent {
  @Input() pageTitles: PageTitle[];
  @Input() title: string;
  @Input() breakpoint: boolean;
  @Input() brand: Brand;
  @Input() link;
  @Input() loadingOtimization
  @Output() collapse = new EventEmitter();
  @Output() changeGantt = new EventEmitter();

  @Input() drawer: any;

  fullScreen = false;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    public router: Router,
    private readonly breakpointObserver: BreakpointObserver
  ) {}

  selectTitle(path: string): Object {
    return this.pageTitles.find((page) => page.path === path)
      ? this.pageTitles.find((page) => page.path === path).name.split("-")
      : null;
  }

  returnTitleTopNavbar(obj: any): string {
    return obj?.length > 0 ? obj[obj.length - 1].trim() : "No Title";
  }

  selectSubTitle(path: string): Object {
    return this.pageTitles.find((page) => page.path === path) && this.pageTitles.find((page) => page.path === path)?.subName
      ? this.pageTitles.find((page) => page.path === path)?.subName?.split("-")
      : null;
  }


  toggleFullScreen(): void {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        this.fullScreen = true;
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          this.fullScreen = false;
        });
      }
    }
  }

  sendCollapse() {
    this.collapse.emit(true);
  }

  openMenu() {
    this.drawer.toggle();
  }

  handleClick(value1, value2) {
    const indexOf = value2.indexOf(value1);
    const newArray = indexOf !== -1 ? value2.slice(0, indexOf + 1) : value2;

    let resultado = newArray.join("-");
    resultado = resultado.replace(/\s+$/, "");

    let breakpoint: string;
    this.pageTitles.forEach((item, index) => {
      if (item.name === resultado) {
        breakpoint = item.path;
      }
    });
    if (breakpoint) {
      this.router.navigate([breakpoint]);
    }
  }
}
