import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModalLoadingComponent } from "src/app/shared/components/loads/modal-loading/modal-loading.component";

export function sleep(ms: number): Promise<number> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function modalLoading(
  dialog: MatDialog,
  newTitle?: string,
  refresh?: boolean
): MatDialogRef<ModalLoadingComponent> {
  return dialog.open(ModalLoadingComponent, {
    autoFocus: false,
    disableClose: true,
    width: refresh ? "360px" : "400px",
    data: {
      refresh,
      disableClose: false,
      title: newTitle ? newTitle : "Carregando",
      text: refresh
        ? `Por favor, volte em alguns minutos.
        Quando finalizarmos, o campo “Data
        da atualização” será atualizado com
        a data mais recente.`
        : "Por favor espere alguns segundos",
    },
    panelClass: "loading-modal",
  });
}

export const magicNumbers = {
  NUMBER0: 0,
  NUMBER1: 1,
  NUMBER2: 2,
  NUMBER3: 3,
  NUMBER4: 4,
  NUMBER5: 5,
  NUMBER6: 6,
  NUMBER7: 7,
  NUMBER8: 8,
  NUMBER9: 9,
  NUMBER10: 10,
  NUMBER11: 11,
  NUMBER12: 12,
  NUMBER13: 13,
  NUMBER14: 14,
  NUMBER15: 15,
  NUMBER20: 20,
  NUMBER24: 24,
  NUMBER25: 25,
  NUMBER30: 30,
  NUMBER40: 40,
  NUMBER45: 45,
  NUMBER50: 50,
  NUMBER60: 60,
  NUMBER100: 100,
  NUMBER140: 140,
  NUMBER300: 300,
  NUMBER400: 400,
  NUMBER450: 450,
  NUMBER500: 500,
  NUMBER580: 580,
  NUMBER1850: 1850,
  NUMBER2000: 2000,
  NUMBER5000: 5000,
  NUMBER6000: 6000,
  NUMBER30000: 30000,
  NUMBER60000: 60000,
};
