import { Component,  Input} from '@angular/core';

@Component({
  selector: 'app-sidenav-mobile-control',
  templateUrl: './sidenav-mobile-control.component.html',
  styleUrls: ['./sidenav-mobile-control.component.scss']
})
export class SidenavMobileControlComponent   {

  @Input() drawer: any;



}
