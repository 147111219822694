import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InformationInterface } from 'src/utils/models/common.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import routes from './routes/routes.json'
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent implements OnInit, OnDestroy {
  @ViewChild("popOver") public popover: NgbPopover;
  private readonly unsubscribeAll$: Subject<Event> = new Subject<Event>();
  
  infoInterface: InformationInterface;

  constructor(private readonly router: Router) { 
    this.changeInterfaceInfo(this.router)
  }
  
  ngOnDestroy(): void {
    this.unsubscribeAll$.next()
    this.unsubscribeAll$.complete()
  }

  ngOnInit(): void {
    this.routerHandle()
  }

  routerHandle() {
    this.router.events.pipe(takeUntil(this.unsubscribeAll$)).subscribe((route) => {
      if(route instanceof NavigationStart) {
        this.changeInterfaceInfo(route)
      }
    })
  }

  changeInterfaceInfo(route: any) {
    this.infoInterface = routes[route.url] || routes['default'];
  }

  closeIcon() {
    this.popover?.close()
  }
}
