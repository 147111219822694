import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "src/app/service/http/http.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  private readonly apiUrl: string = `inventories/`;

  constructor(private http: HttpService) {}

  filterFamiliesGraph(): Observable<any> {
    return this.http.genericGet(`${this.apiUrl}uniqueAttributes`);
  }

  getFamilyChartData(type: string, family?: string): Observable<any> {
    const url =
      type === "family"
        ? `${this.apiUrl}generateFamilyChart?type=${type}`
        : `${this.apiUrl}generateFamilyChart?type=${type}&family=${family}`;
    return this.http.genericGet(url);
  }

  getFamilies(type, familySelected): Observable<any> {
    const url =
      type === "subFamily"
        ? `/inventories/uniqueAttributes${type}?family=${familySelected}`
        : `/inventories/uniqueAttributes${type}?subFamily=${familySelected}`;
    return this.http.genericGet(url);
  }

  getFamiliesByBusiness(type, business): Observable<any> {
    const url = `/inventories/uniqueAttributes${type}?business=${business}`;
    return this.http.genericGet(url);
  }

  filterFamilyChart(payload, type) {
    let url = `${this.apiUrl}generateFamilyChart?type=${type}`;
    if (payload.family) {
      url = url + `&family=${payload.family}`;
    }
    if (payload.subFamily) {
      url = url + `&subFamily=${payload.subFamily}`;
    }
    if (payload.center) {
      url = url + `&center=${payload.center}`;
    }
    if (payload.business) {
      url = url + `&business=${payload.business}`;
    }

    return this.http.genericGet(url);
  }

  getStock() {
    const url = `inventories/getRestockAlerts`;
    return this.http.genericGet(url);
  }

  getCardsInfos(payload?) {
    let url = `inventories/getAdditionalInfomation`;
    if (payload) {
      url += "?";
    }
    if (payload?.center) {
      url += `&center=${payload.center}`;
    }
    if (payload?.business) {
      url += `&business=${payload.business}`;
    }
    if (payload?.family) {
      url += `&family=${payload.family}`;
    }
    if (payload?.subFamily) {
      url += `&subFamily=${payload.subFamily}`;
    }
    url = url.replace("?&", "?");

    return this.http.genericGet(url);
  }

  getAllFamily() {
    const url = `/inventories/uniqueAttributesFamily`;
    return this.http.genericGet(url);
  }

  getAllSubFamily() {
    const url = `/inventories/uniqueAttributesSubFamily`;
    return this.http.genericGet(url);
  }
}
