import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { Breadcrumb } from '../../../../../utils/models/layout.interface';

@Component({
  selector: 'app-topnavbar-breadcrumb',
  templateUrl: './topnavbar-breadcrumb.component.html',
  styleUrls: ['./topnavbar-breadcrumb.component.scss']
})
export class TopnavbarBreadcrumbComponent implements OnInit {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = {icon: 'pi pi-home', url: 'home'};
  menuItems: Breadcrumb[];

  constructor(private readonly router: Router, private readonly sactivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.sactivatedRoute.root);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.menuItems = this.createBreadcrumbs(this.sactivatedRoute.root);
       });
  }

  createBreadcrumbs(route: ActivatedRoute, url = '#', breadcrumbs: any[] = []) {
    const children: ActivatedRoute[] = route.children;

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[TopnavbarBreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!!label) {
        breadcrumbs.push({label, url});
      }
    }

    return breadcrumbs;
  }

  getScenarioName() {
    return sessionStorage.getItem('cenarioName');
  }

  isRouterRoot() {
    return this.router.url === '/';
  }

}
