import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[nonEmptyPositiveNumber]'
})
export class NonEmptyPositiveNumberDirective {
  private regex: RegExp = new RegExp(/^[0-9]*$/);
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value;

    // Remove qualquer caractere não numérico (exceto o ponto decimal)
    value = value.replace(/[^0-9.]/g, '');

    // Garante que o valor não seja vazio ou menor que 0
    if (value === '' || parseFloat(value) < 0) {
      value = '0';
    }

    input.value = value;
  }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value === '' || parseFloat(input.value) < 0) {
      input.value = '0';
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    if (!this.regex.test(event.key)) {
      event.preventDefault();
    }
  }
}
