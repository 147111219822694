import { Component, Input, OnInit, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { ButtonInduxtry, MultiSelectInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';


const MAX_NUMBER = 3;
@Component({
  selector: 'multiselect-input',
  templateUrl: './multiselect-input.component.html',
  styleUrls: ['./multiselect-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiselectInputComponent,
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'overlay-container' }
    }
  ],
})
export class MultiselectInputComponent implements OnInit, ControlValueAccessor, OnChanges {
  warningIconMultiselectInput = returnWarningIcon;
  colorWarningMultiselectInput = returnColorWarning;
  textWarningMultiselectInput = returnTextWarning;

  @ViewChild('multipleSelectInput') multipleSelectInput: MatSelect;

  @Input() multiSelectInput: MultiSelectInput;
  @Input() loading = false;
  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  private _showMax = MAX_NUMBER;
  @Input()
  get max(): number {
    return this._showMax;
  }
  set max(max: number) {
    this._showMax = max;
  }

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() endScroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() page: EventEmitter<number> = new EventEmitter<number>();

  buttonInterfaceElongated: ButtonInduxtry;
  valuePage = 1;
  allOptions = false;

  _changeValue = false;


  writeValue(value: any): void {
    this.multiSelectInput.selectedItem = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    this.buttonInterfaceElongated = { label: 'LIMPAR', elongated: true, customStyle: 'secondary', showLabel: true }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMuliSelect('change');
  }

  ngDoCheck(): void {
    this.updateMuliSelect('check');
  }

  updateMuliSelect(event:string){
    const isSelect = this.multiSelectInput?.selectedItem?.find(item => item['viewValue'] === 'All Options')
    if (isSelect && this.multipleSelectInput ) {
      if(event === 'check'){
        const allOptions = this.multipleSelectInput.options.toArray();
        allOptions.forEach((option) => {
          option.disabled = (option.viewValue === 'All Options' ? false : true);
        });
      } else if(event === 'change') {
        this.setNewValue({value: [{value: null , viewValue: 'All Options'}]})
      }
    }
  }

  setNewValue(option) {
    let newOption = option.value.filter(item => item !== undefined)
    const allOptions = this.multipleSelectInput.options.toArray();
    const isSelect = this.multiSelectInput.selectedItem.find(item => item['viewValue'] === 'All Options')

    if(isSelect) {
      newOption = []
      allOptions.forEach(item => {
        const teste = option.value.find(item => item.viewValue === 'All Options')
        if(item.viewValue === teste.viewValue) {
          item.disabled = false;
        } else {
          item.disabled = true;
          item.deselect();
        }
      });

      this.multiSelectInput.options.forEach((item) => {
        if(item.viewValue !== 'All Options' && !item.disabled) {
          newOption.push(item)
        }
      })


    } else {
      allOptions.forEach(item => item.disabled = false)
    }

    this.onChange(option.value);
    this.selectionChange.emit(newOption);
  }

  removeOptionSelected(ev: Event, option) {
    if (!this.multiSelectInput.disabled) {
      ev.stopPropagation();
      this.multipleSelectInput.options.forEach(opt => {
        if (opt.value.value === option.value) {
          opt.deselect();
        }
      });
    }
  }

  infiniteScroll() {
    if(!this.loading){
      this.valuePage ++
      this.endScroll.emit(true)
      this.page.emit(this.valuePage)
    }
  }

  clearOptions(value) {
    this.multiSelectInput.selectedItem = undefined;
    const allOptions = this.multipleSelectInput.options.toArray();
    allOptions.forEach((option, index) => {
      option.deselect();
      option.disabled = false
    });

    this.selectionChange.emit([]);
  }
}
