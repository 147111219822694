import { ToastInterface } from "./../../../utils/models/miscelanea.interface";
import { ToastrService } from "ngx-toastr";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModalLoadingComponent } from "src/app/shared/components/loads/modal-loading/modal-loading.component";
import { PlotlyService } from "../components/graphics/graphic-plotly/services/plotly-service";
import html2canvas from "html2canvas";
import mergeImages from "merge-base64";
import moment from "moment";
import saveAs from 'save-as'

export function toastrMessage(
  toast: ToastrService,
  toastInterface: ToastInterface
) {
  toast.show(toastInterface.description, toastInterface.title, {
    timeOut: toastInterface.timeOut || 5000,
    messageClass: toastInterface.type,
    titleClass: JSON.stringify({}),
  });
}

export function openModalLoading(
  dialog: MatDialog,
  title = "Cargando",
  text = "Por favor espere unos segundos",
  disableClose = true,
  keepBrowsing = false
): MatDialogRef<ModalLoadingComponent> {
  return dialog.open(ModalLoadingComponent, {
    width: "25rem",
    disableClose,
    data: {
      title,
      text,
      keepBrowsing,
    },
  });
}

export function changeOptionSelectInput(
  options,
  queryListField,
  nameFieldChange
) {
  const selectField = queryListField.selectInput.find(
    (select) => select?.selectInput?.control === nameFieldChange
  );
  if (selectField) {
    selectField.selectInput.options = options;
  }
}

export function downloadBlobResponse(xlsxFileName: string, data, type) {
  const file = new Blob([data], { type: data.type });
  const blob = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = blob;
  const fileName = `${xlsxFileName}`;
  link.download = `${fileName}.${type}`;
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
  const cleanTime = 3000;
  setTimeout(() => {
    window.URL.revokeObjectURL(blob);
    link.remove();
  }, cleanTime);
}

export function limitDataGraphics(dataArray, limitData) {
  let arrayValue = dataArray;
  const keysLimit = limitData.filter((res) => res.hidden);
  if (keysLimit.length > 0) {
    arrayValue = dataArray.filter((res) => {
      const limitItem = keysLimit.findIndex((item) => item.key === res.name);
      return limitItem === -1 ? true : false;
    });
  }
  return arrayValue;
}

export function printGenerico(dialog, viewChild, nomeImagem): void {
  const element = viewChild.nativeElement;

  html2canvas(element).then(canvas => {
    canvas.toBlob(blob => {
      if (blob) {
        saveAs(blob, nomeImagem);
      }
      dialog.closeAll();
    });
  });
}
export function printGenericoScroll(dialog, viewChild, nomeImagem,tamanhoElement): void {
  const element = viewChild.nativeElement;
  const originalWidth = element.style.width
  element.style.width = tamanhoElement
  

  html2canvas(element).then(canvas => {
    canvas.toBlob(blob => {
      if (blob) {
        saveAs(blob, nomeImagem);
      }
      dialog.closeAll();
      element.style.width = originalWidth
    });
  });
}

export function downloadGraphicPloty(
  id: string,
  name: string,
  legendId: string
) {
  const plotlyService = new PlotlyService();
  const plotly = plotlyService.getPlotly();

  plotly.toImage(id, { format: "png" }).then(async (url) => {
    downloadLegend(legendId).then(async (legendImage: string) => {
      const graphic = url.replace("data:image/png;base64,", "");
      const legend = legendImage.replace("data:image/png;base64,", "");
      const mergedImages = await mergeImages([graphic, legend], {
        direction: true,
        color: "#FFF",
        align: "end",
      });
      downloadLink(mergedImages, name);
    });
  });
}

function downloadLink(href: string, name: string) {
  const downloadLink = document.createElement("a");
  downloadLink.href = href;
  downloadLink.download = name;
  downloadLink.click();
}

function downloadLegend(id: string) {
  const legend = document.getElementById(id);
  return new Promise((resolve) => {
    html2canvas(legend).then(function (canvas) {
      const image = canvas.toDataURL();
      resolve(image);
    });
  });
}

export function getDateColombia(dateValue) {
  const month = moment(dateValue).month();
  const year = moment(dateValue).year();
  const date = moment(dateValue).date();
  return moment()
    .set({ year, month, date })
    .subtract(5, "hours")
    .utc(false)
    .toDate();
}

export function getDateSubtracted(hour: number, date?) {
  return date
    ? moment(date).subtract(hour, "hours").toISOString()
    : moment().subtract(hour, "hours").toISOString();
}

export function areAllArraysEmpty(obj: any): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key !== "labels") {
      const value = obj[key];
      if (Array.isArray(value) && value.length > 0) {
        return false;
      }
    }
  }
  return true;
}
