import { Component, Input } from '@angular/core';
import { ListInterface } from '../../../../../utils/models/common.interface';

@Component({
  selector: 'lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent   {

  @Input() list: ListInterface;

 
}
