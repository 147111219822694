import { ModalTimeLineComponent } from "./components/common/modalTimeLine/modalTimeLine.component";
import { DragDropDirective } from "./directives/drag-drop/drag-drop.directive";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Material
import { LayoutModule } from "@angular/cdk/layout";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatRippleModule, MatNativeDateModule } from "@angular/material/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogModule } from '@angular/material/dialog';

import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { ToastrModule } from "ngx-toastr";

import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from "@angular-material-components/datetime-picker";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxMaskModule } from "ngx-mask";
import { ModalReorderComponent } from "./components/dialogs/modal-reorder/modal-reorder.component";
import { MatTooltipModule } from "@angular/material/tooltip";

import { DoubleScrollComponent } from "./components/scrolls/app-double-scroll/app-double-scroll.component";
import { PopoverComponent } from "./components/popover/popover.component";
import { FilterTableComponent } from "./components/tables/filter-table/filter-table.component";
import { ModalLoadingSecComponent } from "./components/loads/modal-loading-sec/modal-loading-sec.component";

import { SwiperModule } from "swiper/angular";

//Formulário
import { TimePickerComponent } from "./components/inputs/time-picker/time-picker.component";
import { TextInputComponent } from "./components/inputs/text-input/text-input.component";
import { PasswordInputComponent } from "./components/inputs/password-input/password-input.component";
import { SelectInputComponent } from "./components/inputs/select-input/select-input.component";
import { MultiselectInputComponent } from "./components/inputs/multiselect-input/multiselect-input.component";
import { TimeInputComponent } from "./components/inputs/time-input/time-input.component";
import { HourSuffixDirective } from "./directives/mat-form-field/hour-suffix-directive";
import { DatapickerInputComponent } from "./components/inputs/datapicker-input/datapicker-input.component";
import { FileInputComponent } from "./components/inputs/file-input/file-input.component";
import { SuffixInputComponent } from "./components/inputs/suffix-input/suffix-input.component";
import { NumberInputComponent } from "./components/inputs/number-input/number-input.component";
import { PrefixInputComponent } from "./components/inputs/prefix-input/prefix-input.component";
import { TextareaInputComponent } from "./components/inputs/textarea-input/textarea-input.component";
import { ButtonComponent } from "./components/inputs/button/button.component";
import { SelectInfiniteScrollSearchComponent } from "./components/scrolls/select-infinite-scroll-search/select-infinite-scroll-search.component";
import { CheckboxComponent } from "./components/inputs/checkbox/checkbox.component";
import { SwitchComponent } from "./components/inputs/switch/switch.component";
import { RadioButtonComponent } from "./components/inputs/radio-button/radio-button.component";

//Estrutura e nav
import { TabsInduxtryComponent } from "./components/common/tabs-induxtry/tabs-induxtry.component";
import { TabInduxtryComponent } from "./components/common/tabs-induxtry/tab-induxtry/tab-induxtry.component";
import { PageSelectComponent } from "./components/common/page-select/page-select.component";
import { DividerComponent } from "./components/common/divider/divider.component";
import { CardsComponent } from "./components/common/cards/cards.component";
import { ListaComponent } from "./components/common/lista/lista.component";
import { LinksComponent } from "./components/common/links/links.component";
import { CheckListComponent } from "./components/common/check-list/check-list.component";
import { EmptyStateComponent } from "./components/common/empty-state/empty-state.component";
import { DynamicTableComponent } from "./components/tables/dynamic-table/dynamic-table.component";
import { DynamicSubTableComponent } from "./components/tables/dynamic-sub-table/dynamic-sub-table.component";
import { AccordionInduxtryComponent } from "./components/common/accordion-induxtry/accordion-induxtry.component";
import { ModalInduxtryComponent } from "./components/common/modal-induxtry/modal-induxtry.component";
import { TreeInduxtryComponent } from "./components/common/tree/tree.component";
import { TimeLineComponent } from "./components/common/timeline/timeline.component";

//Status e Feedback
import { SwalComponent } from "./components/dialogs/swal/swal.component";
import { LoadingComponent } from "./components/loads/loading/loading.component";
import { MytoastrComponent } from "./components/dialogs/mytoastr/mytoastr.component";
import { InlineNotificationComponent } from "./components/dialogs/inline-notification/inline-notification.component";

//Miscelânea
import { AvatarComponent } from "./components/miscelanea/avatar/avatar.component";
import { TagsComponent } from "./components/miscelanea/tags/tags.component";
import { ChipsComponent } from "./components/miscelanea/chips/chips.component";
import { TooltipComponent } from "./components/miscelanea/tooltip/tooltip.component";
import { TooltipMultComponent } from "./components/miscelanea/tooltip-mult/tooltip-mult.component";

//Gráfico
import { ChartComponent } from "./components/graphics/chart/chart.component";
import { GraphicPlotlyComponent } from "./components/graphics/graphic-plotly/graphic-plotly.component";
import { GraphicTradingViewComponent } from "./components/graphics/graphic-tradingView/graphic-tradingView.component";
import { MapBoxComponent } from "./components/graphics/mapBox/mapBox.component";
import { LegendGraficComponent } from "./components/common/legend-grafic/legend-grafic.component";

//Navigation
import { NavigationComponent } from "./components/navigation/navigation/navigation.component";
import { SidenavComponent } from "./components/navigation/sidenav/sidenav.component";
import { NavigationBrandLogoComponent } from "./components/navigation/navigation-brand-logo/navigation-brand-logo.component";
import { SidenavUserComponent } from "./components/navigation/sidenav-user/sidenav-user.component";
import { SidenavMenuComponent } from "./components/navigation/sidenav-menu/sidenav-menu.component";
import { SidenavBottomComponent } from "./components/navigation/sidenav-bottom/sidenav-bottom.component";
import { TopnavbarUserComponent } from "./components/navigation/topnavbar-user/topnavbar-user.component";
import { TopnavbarBreadcrumbComponent } from "./components/navigation/topnavbar-breadcrumb/topnavbar-breadcrumb.component";
import { SidenavMobileControlComponent } from "./components/navigation/sidenav-mobile-control/sidenav-mobile-control.component";
import { TopnavbarComponent } from "./components/navigation/topnavbar/topnavbar.component";
import { MenuMobileComponent } from "./components/navigation/menu-mobile/menu-mobile.component";
import { TopnavbarButtonsComponent } from "./components/navigation/topnavbar-buttons/topnavbar-buttons.component";

//Outros
import { ModalLoadingComponent } from "./components/loads/modal-loading/modal-loading.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { RouterModule } from "@angular/router";
import { ThemeService } from "./service/theme";

import { MyToastService } from "./service/myToast";
import { PlotlyService } from "./components/graphics/graphic-plotly/services/plotly-service";
import { BannerComponent } from "./components/dialogs/banner/banner.component";
import { TooltipModule } from "./components/miscelanea/tooltip-induxtry/tooltip.module";
import { TopnavbarProfileComponent } from "./components/navigation/topnavbar-profile/topnavbar-profile.component";
import { InsertTagsComponent } from "./components/miscelanea/insert-tags/insert-tags.component";
import { TextMaskModule } from "angular2-text-mask";
import { FlowchartComponent } from "./components/common/flowchart/flowchart.component";
import { QrcodeReaderComponent } from "./components/common/qrcode-reader/qrcode-reader.component";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { CalendarPickerComponent } from "./components/calendar-picker/calendar-picker.component";
import { CalendarComponent } from "./components/calendar-picker/calendar/calendar.component";
import { CalendarMonthComponent } from "./components/calendar-picker/calendar-month/calendar-month.component";
import { CalendarPeriodComponent } from "./components/calendar-picker/calendar-period/calendar-period.component";
import { CalendarYearComponent } from "./components/calendar-picker/calendar-year/calendar-year.component";
import { HeaderComponentComponent } from "./components/calendar-picker/header-component/header-component.component";
import { FilterDiacoComponent } from "./components/filter-diaco/filter-diaco.component";
import { DynamicFieldComponent } from "./components/filter-diaco/dynamic-field/dynamic-field.component";
import { InfoPopupComponent } from "./components/info-popup/info-popup.component";
import { GraficoBarraLinhaComponent } from "./components/graphics/grafico-barra-linha/grafico-barra-linha.component";
import { ModalAlertComponent } from "./components/dialogs/modal-alert/modal-alert.component";
import { NonEmptyNumericDirective } from "./directives/inputs/notEmptyNumeric.directive";
import { NonEmptyPositiveNumberDirective } from "./directives/inputs/inputNumericPositive.directive";
import { GenericPickerComponent } from "./components/calendar-picker/generic-picker/generic-picker.component";
import { WarningInputInduxtryDirective } from "./directives/warning-input-induxtry/warning-input-induxtry.directive";
import { NumericRangeDirective } from "./directives/inputs/numericRange.directive";
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from "ng2-currency-mask";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ","
};

@NgModule({
  declarations: [
    ModalLoadingComponent,
    ModalAlertComponent,
    ModalLoadingSecComponent,
    FilterTableComponent,
    PopoverComponent,
    ModalReorderComponent,
    DoubleScrollComponent,
    //Formulário
    TimePickerComponent,
    TextInputComponent,
    PasswordInputComponent,
    SelectInputComponent,
    MultiselectInputComponent,
    TimeInputComponent,
    DatapickerInputComponent,
    FileInputComponent,
    SuffixInputComponent,
    NumberInputComponent,
    PrefixInputComponent,
    TextareaInputComponent,
    ButtonComponent,
    SelectInfiniteScrollSearchComponent,
    CheckboxComponent,
    SwitchComponent,
    RadioButtonComponent,
    DragDropDirective,
    GraficoBarraLinhaComponent,
    GenericPickerComponent,

    //Estrutura e nav
    TabsInduxtryComponent,
    TabInduxtryComponent,
    PageSelectComponent,
    DividerComponent,
    CardsComponent,
    ListaComponent,
    LinksComponent,
    CheckListComponent,
    EmptyStateComponent,
    DynamicTableComponent,
    DynamicSubTableComponent,
    AccordionInduxtryComponent,
    ModalInduxtryComponent,
    TreeInduxtryComponent,
    TimeLineComponent,
    FlowchartComponent,
    //-------------

    //Status e Feedback
    SwalComponent,
    LoadingComponent,
    MytoastrComponent,
    InlineNotificationComponent,
    BannerComponent,

    //Miscelânea
    AvatarComponent,
    TagsComponent,
    InsertTagsComponent,
    ChipsComponent,
    TooltipComponent,
    TooltipMultComponent,
    //-----------

    //Gráfico
    ChartComponent,
    GraphicPlotlyComponent,
    GraphicTradingViewComponent,
    MapBoxComponent,
    LegendGraficComponent,

    //Navigation
    NavigationComponent,
    SidenavComponent,
    NavigationBrandLogoComponent,
    SidenavUserComponent,
    SidenavMenuComponent,
    SidenavBottomComponent,
    TopnavbarUserComponent,
    TopnavbarBreadcrumbComponent,
    SidenavMobileControlComponent,
    TopnavbarComponent,
    MenuMobileComponent,
    TopnavbarButtonsComponent,

    // Pesquisa e filtros
    QrcodeReaderComponent,

    //Directives
    HourSuffixDirective,
    TopnavbarProfileComponent,
    CalendarPickerComponent,
    CalendarComponent,
    CalendarMonthComponent,
    CalendarPeriodComponent,
    CalendarYearComponent,
    HeaderComponentComponent,
    FilterDiacoComponent,
    DynamicFieldComponent,
    InfoPopupComponent,
    ModalTimeLineComponent,
    NonEmptyNumericDirective,
    NonEmptyPositiveNumberDirective,
    WarningInputInduxtryDirective,
    NumericRangeDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Material
    LayoutModule,
    MatToolbarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatMenuModule,
    MatRippleModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSelectInfiniteScrollModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    NgxMatDatetimePickerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    ToastrModule.forRoot({
      toastComponent: MytoastrComponent, // added custom toast!
    }),
    NgxMaskModule.forRoot(),
    NgbModule,
    SwiperModule,
    TooltipModule,
    ZXingScannerModule,
    CurrencyMaskModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ZXingScannerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Material
    LayoutModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatTreeModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRippleModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectInfiniteScrollModule,
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    ModalAlertComponent,
    FilterTableComponent,
    PopoverComponent,
    NgxMatDatetimePickerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMaskModule,
    DoubleScrollComponent,
    MatDialogModule,

    //Formulário
    TimePickerComponent,
    TextInputComponent,
    PasswordInputComponent,
    SelectInputComponent,
    MultiselectInputComponent,
    TimeInputComponent,
    HourSuffixDirective,
    DatapickerInputComponent,
    FileInputComponent,
    SuffixInputComponent,
    NumberInputComponent,
    PrefixInputComponent,
    TextareaInputComponent,
    ButtonComponent,
    SelectInfiniteScrollSearchComponent,
    CheckboxComponent,
    SwitchComponent,
    RadioButtonComponent,
    DragDropDirective,
    NumericRangeDirective,
    CurrencyMaskModule,
    // CalendarComponent,
    // CalendarMonthComponent,
    // CalendarPeriodComponent,
    // CalendarYearComponent,
     CalendarPickerComponent,
    // HeaderComponentComponent,
    // GenericPickerComponent,

    //Estrutura e nav
    TabsInduxtryComponent,
    TabInduxtryComponent,
    PageSelectComponent,
    DividerComponent,
    CardsComponent,
    ListaComponent,
    LinksComponent,
    CheckListComponent,
    EmptyStateComponent,
    DynamicTableComponent,
    DynamicSubTableComponent,
    AccordionInduxtryComponent,
    ModalInduxtryComponent,
    TreeInduxtryComponent,
    TimeLineComponent,
    FlowchartComponent,

    //Status e Feedback
    SwalComponent,
    LoadingComponent,
    MytoastrComponent,
    InlineNotificationComponent,
    BannerComponent,

    //Miscelânea
    AvatarComponent,
    TagsComponent,
    InsertTagsComponent,
    ChipsComponent,
    TooltipComponent,
    TooltipMultComponent,
    TooltipModule,

    //Gráfico
    ChartComponent,
    GraphicPlotlyComponent,
    GraphicTradingViewComponent,
    MapBoxComponent,
    LegendGraficComponent,
    GraficoBarraLinhaComponent,

    // Pesquisa e filtros
    QrcodeReaderComponent,

    NgxMatSelectSearchModule,

    //Navigation
    NavigationComponent,
    SidenavComponent,
    NavigationBrandLogoComponent,
    SidenavUserComponent,
    SidenavMenuComponent,
    SidenavBottomComponent,
    TopnavbarUserComponent,
    TopnavbarBreadcrumbComponent,
    SidenavMobileControlComponent,
    TopnavbarComponent,
    MenuMobileComponent,
    TopnavbarButtonsComponent,
    FilterDiacoComponent,
    InfoPopupComponent,
    SwiperModule,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    ThemeService,
    MyToastService,
    PlotlyService,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  entryComponents: [
    SwalComponent,
    MytoastrComponent,
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    ModalAlertComponent,
    ModalReorderComponent,
  ],
})
export class SharedModule {}
