import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Interface
import { Brand } from '../../../../../utils/models/layout.interface';

@Component({
  selector: 'app-navigation-brand-logo',
  templateUrl: './navigation-brand-logo.component.html',
  styleUrls: ['./navigation-brand-logo.component.scss']
})
export class NavigationBrandLogoComponent implements OnInit, OnChanges {

  @Input() brand: Brand;
  @Input() collapse;
  myCollapse;

  constructor(private readonly router: Router) { }

  ngOnInit() {
    if (this.myCollapse === undefined) {
      this.myCollapse = true;
    }
  }

  ngOnChanges(change) {
    if (change.collapse) {
      this.myCollapse = this.collapse;
    }
  }

  home() {
    const type = localStorage.getItem("type")
    type && type === 'admin' ? this.router.navigate(['admin']) : this.router.navigate([''])
  }

}
