import { Component, Input,  Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInput, Warning } from '../../../../../utils/models/input.interface';
import { returnTextWarning, returnWarningIcon, returnColorWarning } from '../../../functions/utilities.service';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextInputComponent,
    },
  ],
})
export class TextInputComponent implements  ControlValueAccessor{
  warningIconTextInput = returnWarningIcon;
  colorWarningTextInput = returnColorWarning;
  textWarningTextInput = returnTextWarning;

  @Input() textInput: TextInput;
  @Input() textField: boolean;


  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();


  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };



  writeValue(value: any): void {
    this.textInput.value = value;
  }

  onChange: (_: any) => void = () => { }

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }



  setValue() {
    this.onChange(this.textInput.value);
    this.valueEmitter.emit(this.textInput.value);
  }
}
