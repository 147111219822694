import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {  FooterModal, HeaderModal } from '../../../../../utils/models/common.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'modal-induxtry',
  templateUrl: './modal-induxtry.component.html',
  styleUrls: ['./modal-induxtry.component.scss']
})
export class ModalInduxtryComponent implements OnInit {

  @Output() buttonEvent = new EventEmitter<any>();
  @Output() changeEvent = new EventEmitter<any>();

  height

  //Header
  header: HeaderModal;
  //Footer
  footer: FooterModal;

  forms: [] = [];


  constructor(public dialogRef: MatDialogRef<ModalInduxtryComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private readonly formBuilder: FormBuilder) {}


  results : any 
 
  ngOnInit(){
    //Header
    this.header = this.data?.header
    //Footer
    this.footer = this.data?.footer
    this.height = this.data?.height

    this.forms = this.data?.forms;


  }

  
  genericButton(event,item){
    event.push(this.data)
    this.buttonEvent.emit([...event,item])
  }

  close(){
    this.dialogRef.close()
  }


  onChange(event, type?, index?) {
    this.changeEvent.emit(this.forms)
  }


}
