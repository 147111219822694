import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { RadioButtonInput } from "../../../../../utils/models/input.interface";

@Component({
  selector: "radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioButtonComponent,
    },
  ],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor {
  _checked = false;
  @Input() radioButton: RadioButtonInput;

  checkHovered;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  onCheckMouseEnter(value) {
    this.checkHovered = value;
  }

  onCheckMouseLeave(value) {
    this.checkHovered = null;
  }

  writeValue(value: any): void {
    this._checked = value;
  }

  _onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    this.disabledAll();
  }

  disabledAll() {
    if (this.radioButton?.disabledAll) {
      this.radioButton.options.forEach((op) => {
        op.disabled = true;
      });
    }
  }

  onClicked(option) {
    this.checkValueOption(option);
    if (option.checked) {
      this.onChange.emit([option]);
    } else {
      this.onChange.emit([]);
    }
  }

  checkValueOption(option) {
    this.radioButton.options.forEach((op) => {
      if (op.checked && op.value !== option.value) {
        op.checked = false;
      }
    });
    const value = !option.checked;
    option.checked = value;
  }
}
