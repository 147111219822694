import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {
  hasValue: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.data?.info.length > 0) {
      this.hasValue = true;
    }
    this.removeDarkbackground()
  }
  
  handleClick() {
    this.dialogRef.close();
  }

  redirect(){
    this.dialogRef.close();
    this.router.navigate(['/inventory/details']);
  }
  
  removeDarkbackground(){
    const backdropElement:HTMLCollection = document.getElementsByClassName('cdk-overlay-dark-backdrop')
    if(backdropElement[0]){
      (backdropElement[0] as HTMLElement).style.setProperty('background-color', 'transparent', 'important');
    }

  }

}
