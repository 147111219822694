import { DOCUMENT } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker/datepicker';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarPickerConfig } from 'src/utils/models/calendar.interface';
import { Warning } from 'src/utils/models/input.interface';

import { CalendarMonthComponent } from './calendar-month/calendar-month.component';
import { CalendarPeriodComponent } from './calendar-period/calendar-period.component';
import { CalendarYearComponent } from './calendar-year/calendar-year.component';
import { CalendarComponent } from './calendar/calendar.component';

const TIME_OUT = 600;
@Component({
  selector: 'calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarPickerComponent),
      multi: true,
    },
  ],
})
export class CalendarPickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy,AfterContentChecked {
  @Input() calendarPicker: any;
  @Input() showTime: boolean;
  @Input() id: string;
  @Input() textMonth = false;
  @Input() warning: Warning;
  @Input() useEndDate = false;

  private _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabledState(disabled);
  }

  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('calendarPeriod') calendarPeriod: CalendarPeriodComponent;
  @ViewChild('calenarMonth') calenarMonth: CalendarMonthComponent;
  @ViewChild('calendar') calendar: CalendarComponent;
  @ViewChild('calendarYear') calendarYear: CalendarYearComponent;

  _yearPickerCtrl: FormControl = new FormControl();
  _monthPickerCtrl: FormControl = new FormControl();
  _dayPickerCtrl: FormControl = new FormControl();
  _dayPeriodPickerCtrl: FormControl = new FormControl();
  _regularPickerCtrl: FormControl = new FormControl();
  _periodPickerCtrl: FormControl = new FormControl();
  _yearPeriodPickerCtrl: FormControl = new FormControl();
  _monthPeriodPickerCtrl: FormControl = new FormControl();

  cdr
  _startDate: any = [];
  _endDate: any = [];
  beforePeriod;
  resetStartDate: boolean;

  private readonly _onDestroy: Subject<void> = new Subject<void>();

  constructor(@Inject(DOCUMENT) private readonly _document: any,cdr: ChangeDetectorRef) {
    this.cdr = cdr;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }


  ngAfterViewInit() {
    switch (this.calendarPicker.mode) {
      case 'YEAR':
        this._subscribeToChanges(this._yearPickerCtrl);
        break;
      // case 'PERIOD':
      //   this._subscribeToChanges(this._periodPickerCtrl);
      //   break;

      case 'MONTH':
        this._subscribeToChanges(this._monthPickerCtrl);
        break;
      case 'YEAR PERIOD':
        this._subscribeToChanges(this._yearPeriodPickerCtrl);
        break;
      case 'MONTH PERIOD':
        this._subscribeToChanges(this._monthPeriodPickerCtrl);
        break;
      case 'DAY':
        this._subscribeToChanges(this._dayPickerCtrl)
      break;
      case 'DAY PERIOD':
        this._subscribeToChanges(this._dayPeriodPickerCtrl)
      break;
      default:
        this._subscribeToChanges(this._regularPickerCtrl);
    }

    if(this.calendarPicker.valueStart){
      this._monthPeriodPickerCtrl.setValue({startDate: moment(this.calendarPicker.valueStart), endDate: moment(this.calendarPicker.valueEnd)})
      this._dayPeriodPickerCtrl.setValue({startDate: moment(this.calendarPicker.valueStart), endDate: moment(this.calendarPicker.valueEnd)})
    }
  }

  selectedEverything(event: any): void {
    let valor: any = [];
    if (this.calendarPicker.mode === 'YEAR PERIOD' || this.calendarPicker.mode === 'MONTH PERIOD' || this.calendarPicker.mode === 'DAY PERIOD') {
      valor = this.emitterPeriod(event, this.calendarPicker.mode);
    } else {
      valor = new Date(event)
    }
    this.beforePeriod = event;
    this.dateChange.emit(valor);
  }

  ngOnDestroy() {
    this._onDestroy.next();
  }

  // Function to call when the date changes.
  onChange = (date: any) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  writeValue(date: any): void {
    if (date) {
      this._writeValue(date);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    switch (this.calendarPicker.mode) {
      case 'YEAR':
        isDisabled ? this._yearPickerCtrl.disable() : this._yearPickerCtrl.enable();
        break;
      // case 'PERIOD':
      //   isDisabled ? this._periodPickerCtrl.disable() : this._periodPickerCtrl.enable();
      //   break;

      case 'MONTH':
        isDisabled ? this._monthPickerCtrl.disable() : this._monthPickerCtrl.enable();
        break;

      default:
        isDisabled ? this._regularPickerCtrl.disable() : this._regularPickerCtrl.enable();
    }
  }

  _takeFocusAway($datepicker: MatDatepicker<any>) {
    $datepicker.disabled = true;
    setTimeout(() => {
      $datepicker.datepickerInput['_elementRef'].nativeElement.blur();
      $datepicker.disabled = false;
    }, TIME_OUT);
  }

  private _writeValue(date: any): any {
    if (!date) {
      return;
    }

    switch (this.calendarPicker.mode) {
      case 'YEAR':
        if (date instanceof Date) {
          this._yearPickerCtrl.setValue(date, {emitEvent: false});
        }
        break;
      // case 'PERIOD':
      //   if (date instanceof Date) {
      //     this._periodPickerCtrl.setValue(date, {emitEvent: false});
      //   }
      //   break;
      case 'DAY PERIOD':
        if (date) {
          this._dayPeriodPickerCtrl.setValue(date, { emitEvent: false });
        }
        break;
      case 'YEAR PERIOD':
        if (date) {
          this._yearPeriodPickerCtrl.setValue(date, { emitEvent: false });
        }
        break;
      case 'MONTH PERIOD':
      if (date) {
        this._monthPeriodPickerCtrl.setValue(date, { emitEvent: false });
      }
      break;
      case 'MONTH':
        if (date instanceof Date) {
          this._monthPickerCtrl.setValue(date, {emitEvent: false});
        }
      break;
      case 'DAY':
        if (date instanceof Date) {
          this._dayPickerCtrl.setValue(date, {emitEvent: false});
        }
      break;

      default:
        if (date instanceof Date) {
          this._regularPickerCtrl.setValue(date, {emitEvent: false});
        }
    }
  }

  private _subscribeToChanges(control: FormControl) {
    if (!control) {
      return;
    }

    control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value) => {
      let valor: any = [];
      if (value === undefined) {
        value = moment(this.beforePeriod.start_period);
        this.resetStartDate = true;
      }
      // this.calendarPicker.mode === 'PERIOD' || 
      if (this.calendarPicker.mode === 'YEAR PERIOD' || this.calendarPicker.mode === 'MONTH PERIOD' || this.calendarPicker.mode === 'DAY PERIOD') {
        valor = this.emitterPeriod(value, this.calendarPicker.mode);
      } else {
        valor = new Date(value)
      }
      this.onChange(valor);
      this.onTouched();

    });
  }

  private emitterPeriod(value, mode: string) {
    if(mode !== 'YEAR PERIOD' && mode !== 'MONTH PERIOD' && mode !== 'DAY PERIOD'){
      if (this._startDate instanceof Date && !this.resetStartDate) {
        this._endDate = new Date(value);
      } else {
        this._startDate = new Date(value);
        this.resetStartDate = false;
      }
  
      return {
        startDate: this._startDate,
        endDate: this._endDate
      }
    }else{
      return {
        startDate: new Date(value.start_period),
        endDate: new Date(value.end_period)
      }
    }
   
  }



  get _showMonthPicker(): boolean {
    return this.calendarPicker.mode === 'MONTH';
  }

  // get _showPeriodPicker(): boolean {
  //   return this.calendarPicker.mode === 'PERIOD';
  // }

  get _showRegularDatepicker(): boolean {
    return !this.calendarPicker.mode;
  }

  resetAll() {
    this.calendarPeriod?.reset()
    this.calenarMonth?.reset()
    this.calendar?.reset()
    this.calendarYear?.reset()
  }
}
