import {
	Component,
	ViewChild,
	OnInit,
	ChangeDetectorRef,
	Input,
	ElementRef,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { map, filter, catchError, switchMap } from 'rxjs/operators';
import { interval, Observable, of, Subscription } from 'rxjs';

// Interfaces
import {
	Brand,
	Menu,
	MenuBottom,
	MenuMobile,
	PageTitle,
} from '../../../../../utils/models/layout.interface';

// Constants
import { BannerInterface } from '../../../../../utils/models/common.interface';
import { NavigationService } from '../../../service/navigation.service';
import { ButtonInduxtry } from 'src/utils/models/input.interface';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'navigation-induxtry',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
	@ViewChild('drawer') private readonly drawer: MatSidenav;
	@ViewChild('ganttSide') private readonly ganttSide: MatSidenav;

	// Observable
	isHandset$: Observable<boolean> = this.breakpointObserver
		.observe(Breakpoints.Handset)
		.pipe(map((result) => result.matches));
	title$: Observable<string>;
	title: string;
	@Input() menus: Menu[];
	@Input() user: Menu;
	@Input() brand: Brand;
	@Input() menuBottom: MenuBottom;
	@Input() disabledTopNav = true;
	@Input() pageTitles: PageTitle[];
	sidenavPositionControl = 'start';
	@Input() mobileSideNavPositionControl;
	@Input() menuMobile: MenuMobile;
	myCollapsedUserPanel;
	myCollapse;
	otimizationLoading = false;

	showSideNav = true;
	scroll = false;
	showLabel = false;

	banner: BannerInterface[] = [];
	private subscription: Subscription | undefined;
	lastUpdate: string;

	identifier = 'graphDataWithdrawalSells';

	constructor(
		private readonly breakpointObserver: BreakpointObserver,
		private readonly changeDetector: ChangeDetectorRef,
		readonly router: Router,
		private readonly el: ElementRef,
		private readonly nav: NavigationService,
		private readonly dialog: MatDialog,
		private readonly toastr: ToastrService
	) {
		router.events
			.pipe(filter((e) => e instanceof NavigationEnd))
			.subscribe((e: NavigationEnd) => (this.title = e.url));
	}

	ngOnInit() {
		this.isHandset$.subscribe((res) => {
			if (res) {
				this.sidenavPositionControl = this.mobileSideNavPositionControl;
			} else {
				this.sidenavPositionControl = 'start';
			}
		});

		const options: Intl.DateTimeFormatOptions = {
			timeZone: 'America/Bogota',
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		};

		this.lastUpdate = new Date().toLocaleString('pt-BR', options);

		this.getLastUpdate();

		this.nav.optimizing$.subscribe((res) => {
			if (res) {
				this.getLastUpdate();
			}
		});

		this.nav.banner.subscribe((res) => {
			if (res) {
				this.banner?.push(res);
			}
		});
	}

	getLastUpdate(): void {
		// Chamada inicial imediata
		this.nav
			.getLastUpdate()
			.pipe(catchError((error) => of(null)))
			.subscribe((datetime: any) => {
				this.handleLastUpdate(datetime);
				this.checkSimulationStatus(datetime, true);
			});
    
				// Repetição a cada 30 segundos
				this.subscription = interval(30000) // 30 segundos
					.pipe(
						switchMap(() =>
							this.nav.getLastUpdate().pipe(catchError((error) => of(null)))
						)
					)
					.subscribe((datetime: any) => {
						this.handleLastUpdate(datetime);
						this.checkSimulationStatus(datetime);
					});
	}

	handleLastUpdate(datetime: any): void {
		if (datetime?.lastSuccessSimulation) {
			const date = datetime.lastSuccessSimulation.date;
			const time = datetime.lastSuccessSimulation.hour;
			if (this.needRefresh(date, time, this.lastUpdate)) {
				this.displayBanner('update', date, time);
			}
		}
	}

	closeDialogs(): void {
		if (this.dialog.openDialogs.length > 0) {
			this.dialog.openDialogs.forEach((modal) => {
				if (modal?.componentInstance?.data?.keepBrowsing) {
					modal.close();
				}
			});
		}
	}

	checkSimulationStatus(datetime: any, firstCall = false): void {
		const status = datetime?.lastSimulation?.status;
    if(status === 'error' && !firstCall){
      this.displayBanner('error')
    }
		if (status === 'error' || status === 'success') {
      this.otimizationLoading = false
      this.closeDialogs();
      this.nav.optimizingStatus.next({status: status, firstCall: firstCall})
			if (this.subscription) {
				this.subscription.unsubscribe(); // Para de chamar a rota
			}
		}else if(status === 'pending'){
      this.nav.optimizingStatus.next({status: status, firstCall: firstCall})
      this.otimizationLoading = true
    }
	}

	private needRefresh(date: string, time: string, lastUpdate: string) {
		const lastSimulationDate = this.convertToDate(date, time);
		const lastUpdateDate = this.convertToDate(
			this.lastUpdate.split(', ')[0],
			this.lastUpdate.split(', ')[1]
		);
		return lastUpdateDate < lastSimulationDate;
	}

	private convertToDate(date: string, time: string): Date {
		const [day, month, year] = date.split('/').map(Number);
		const [hours, minutes] = time.split(':').map(Number);
		return new Date(year, month - 1, day, hours, minutes);
	}

	displayBanner(type: string, date?: string, time?: string): void {
    if(type === 'update'){
      const buttons: ButtonInduxtry[] = [
        {
          label: 'Actualizar',
          customStyle: 'tertiary',
          showLabel: true,
        },
      ];
  
      this.banner = [
        {
          status: 'update',
          button: buttons,
          icon: 'circle_information',
          activeIcon: true,
          text: 'Atención! Para visualizar los dados más recientes, por favor actualice la página.',
          text2: `${date} a las ${time}`,
          disableClose: true,
					key: 'update'

        },
      ];
    }else{
      const buttons: ButtonInduxtry[] = [
        {
          label: 'CERRAR',
          customStyle: 'tertiary',
          showLabel: false,
          icon: 'Mobile Cancel'
					

        },
      ];
  
      this.banner = [
        {
          status: 'error',
          button: buttons,
          icon: 'circle_information',
          activeIcon: true,
          text: `No fue posible realizar la simulación en este momento.
           Por favor, intente nuevamente. Caso que el error persista, entre en contacto con el`,
          disableClose: true,
          linkText:'soporte Enacom',
          link: 'https://suporte.enacom.com.br/',
					key: 'errorSimulation'
        },
      ];
    }
		
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	handleClose(event: boolean): void {
		this.isHandset$.subscribe((value) => {
			if (value && event) {
				this.drawer.close();
			}
		});
	}

	closeGanttSide() {
		this.ganttSide?.close();
	}

	receiveCollapse(collapse) {
		this.myCollapse = collapse;
		this.isHandset$.subscribe((value) => {
			if (value) {
				document.getElementById('sidenav').style.width = '254px';
				document.getElementById('sidenav').style.right = '0px';
				document.getElementById('sidenav').style.left = '';
				this.sidenavPositionControl = 'end';
			} else {
				this.sidenavPositionControl = 'start';
				if (this.myCollapse) {
					document.getElementById('sidenav').style.width = '198px';
					document.getElementById('sidenav').style.left = '0px';
				} else {
					document.getElementById('sidenav').style.width = '80px';
					document.getElementById('sidenav').style.left = '0px';
				}
			}
		});
	}

	receiveCollapsedUserPanel(collapsedUseMenu) {
		if (!collapsedUseMenu) {
			this.myCollapsedUserPanel = collapsedUseMenu;
		} else {
			this.myCollapsedUserPanel = !this.myCollapsedUserPanel;
		}
	}

	scrollToTop() {
		const contentElement = this.el.nativeElement.querySelector('.content');
		if (contentElement) {
			contentElement.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
			this.showLabel = false;
		}
	}

	onScrollTeste(event) {
		const scroll = event?.target?.scrollTop;
		this.scroll = scroll === 0 ? false : true;
	}

	expandButton(isHovered) {
		if (isHovered) {
			this.showLabel = true;
		} else {
			this.showLabel = false;
		}
	}

	closeBanner(event) {
		const newArray = this.banner.filter((item) => item?.key !== event?.key);
		this.banner = newArray;
	}

	eventButton(event) {
		this.subscription.unsubscribe();
		if(event?.status === 'error'){
			this.closeBanner(event)
		}else{
			window.location.reload();
		}
		return event;
	}
}
