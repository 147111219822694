import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "src/app/service/http/http.service";
import { magicNumbers } from "src/app/utils/constants/shared/sharedFunctions";
import { FileInput } from "src/utils/models/input.interface";

@Component({
  selector: "app-ModalImportJson",
  templateUrl: "./ModalImportJson.component.html",
  styleUrls: ["./ModalImportJson.component.scss"],
})
export class ModalImportJsonComponent {
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;
  @Output() enviarLoading = new EventEmitter<boolean>();

  radioButton = "";
  namePlaceHolder = "Insira o nome do arquivo aqui";
  selectedRadioOption = "";
  cenarioId = sessionStorage.getItem("cenarioId");

  formGroup = this.formBuilder.group({
    prefixo: ["sim_teste_", [Validators.required]],
    description: ["", [Validators.required]],
    observations: ["", []],
    situation: ["Desenvolvimento"],
    radio: [null, [Validators.required]],
  });
  uploadedJson: string;
  resultJSON: string;
  resultadoOtm: any;
  selectedFile = null;
  fileInputWarning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null,
  };
  fileInputInterface: FileInput = {
    disabled: false,
    readonly: false,
    types: ["json"],
    value: null,
    placeholder: "Nome do arquivo.json",
    label: "Objeto(.json)",
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalImportJsonComponent>,
    private readonly toastr: ToastrService,
    public dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly httpService: HttpService
  ) {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onFileSelected(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.namePlaceHolder = selectedFile.name;
      this.selectedFile = selectedFile;
    }
  }

  isFormValid() {
    return this.formGroup.valid && this.selectedFile;
  }

  handleClick(submit: boolean): void {
    if (submit) {
      this.dialogRef.close({ form: this.formGroup, file: this.selectedFile });
    } else {
      this.dialogRef.close(null);
    }
  }

  enviarValorLoading() {}

  readFileContents(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        try {
          const fileContents = JSON.parse(e?.target?.result);
          resolve(fileContents);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsText(file);
    });
  }

  formartResult(result) {
    this.resultadoOtm = result;
    this.resultadoOtm = result.type !== "laminador" ? false : true;
    const textedJson = JSON.stringify(result, null, magicNumbers.NUMBER4);
    const textInput: HTMLInputElement = document.getElementById(
      "resultInput"
    ) as HTMLInputElement;
    textInput.value = textedJson;
    textInput.readOnly = true;
  }

  fileImport(event) {
    this.selectedFile = event;
  }
}
