import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalImportJsonComponent } from "src/app/internal/views/simulation/components/ModalImportJson/ModalImportJson.component";
import { ButtonInduxtry, SwitchInput } from "src/utils/models/input.interface";
import { ModalTimeLineComponent } from "../../common/modalTimeLine/modalTimeLine.component";
import { GlobalDataService } from "src/app/shared/service/globalDataService";
import { NavigationEnd, Router } from "@angular/router";
import { filter, switchMap } from "rxjs/operators";
import { ModalAlertComponent } from "../../dialogs/modal-alert/modal-alert.component";
import { InventoryService } from "src/app/internal/views/inventory/service/inventory.service";
import { timer } from "rxjs";

@Component({
  selector: "topnavbar-buttons",
  templateUrl: "./topnavbar-buttons.component.html",
  styleUrls: ["./topnavbar-buttons.component.scss"],
})
export class TopnavbarButtonsComponent implements OnInit {
  // Interfaces
  buttonImportJSON: ButtonInduxtry[];

  _patternSwitchCommon: SwitchInput;// Interface SwitchInput inventoryDetails
  _patternSwitchCommonAlertHistory: SwitchInput; // Interface SwitchInput alert-history
  inventoryDetailsButtons = false;
  inventoryModel = false;
  modalData = [];

  currentUrl = ''
  parametersModel: boolean;

  constructor(
    public dialog: MatDialog,
    public globalDataService: GlobalDataService,
    public router: Router,
    private inventoryService: InventoryService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((res: NavigationEnd) => {
        this.currentUrl = res.url
        this.inventoryDetailsButtons = this.canShowDetailsInv(res.url);
        this.inventoryModel = this.canShowModelInv(res.url);
        this.parametersModel = this.canParametersInv(res.url);
      });
  }

  ngOnInit() {
    this.startInterfaces(); 
    this.getAlerts().then((res) => {
      this.modalData = res;
    }).catch((_error) => {
      this.modalData = []
    });
    this.updateAlerts();
  }

  startInterfaces() {
    this.buttonImportJSON = [
      {
        label: "IMPORTAR JSON",
        showLabel: true,
        customStyle: "primary",
        key: "importJSON",
      },
      {
        label: "VOLVER",
        icon: "Arrow Left",
        showLabel: true,
        customStyle: "secondary",
        key: "volter",
      },
      {
        label: "ALERTA",
        showLabel: true,
        customStyle: "secondary",
        key: "importJSON",
        icon: "bellAlert",
        allertIcon: true,
        backgroundColor: "#214B96",
      },
    ];

    this._patternSwitchCommon = {
      checked: null,
      label: "Cambiar unidades de medida",
      disabled: false,
    };
    this._patternSwitchCommonAlertHistory = {
      checked: false,
      label: "Ver Gráfico",
      disabled: false,
    };
  }

  importJSON() {
    this.openModal();
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ModalImportJsonComponent, {
      width: "400px",
      disableClose: false,
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const loadingModal = this.dialog.open(ModalTimeLineComponent, {
          width: "464px",
          disableClose: true,
          data: {
            type: "info-temp",
            bodyScenario: res,
            title: "Criando cenário de otimização",
          },
        });
      }
    });
  }

  canShowDetailsInv(url: string) {
    const urlCanRemove = ["/inventory/details"];
    return urlCanRemove.includes(url);
  }

  canParametersInv(url: string) {
    const urlCanRemove = ["/inventory/parameters"];
    return urlCanRemove.includes(url);
  } 

  canShowModelInv(url: string) {
    const urlCanRemove = ["/inventory/model"];
    return urlCanRemove.includes(url);
  }

  returnInventory() {
    if(this.currentUrl === '/inventory/model'){
      this.router.navigate(["/inventory/details"]);
    } else {
      this.router.navigate(["/inventory"]);
    }
  }
  changeSwitchValue(event) {
    this.globalDataService.emitSwitchFunction(event);
  }

  async openAlertModal() {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: "400px",
      disableClose: false,
      data: {
        info: this.modalData,
        emptyText: {
          text: "No hay alertas de reposicion para hoy",
          subText: "No hay artículos críticos para gestionar.",
        },
        title: "Solicitar Orden de Compra",
        button: {
          label: "DETALLES",
          showLabel: true,
          icon: "",
          customStyle: "secondary",
          key: "descargar",
          disabled: false,
          elongated: true,
        },
      },
      panelClass: "custom-modal-container",
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.buttonImportJSON[2].allertIcon = false;
    });
  }

  async getAlerts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.inventoryService.getStock().subscribe(
        (res) => {
          if (res) {
            resolve(res);
          }
        },
        (error) => {
          if (error?.message) {
            reject(new Error("Error al actualizar datos: " + error.message));
          } else {
            reject(new Error("Error al capturar datos de actualización"));
          }
        }
      );
    });
  }

  updateAlerts() {
    const REQUEST_INTERVAL = 3600000; //intervalo de 1 hora entre requests;

    timer(REQUEST_INTERVAL, REQUEST_INTERVAL)
      .pipe(switchMap(() => this.getAlerts()))
      .subscribe((res) => {
        const currentDiacoAlerts = this.modalData[0]?.alerts?.length || 0;
        const currentCyrgoAlerts = this.modalData[1]?.alerts?.length || 0;

        // updated Data
        const updatedDiacoAlerts = res[0].alerts?.length || 0;
        const updatedCyrgoAlerts = res[1].alerts?.length || 0;

        //is some data updated?
        const isDiacoUpdated = updatedDiacoAlerts > currentDiacoAlerts;
        const isCyrgoUpdated = updatedCyrgoAlerts > currentCyrgoAlerts;

        if (!isDiacoUpdated && !isCyrgoUpdated) {
        }

        if (isDiacoUpdated || isCyrgoUpdated) {
          this.buttonImportJSON[2].allertIcon = true;
        }
      });
  }

  showJsonButton() {
    return this.router.url.includes("simulation/test-simulation");
  }
}
