import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../service/auth/auth.service';

@Component({
  selector: 'topnavbar-profile',
  templateUrl: './topnavbar-profile.component.html',
  styleUrls: ['./topnavbar-profile.component.scss']
})
export class TopnavbarProfileComponent implements OnInit {
  @Input() link;

  openProfile: boolean;
  name: string;
  profileUser: string;
  firstCharacters: string;

  constructor(
    public router: Router,
    private readonly auth: AuthService,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.name = (localStorage.getItem('name') ? localStorage.getItem('name') : '');
    this.profileUser = (localStorage.getItem('user') ? localStorage.getItem('user') : '');
    this.firstCharacters = this.extractFirstCharacters();
  }

  extractFirstCharacters(): string {
    const arrayName = this.name.split(' ');
    let nowCharacter = '';
    arrayName.forEach((item, index, arr) => {
      if (index <= 1) {
        nowCharacter += item.substring(0, 1);
      } else {
        arr.length = index + 1;
      }
    })
    return nowCharacter.toUpperCase();
  }

  handleClickOpenProfile(): void {
    this.openProfile = true;
  }

  handleClickCloseProfile(): void {
    this.openProfile = false;
  }

  closeCloseProfile(event): void {
    if (event.currentTarget === event.target) {
      this.handleClickCloseProfile();
    }
  }

  handleExit() {
    this.router.navigate(['/autenticacao/login']);
    this.auth.removeLocalStorage();
    this.toastr.show('¡Cerrar sesión correctamente!', '¡Éxito!', {
      timeOut: 5000,
      messageClass: 'success',
      titleClass: JSON.stringify({})
    });
  }
}
