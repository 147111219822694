import { Component, Input, OnInit, EventEmitter, Output, ChangeDetectorRef, AfterContentChecked, } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonInduxtry, SelectInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpService } from '../../../../service/http/http.service';
@Component({
  selector: 'select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectInputComponent,
    },
  ],
})
export class SelectInputComponent implements OnInit, ControlValueAccessor, AfterContentChecked {
  //Global Functions
  warningIconSelectInput = returnWarningIcon;
  colorWarningSelectInput = returnColorWarning;
  textWarningSelectInput = returnTextWarning;
  
  //Inputs
  @Input() selectInput: SelectInput;
  @Input() loading = false;
  @Input() returnObject = false;
  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  //Outputs
  @Output() valueEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() endScroll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() page: EventEmitter<number> = new EventEmitter<number>();
  @Output() originalOptionsEmitter: EventEmitter<any> = new EventEmitter<any>();
 
  //Interfaces
  buttonInterfaceElongated: ButtonInduxtry;

  //constants
  public searchFieldForm: FormControl = new FormControl();
  private readonly _onDestroy = new Subject<void>();

  //variables
  valuePage = 1
  searchLoading = false;
  preventRequest = false
  cdr
  defaultOptions:any[] | null
  selectedValueWhenClosed
  firstRequest = true
  selectValueWhenOpened


  constructor(readonly httpService: HttpService,
    cdr: ChangeDetectorRef){
    this.cdr = cdr;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this.selectInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    this.buttonInterfaceElongated = { label: 'LIMPIAR', elongated: true, customStyle: 'secondary', showLabel: true }
    if(this.selectInput.selectInputSearch?.requestOnInit){
      this.getRequest()
    }
      this.handleSearchField()
  }
  
  handleSearchField(){
    const sleepTime = 300;
    this.searchFieldForm.valueChanges.pipe(
      takeUntil(this._onDestroy),
      debounceTime(sleepTime),
      distinctUntilChanged()
    ).subscribe((typedValue) => {
      this.getRequest(typedValue,false,true)
    })
  }
  
  getRequest(typedValue?,pagination = false,restorePage = false){
    const endpoint = this.selectInput.selectInputSearch?.endpoint
    const params = this.selectInput.selectInputSearch?.requestParams
    const attributes = this.attributesBuilder(typedValue || '')
    if(this.selectInput.selectInputSearch){
    if(restorePage){
      params.page = 1 
      this.valuePage = 1}
    this.handleService(endpoint,params,attributes,pagination)
    }
  }

  handleService(endpoint,params,attributes,pagination=false){
    if(!this.preventRequest){
      this.searchLoading = true
      this.httpService.genericGetSelectFiltroScroll<[]>(
        endpoint,
        params,
        attributes
      ).subscribe((options) => {
        this.originalOptionsEmitter.emit(options)
        this.handleResponse(options,pagination)
      },
      (_error) =>{
        this.writeValue(null)
        this.selectInput.options = []
      },
      () => {
      this.searchLoading = false
      })
    }
  }

  handleResponse(options,pagination=false){
    this.formatOptions(options,pagination)
  }

  clearSelection(){
    this.selectInput.selectInputSearch.requestParams['page'] = 1
    this.valuePage = 1
  }

  formatOptions(options,pagination=false){
    let familyGraph = options?.family ? options?.family : options
    let subFamilyGraph = options?.subFamily ? options?.subFamily : options
    let newOptions = options?.rows ? options?.rows : (familyGraph ? familyGraph.rows : options) 
    newOptions = newOptions.map((element) => {
        return {
          value: element?.id || element?._id,
          viewValue: element[this.selectInput.selectInputSearch.viewValue]
        }
      })
      this.setupPagination(pagination,newOptions)
      this.setupPreviousValue(newOptions)
    }
    
  setupPreviousValue(newOptions){
    if(this.selectValueWhenOpened){
      if(typeof(this.selectValueWhenOpened) === 'string'){
        if(this.selectedValueWhenClosed){
          this.selectInput.options.unshift(this.selectedValueWhenClosed)  
        } else {
          this.selectInput.options.unshift(this.getCurrentValue())
        }
      } else {
        this.selectInput.options.unshift(this.selectValueWhenOpened)
        this.selectValueWhenOpened = null
      }
      this.removeDuplicate()
    }}

  removeDuplicate(){
    const seen = []
    const output = []
    for(const item of this.selectInput.options){
      if(!seen.includes(item.value)){
        output.push(item)
        seen.push(item.value)
        }}
        this.selectInput.options = output
    }

  getCurrentValue(){
    this.selectInput.options = this.selectInput.options.filter(element => element)
    const currentOption = this.selectInput.options.filter(element => element?.value === this.selectValueWhenOpened)
    if(currentOption.length){
      return currentOption[0]
    } else {
      return this.selectedValueWhenClosed
    }
  }

  setupPagination(pagination, newOptions){
    newOptions = newOptions.filter(element => element)
    if(pagination){
      this.selectInput.options.push(...newOptions)
    } else {
      this.selectInput.options = newOptions
    }
  }

  attributesBuilder(typedValue){
    const defaultAttriubte = {
      param: this.selectInput.selectInputSearch?.param, value: typedValue
    }
    const complementaryAttributes = this.selectInput.selectInputSearch?.aditionalAttributes
    return complementaryAttributes ? [defaultAttriubte,...complementaryAttributes]: [defaultAttriubte]
  }

  setNewValue(event) {
    this.selectInput.value = event.value
    this.onChange(this.selectInput.value);
    this.valueEmitter.emit({option: this.selectInput.value});
    this.searchFieldForm.setValue(this.selectInput?.value['viewValue'])
  }

  genericButton(value) {
    this.selectInput.value = undefined;
    this.onChange(this.selectInput.value);
    this.valueEmitter.emit({option: this.selectInput.value});
  }

  resetPage() {
    this.valuePage = 1;
  }

  infiniteScroll() {
    if(!this.loading){
      this.valuePage ++
      this.endScroll.emit(true)
      this.paginationBuilder()
    }
  }
  
  paginationBuilder(){
    if(this.selectInput?.selectInputSearch?.requestParams){
      this.selectInput.selectInputSearch.requestParams['page'] = this.valuePage
      const typeValue = this.searchFieldForm.value
      this.getRequest(typeValue,true,false)
    }
  }
  resetSearchField(event){
    if(!event){
      this.resetData()
      return;
    } 
    this.openedWithValue()
    this.preventRequest = false
    this.searchLoading = false
    this.firstRequest = true
  }

  openedWithValue(){
    this.selectValueWhenOpened = this.selectInput?.value
    this.searchFieldForm.reset()
    this.handleSearchField()
  }

  resetData(){
    this.preventRequest = true
    this.valuePage = 1
    this.setupAuxVariables()
    this.removeUnecessaryOptions()
  }

  removeUnecessaryOptions(){
    if(this.selectInput.selectInputSearch){

      let currentValue:any
      if(typeof(this.selectInput.value)=== 'string' ){
        currentValue = this.selectInput.options.filter(element => element?.value === this.selectInput.value)[0]
        this.selectInput.options = [currentValue]
      } else {
        currentValue = this.selectInput?.value
        if(currentValue?.value){
          currentValue = this.selectInput?.value['value']
          this.selectInput.options = this.selectInput.options.filter(element => element.value === currentValue )
        }
      }
    }
  }
  
  setupAuxVariables(){
    if(this.selectInput.selectInputSearch?.requestParams){
      this.selectInput.selectInputSearch.requestParams['page'] = 1
      if(typeof(this.selectInput.value) === 'string'){
        this.selectedValueWhenClosed = this.selectInput.options.filter(element => element.value ===this.selectInput.value )[0]
      }
      const currentValue = this.selectInput.value
      this.selectValueWhenOpened = currentValue
    }
  }
}
