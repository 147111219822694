import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalDataService {
  isToogleActive$ = new Subject<boolean>();
  showFilter: EventEmitter<boolean> = new EventEmitter();
  handleMockedData$ = new BehaviorSubject<boolean>(false);
  emitSwitchEvent:  EventEmitter<boolean> = new EventEmitter();
  downloadExcel: EventEmitter<boolean> = new EventEmitter();

  setShowFilter() {
    this.showFilter.emit(true);
  }

  handleMockedData(data) {
    this.handleMockedData$.next(data);
  }

  emitSwitchFunction(prop){
    this.emitSwitchEvent.emit(prop)
    this.isToogleActive$.next(prop);
  }


}
