import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { FilterInterface } from "src/utils/models/filter.interface";
import { CalendarPickerComponent } from "../../calendar-picker/calendar-picker.component";
import { SelectInputComponent } from "../../inputs/select-input/select-input.component";
import { RadioButtonInput } from "src/utils/models/input.interface";
import { SelectInfiniteScrollSearchComponent } from "../../scrolls/select-infinite-scroll-search/select-infinite-scroll-search.component";
import { Router } from "@angular/router";
import moment from "moment";

@Component({
  selector: "dynamic-field",
  templateUrl: "./dynamic-field.component.html",
  styleUrls: ["./dynamic-field.component.scss"],
})
export class DynamicFieldComponent implements OnInit,AfterContentChecked {
  @Input() formInterface: FilterInterface | null;
  @Input() haveSelectAll = true;

  @ViewChild("calendarPicker", { static: false })
  calendarPicker: CalendarPickerComponent;
  @ViewChildren("selectInput") selectInput: QueryList<SelectInputComponent>;
  @ViewChildren("infinitSelectInput") infinitSelectInput: QueryList<SelectInfiniteScrollSearchComponent>;
  @ViewChildren("radioButtonsViewChild")
  radioButtonsViewChild: QueryList<RadioButtonInput>;

  @Output() selectEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() calendarEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() toogleEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() resetGraphEmitter: EventEmitter<any> = new EventEmitter();
  @Output() appliedValueSelect: EventEmitter<any> = new EventEmitter();
  @Output() infiniteSelectCloseEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cleanFilterEmitter: EventEmitter<any> = new EventEmitter();
  @Output() filterValuesSelected: EventEmitter<any> = new EventEmitter();
  @Output() typedvalue: EventEmitter<any> = new EventEmitter();
  @Output() radioChangeEmitter: EventEmitter<any> = new EventEmitter();
  haveInitialRequest = true;

  cdr
  form = this.formBuilder.group({});

  constructor(
    public formBuilder: FormBuilder,
    public routerURL: Router,
    cdr: ChangeDetectorRef
  ) {
    this.cdr = cdr;
    if(this.routerURL.url === '/inventory/model'|| this.routerURL.url === '/inventory/vision-general' || this.routerURL.url === '/alert-history'){
      this.haveInitialRequest = false;
    }
   }

   ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.initFormInterface();
    this.form.valueChanges.subscribe((res: any) => {
      if (res) {
        this.filterValuesSelected.emit(res);
      }
    })
  }

  initFormInterface() {
    /* 
    remvoendo form controls anterior para quando o json for
    alterado, não houver sobreposição
    */
    this.removePreviousForm();
    this.form.reset();

    this.formInterface.fields.forEach((field) => {
      if (field.type === "calendar") {
          this.form.addControl(`${field.controlName}Start`,new FormControl(Date.parse(field?.interface?.valueStart) || null));
          this.form.addControl(`${field.controlName}End`,new FormControl(Date.parse(field?.interface?.valueEnd) || null));
         this.form.addControl(`${field.controlName}`,new FormControl({startDate: field?.interface?.valueStart , endDate: field?.interface?.valueEnd}))

      } else if (field.type === "toogle") {
        this.form.addControl(field.controlName, new FormControl(null));
      } else if (field.type === "radioButton") {
        this.form.addControl(field.controlName, new FormControl(null));
        this.setInicialValueField(field, field.interface.checked)
      } else if (field.type === 'infinit-select') {
        this.form.addControl(field.controlName, new FormControl(null));
      
       this.setInicialValueField(field, field.interface.options.map(item => item.name))
      }
      else {
        this.form.addControl(
          field.controlName,
          new FormControl(field?.interface?.value || null)
        );
      }
    });
  }

  setInicialValueField(field, value){
    const status = JSON.parse(localStorage.getItem('eventSelected'))
    if(status && field.type === 'infinit-select'){
      if(field.controlName === 'family'){
        this.form.get(field.controlName).setValue(status.family)
      }
      else if(field.controlName === 'subFamily' && status.subFamily !== 'all'){
        this.form.get(field.controlName).setValue(status.subFamily)
      }
      else if(field.controlName === 'product' && status.product !== 'all'){
        this.form.get(field.controlName).setValue(status.product)
      }else{
        this.form.get(field.controlName).setValue(value)
      }
    }else{
      this.form.get(field.controlName).setValue(value)
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  removePreviousForm() {
    Object.keys(this.form.controls).forEach((controlName) => {
      this.form.removeControl(controlName);
    });
  }

  cleanFilter() {
    this.form.reset();
    this.calendarPicker?._periodPickerCtrl.reset();
    this.resetGraphEmitter.emit(true);
  }

  changeSelect(event, field) {
    this.selectEmmiter.emit({ value: event, field });
  }

  onChangeParents(event) {
  }

  getMultiSelect(endpoint) {

  }

  onChangeStart(event, field) {
      this.calendarEmmiter.emit({ value: event, field });
  }

  onChangeToogle(event, field) { }

  onChangeRadio(event, index, controlName) {
    if (event.length) {
      this.form.get(controlName).setValue(event[0].value);
    }
    this.radioButtonsViewChild
      .toArray()
      .forEach((element: RadioButtonInput, idx) => {
        if (index !== idx) {
          element["radioButton"].options[0].checked = false;
        }
      });
    this.radioChangeEmitter.emit(event)
  }

  getValuesApplied(value) {
    const resEmit = {
      applied: value,
      fields: this.infinitSelectInput
    }
    this.appliedValueSelect.emit(resEmit)
  }
  getValuesAppliedWithControlName(optionsArray,controlName){
    const emitObject ={
      applied : optionsArray,
      controlName: controlName
    }
    this.appliedValueSelect.emit(emitObject)
  }

  closeMenuEmmiter(event, field) {
    const output = {
      inputs: this.infinitSelectInput.toArray(),
      field,
      fullArray: this.infinitSelectInput,
      value:event
    }
    this.infiniteSelectCloseEmitter.emit(output)
  }

  cleanButtonEmitter(event, field) {
    const output = {
      inputs: this.infinitSelectInput.toArray(),
      field
    }
    this.cleanFilterEmitter.emit(output)
  }

  typed(event,field){
    this.typedvalue.emit({
      inputs: this.infinitSelectInput.toArray(),
      field,
      typedValue:event
    })
  }

}
