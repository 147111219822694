import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import {
  RequestParams,
  RequestAttribute,
} from "../../../utils/models/http.interface";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  private readonly apiUrl: string = environment.apiUrl;
  private readonly unsubscribeAll$: Subject<Event> = new Subject<Event>();

  public static handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  constructor(private readonly http: HttpClient) {}

  genericGet<T>(
    endpoint: string,
    id?: string,
    requestParams?: any
  ): Observable<T> {
    const token = `${localStorage.getItem("login")}`;
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    let params = new HttpParams();
    if (requestParams) {
      params = params
        .set("page", requestParams.page?.toString())
        .set("limit", requestParams.limit?.toString());
    }

    const url = id
      ? `${this.apiUrl}/${endpoint}/${id}`
      : `${this.apiUrl}/${endpoint}`;
    return this.http
      .get<T>(url, { params, headers })
      .pipe(map((response: T) => response));
  }

  genericGetXLSX<Blob>(endpoint: string, id?: string): Observable<Blob> {
    const token = `${localStorage.getItem("login")}`;
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

    const url = id
      ? `${this.apiUrl}/${endpoint}/${id}`
      : `${this.apiUrl}/${endpoint}`;
    return this.http
      .get(url, { responseType: "blob", headers })
      .pipe(map((response: any) => response));
  }

  genericGetSelectInfinite<T>(
    endpointAddress: string,
    identifier?: string
  ): Observable<T> {
    const tokenCode = `${localStorage.getItem("login")}`;
    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${tokenCode}`
    );
    const address = identifier
      ? `${this.apiUrl}/${endpointAddress}/${identifier}`
      : `${this.apiUrl}/${endpointAddress}`;
    return this.http
      .get<T>(address, { headers })
      .pipe(map((response: T) => response));
  }

  genericGetWithId<T>(
    endpoint: string,
    id: string,
    attribute?: RequestAttribute[]
  ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}/${id}`;
    let params = new HttpParams();
    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    return this.http.get<T>(url, { params }).pipe(
      map((response: T) => {
        return response;
      })
    );
  }

  genericGetParams<T>(
    endpoint: string,
    attribute?: RequestAttribute[],
    requestParams?: RequestParams
  ): Observable<T> {
    const token = localStorage.getItem("login");
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams();

    if (requestParams) {
      params = params
        .set("page", requestParams.page?.toString())
        .set("limit", requestParams.limit?.toString());
    }
    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    return this.http.get<T>(url, { headers, params }).pipe(
      map((response: T) => {
        return response;
      })
    );
  }

  genericGetListTable<T>(
    endpoint: string,
    requestParams: RequestParams,
    attribute?: RequestAttribute[],
    filterAttribute?: RequestAttribute[]
  ): Observable<T> {
    const token = `${localStorage.getItem("login")}`;
    let headers;
    if (token) {
      headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    }

    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams()
      .set("skip", requestParams.skip?.toString() || "")
      .set("sort", requestParams.sort)
      .set("limit", requestParams.limit?.toString())
      .set("page", requestParams.page?.toString())
      ;
    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    if (!!filterAttribute) {
      filterAttribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }

    const requestOptions = {};
    requestOptions["params"] = params;
    if (token) {
      requestOptions["headers"] = headers;
    }

    return this.http.get<T>(url, requestOptions).pipe(
      map((response: T) => {
        return response;
      })
    );
  }

  genericPost<T>(
    endpoint: string,
    data: any,
    headersEx: any,
    isLogin = false
  ): Observable<T> {
    const token = localStorage.getItem("login");

    const headerLogin = new HttpHeaders(headersEx);
    const headers = isLogin
      ? headerLogin
      : new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const httpOptions = {
      headers,
    };

    const url = `${this.apiUrl}/${endpoint}`;
    return this.http
      .post<T>(url, data, httpOptions)
      .pipe(map((response: T) => response));
  }
  genericPostParam<T>(
    endpoint: string,
    body: any,
    attribute?: RequestAttribute[]
  ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams();
    const token = localStorage.getItem("login");
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    return this.http
      .post(url, body, { headers, params })
      .pipe((response: any) => {
        return response;
      });
  }

  genericPostXlsx(endpoint: string, body: any): Observable<any> {
    // Create url
    const url = `${this.apiUrl}/${endpoint}`;
 
    return this.http.post(url, body, {
      responseType: "blob"
 
    });
  }

  genericPostDownload<T>(
    endpoint: string,
    body: any,
    attribute?: RequestAttribute[]
  ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams();

    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    return this.http
      .post(url, body, { responseType: "arraybuffer" })
      .pipe((response: any) => {
        return response;
      });
  }

  genericPostNotData<T>(endpoint: string, data: any): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post<T>(url, data).pipe(map((response: T) => response));
  }

  genericPut<T>(endpoint: string, data: any, id?: string): Observable<T> {
    const token = localStorage.getItem("login");
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const url = id
      ? `${this.apiUrl}/${endpoint}/${id}`
      : `${this.apiUrl}/${endpoint}`;

    return this.http
      .put<T>(url, data, { headers })
      .pipe(map((response: T) => response));
  }

  genericDelete<T>(endpoint: string, id: string): Observable<T> {
    const token = `${localStorage.getItem("login")}`;
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const url = `${this.apiUrl}/${endpoint}/${id}`;
    return this.http
      .delete<T>(url, { headers })
      .pipe(map((response: T) => response));
  }

  genericDeleteWithoutId<T>(endpoint: string): Observable<T> {
    const token = `${localStorage.getItem("login")}`;
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http
      .delete<T>(url, { headers })
      .pipe(map((response: T) => response));
  }

  genericDeleteBody<T>(endpoint: string, id: string, data: any): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    const url = `${this.apiUrl}/${endpoint}/${id}`;
    return this.http.delete<T>(url, options).pipe(
      map((response: T) => {
        return response;
      })
    );
  }

  genericDeleteAll<T>(
    endpoint: string,
    id: string,
    attribute?: RequestAttribute[]
  ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}/${id}`;
    let params = new HttpParams();
    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    return this.http.delete<T>(url, { params }).pipe(
      map((response: T) => {
        return response;
      })
    );
  }

  genericPutBody<T>(
    endpoint: string,
    body: any,
    attribute?: RequestAttribute[]
  ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams();
    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    return this.http.put<T>(url, body, { params }).pipe(
      map((response: T) => {
        return response;
      })
    );
  }

  genericGetSelectFiltroScroll<T>(
    endpoint: string,
    requestParams: RequestParams,
    attribute?: RequestAttribute[]
  ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    const token = `${localStorage.getItem("login")}`;
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    let params = new HttpParams()
      .set("page", requestParams.page.toString())
      .set("sort", requestParams.sort)
      .set("limit", requestParams.limit.toString());
    if (!!attribute) {
      attribute.forEach(
        (att) => (params = params.append(att.param, att.value))
      );
    }
    return this.http
      .get<T>(url, {
        headers,
        params,
      })
      .pipe(
        map((response: T) => {
          return response;
        })
      );
  }

  genericGetData<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(endpoint).pipe(map((response: T) => response));
  }

  genericPostData<T>(endpoint: string, data): Observable<T> {
    return this.http
      .post<T>(endpoint, data)
      .pipe(map((response: T) => response));
  }
}
