import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'swal',
  templateUrl: './swal.component.html',
  styleUrls: ['./swal.component.scss']
})
export class SwalComponent implements OnInit{

  @Output() handleClickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<SwalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly cdr: ChangeDetectorRef
  ) { }
  hour: Date
  handleClick(type: boolean): void {
    this.dialogRef.close(type);
  }

  ngOnInit(): void {
    this.updateHour();
    if(this.data?.type === 'success'){
      this.data['type'] = 'warning-sucess';
    } else if(this.data?.type === 'error') {
      this.data['type'] = 'warning-error';
    } else if(this.data?.type === 'alert') {
      this.data['type'] = 'warning-alert';
    } else if(this.data?.type === 'info') {
      this.data['type'] = 'warning-information';
    } else if(this.data?.type === 'neutral') {
      this.data['type'] = 'warning-neutral';
    }

    this.cdr.detectChanges();
  }

  private updateHour(): void {
    this.hour = new Date();
  }

  handleEvent(event) {

    this.handleClickEvent.emit(event)
  }

  subTitleIsObject() {
    if(this.data && typeof this.data.pergunta === 'string') {
      return true
    }

    return false;
  }
}
