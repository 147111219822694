import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { GlobalDataService } from "../../service/globalDataService";
import { DynamicFieldComponent } from "./dynamic-field/dynamic-field.component";
import { FilterInterface } from "src/utils/models/filter.interface";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "filter-diaco",
  templateUrl: "./filter-diaco.component.html",
  styleUrls: ["./filter-diaco.component.scss"],
})
export class FilterDiacoComponent implements OnInit, OnDestroy {
  @ViewChild("fieldsComponent") fieldsComponent: DynamicFieldComponent;
  @Input() dynamicFields: FilterInterface;
  @Input() restore = false;
  @Input() clear = true;
  @Input() notDialog = false;
  @Input() haveSelectAll = true;
  @Input() disableSubmit = false
  @Output() formEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter();
  @Output() resetFilter: EventEmitter<any> = new EventEmitter();
  @Output() selectInputEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() calendarInputEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() emitterResetFilter: EventEmitter<any> = new EventEmitter();
  @Output() changesAppliedInfinit: EventEmitter<any> = new EventEmitter();
  @Output() infiniteSelectCloseEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cleanButtonEmitter: EventEmitter<any> = new EventEmitter();
  @Output() typedValueEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioButtonEmitter: EventEmitter<any> = new EventEmitter();
  disableSubmitButton: boolean = false;
  openFilter: boolean;
  toggleSubscription: Subscription;
  isToogleActivated = false;
  globalDataService: GlobalDataService;
  subscription: Subscription;


  private readonly unsubscribe$: Subject<Event> = new Subject<Event>();
  handleReset: boolean;

  constructor(globalDataService: GlobalDataService, private cdr: ChangeDetectorRef) {
    this.globalDataService = globalDataService;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.showFilter();
  }

  showFilter() {
    this.subscription = this.globalDataService.showFilter
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.openFilter = res;
      });
  }

  closeFilter(icon = false) {
    this.openFilter = false;
    if (icon) {
      this.fieldsComponent.form.reset();
    }
  }

  emitForm() {
    this.formEmmiter.emit(this.fieldsComponent.form);
  }

  handleClearFilter(): void {
    this.disableSubmitButton = false;
    this.fieldsComponent.cleanFilter();
    this.clearFilter.emit(true);
  }

  handleResetFilter(){
    this.resetFilter.emit(true);
  }

  handleClickFilter(): void {
    this.emitForm();
    this.closeFilter();
  }

  selectEmmiter(event) {
    this.selectInputEmmiter.emit({
      event,
      fieldComponent: this.fieldsComponent,
    });
  }

  calendarEmmiter(event) {
    this.calendarInputEmmiter.emit({
      event,
      fieldComponent: this.fieldsComponent,
    });
  }

  resetGraphEmitter(event) {
    this.handleReset = true
    this.emitterResetFilter.emit(true);
  }

  appliedChanges(changes) {
    this.changesAppliedInfinit.emit(changes)
  }

  multiSelectCloseEmitter(event) {
    this.infiniteSelectCloseEmitter.emit(event)
  }

  cleanFilterEmitter(event) {
    this.cleanButtonEmitter.emit(event)
  }

  getChangesValue(e) {
    this.disableSubmitButton = false;

      for (const key of Object.keys(e)) {
        const value = e[key];
        if (value !== null && value !== undefined && value.length !== 0) {
          this.disableSubmitButton = true;
          break;
        }
      }
      this.cdr.detectChanges()
  }
  radioChangeEmitter(e){
    this.radioButtonEmitter.emit(e)
  }

  typedvalue(event){
    this.typedValueEmitter.emit(event)
  }
    
}
