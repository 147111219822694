import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  setTheme(theme) {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
    :root {
    --white: ${theme?.white} !important;
    --black: ${theme?.black} !important;
    --gray-800: ${theme?.gray800} !important;
    --gray-650: ${theme?.gray650} !important;
    --gray-500: ${theme?.gray500} !important;
    --gray-400: ${theme?.gray400} !important;
    --gray-300: ${theme?.gray300} !important;
    --gray-200: ${theme?.gray200} !important;
    --gray-100: ${theme?.gray100} !important;
    --green-500: ${theme?.green500} !important;
    --green-400: ${theme?.green400} !important;
    --green-350: ${theme?.green350} !important;
    --green-300: ${theme?.green300} !important;
    --green-200: ${theme?.green200} !important;
    --green-100: ${theme?.green100} !important;
    --blue-500: ${theme?.blue500} !important;
    --blue-400: ${theme?.blue400} !important;
    --blue-350: ${theme?.blue350} !important;
    --blue-300: ${theme?.blue300} !important;
    --blue-200: ${theme?.blue200} !important;
    --blue-100: ${theme?.blue100} !important;
    --red-500: ${theme?.red500} !important;
    --red-400: ${theme?.red400} !important;
    --red-300: ${theme?.red300} !important;
    --red-200: ${theme?.red200} !important;
    --red-100: ${theme?.red100} !important;
    --yellow-500: ${theme?.yellow500} !important;
    --yellow-400: ${theme?.yellow400} !important;
    --yellow-300: ${theme?.yellow300} !important;
    --yellow-200: ${theme?.yellow200} !important;
    --yellow-100: ${theme?.yellow100} !important;
    --purple-500: ${theme?.purple500} !important;
    --purple-400: ${theme?.purple400} !important;
    --purple-300: ${theme?.purple300} !important;
    --purple-200: ${theme?.purple200} !important;
    --purple-100: ${theme?.purple100} !important;
    --blue2-500: ${theme?.blue2500} !important;
    --blue2-400: ${theme?.blue2400} !important;
    --blue2-350: ${theme?.blue2350} !important;
    --blue2-300: ${theme?.blue2300} !important;
    --blue2-200: ${theme?.blue2200} !important;
    --blue2-100: ${theme?.blue2100} !important;
    --orange-500: ${theme?.orange500} !important;
    --orange-400: ${theme?.orange400} !important;
    --orange-300: ${theme?.orange300} !important;
    --orange-200: ${theme?.orange200} !important;
    --orange-100: ${theme?.orange100} !important;
    --pink-500: ${theme?.pink500} !important;
    --pink-400: ${theme?.pink400} !important;
    --pink-300: ${theme?.pink300} !important;
    --pink-200: ${theme?.pink200} !important;
    --pink-100: ${theme?.pink100} !important;
    --brown: ${theme?.brown} !important;
    --primary-color-light: ${theme?.primaryColorLight} !important;
    --primary-color-medium: ${theme?.primaryColorMedium} !important;
    --primary-color-dark: ${theme?.primaryColorDark} !important;
    --secondary-color-light: ${theme?.secondaryColorLight} !important;
    --secondary-color-medium: ${theme?.secondaryColorMedium} !important;
    --secondary-color-dark: ${theme?.secondaryColorDark} !important;
    }`;
    document.head.appendChild(style);
  }
}
