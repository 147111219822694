import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[NonEmptyNumeric]'
})
export class NonEmptyNumericDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value; // Remove caracteres não numéricos
    if (value === '') {
      input.value = `0`; // Define o valor para '0' se estiver vazio
    } else {
      input.value = value;
    }

    // Manter a posição do cursor
    const cursorPosition = input.selectionStart;
    input.setSelectionRange(cursorPosition, cursorPosition);
  }
}