import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {  Router } from '@angular/router';

// Interface
import { MenuLink } from '../../../../../utils/models/layout.interface';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent implements OnInit, OnChanges {

  @Input() link: MenuLink;
  @Input() subMenu: boolean;
  @Input() collapse;
  @Input() child = false;
  @Output() linkClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  myCollapse;
  isHovered = false;
  panelOpenState = false;

  constructor(private readonly router: Router) { }

  activeRoute(routeName: string): boolean {
    return this.router.url.indexOf(routeName) > -1;
  }

  handleClick(event: boolean): void {
    this.linkClose.emit(event);
  }

  handleClickRoute(route, event) {
    if (`/${route}` === this.router.url) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([route]);
      });
    }
    this.handleClick(event);
  }

  ngOnChanges(change) {
    if (change.collapse) {
      this.myCollapse = this.collapse;
    }
  }

  ngOnInit() {
    this.myCollapse = true;
  }

  preventMenuClose(event: Event, type?) {
    if(type === 'secondary' || type === 'tertiary'){
      event.stopPropagation();
    }
  }
}
