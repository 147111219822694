import { PlotlyService } from "./../graphic-plotly/services/plotly-service";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  RendererFactory2,
  ViewChild,
} from "@angular/core";
import { areAllArraysEmpty } from "../../../../shared/service/utils";
import Plotly from 'plotly.js-dist';

@Component({
  selector: "grafico-barra-linha",
  templateUrl: "./grafico-barra-linha.component.html",
  styleUrls: ["./grafico-barra-linha.component.scss"],
})
export class GraficoBarraLinhaComponent implements OnInit {
  private renderer: Renderer2;
  @ViewChild("chartGenericPlot") chartGenericPlot: ElementRef;
  @ViewChild("yaxis1") yaxis1!: ElementRef;
  @ViewChild("yaxis2") yaxis2!: ElementRef;

  trace0: any;
  trace1: any;
  trace2: any;
  dataGraficTeste;
  layoutTeste;

  @Input() dataGraphic;
  @Input() EmptyData: false;
  @Input() haveAnnotation: false;
  @Input() idChart: string;
  @Input() idLegend: string;
  @Output() click: EventEmitter<any> = new EventEmitter();

  dataGrafic;
  configGrafic;
  layout;
  isEmptyData = false;

  labels = [
    {
      label: `Familias`,
      backgroundColor: "#13A1B4",
      borderColor: "#13A1B4",
      order: 1,
      icon: "square",
    },
    {
      label: `Cobertura Actual`,
      backgroundColor: "#4A13B9",
      borderColor: "#4A13B9",
      order: 1,
      icon: "line",
    },
    {
      label: `Cobertura Ideal`,
      backgroundColor: "#7D46EC",
      borderColor: "#7D46EC",
      order: 1,
      icon: "line",
    },
  ];
  label = [];
  bar1 = [];
  line = [];
  line2 = [];
  tickvals = [];
  tooltip = ``;

  value1 = "-";
  value2 = "-";
  value3 = "-";

  constructor(
    private readonly plotlyService: PlotlyService,
    rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    //Formatando os dados antes de usar no gráfico
    this.isEmptyData = areAllArraysEmpty(this.dataGraphic);
    this.label = this.plotlyService.labelUpdate(this.dataGraphic.label);
    this.bar1 = this.plotlyService.barUpdate(this.dataGraphic.bar1);
    this.line = this.plotlyService.lineUpdate(this.dataGraphic.line);
    this.line2 = this.plotlyService.barUpdate(this.dataGraphic.line2);
    this.tickvals = this.plotlyService.findIndex(this.label);
    this.labels = this.dataGraphic.labels;
    this.labels?.forEach((element) => {
      if (element['label'] === 'Cobertura Actual') {
        element['backgroundColor'] = '#F37A19'
      }
    })
    this.createGrafic();
  }

  ngAfterViewInit(): void {
    this.addBorderRadiusToBgClass();
  }

  createGrafic() {
    this.getConfigGrafic();
    this.getLayoutGrafic();
    this.dataGrafic = this.generateDataGrafic();
  }

  getConfigGrafic() {
    this.configGrafic = {
      displaylogo: false,
      responsive: false,
      displayModeBar: false,
      modeBarButtons: [
        // ["zoomIn2d", "zoomOut2d", "resetViews"]
      ],
    };
  }

  getLayoutGrafic() {
    const width =
      this.dataGraphic.label.length <= 5
        ? 5 * 324
        : this.dataGraphic.label.length * 324;
    this.layout = {
      barmode: "group",
      width,
      margin: {
        t: 35,
        l: 50,
        b: 40,
        r: 50,
      },
      xaxis: {
        title: this.idChart === "subfamily-chart" ? "SubFamilias" : "",
        tickvals: this.generateXAxis(this.dataGraphic.label), // Array com a posição dos eixos X  que quero exibir
        ticktext: this.dataGraphic.label, // Mostra o eixo X das barras não ocultas é necessário passar o array de dados iniciais antes da formatação
        zeroline: false,
        range: [0.5, this.generateXAxis(this.dataGraphic.label).length - 0.5], //Isso faz com que o ponto inicial da linha comece a partir da origem no eixo X.
        showgrid: true,
        fixedrange: true,
        showline: true,
        gridwidth: 1,
      },
      yaxis: {
        showline: true,
        fixedrange: true,
        zeroline: false,
        gridwidth: 1,
      },
      yaxis2: {
        overlaying: "y",
        side: "right",
        showline: true,
        fixedrange: true,
        zeroline: false,
        gridwidth: 1,
      },
      annotations: this.createAnnotations(),
      showlegend: false, // mostra a legenda
      scrollZoom: false, // desativa o zoom
      autosize: true, // define o tamanho automatico do grafico
    };
  }

  createAnnotations() {
    const data = this.generateXAxis(this.dataGraphic.label);
    data.pop();

    const familias = data.map((xValue, index) => {
      return {
        captureevents: true,
        x: xValue,
        y: this.dataGraphic.bar1[index] + this.dataGraphic.bar1[index] * 0.04,
        text:  
        this.formatNumberWithThousandSeparator(this.dataGraphic.bar1[index]?.toFixed().toString())
        + ' Kg',
        showarrow: false,
        name: "",
        visible: this.dataGraphic.labels[0].visible,
       // bordercolor: "#13A1B4",
      // borderwidth: 2,
     // borderpad: 4,
        bgcolor: "#46D8EC", // #364F52 // cor certa -> #46D8EC
        opacity: 0.8,
        xanchor: "center",
        yanchor: "bottom",
        ax: 0,
        ay: 10,
        title: "Familias",
        font: {
          family: "Inter Regular",
          size: 14,
          weight: 600,
          color: "#364F52",
        },
        familyName: this.dataGraphic.label[index],
      };
    });

    const currentCoverage = data.map((xValue, index) => {
      return {
        yref: "y2",
        x: xValue - 0.4,
        y: this.dataGraphic.line[index] + this.dataGraphic.line[index] * 0.2, // Espaçamento abaixo dos pontos de linha
        text: `${this.dataGraphic.line[index]?.toFixed().toString()} días`,
        showarrow: false,
       // bordercolor: "#4A13B9",
       // borderwidth: 0,
        visible: this.dataGraphic.labels[1].visible,
        title: "Cobertura Actual",
        name: "",
       // borderRadius: 10,
      //  borderpad: 10,
        bgcolor: "#F37A19", //aqui
        opacity: 1,
        xanchor: "left",
        yanchor: "top",
        font: {
          family: "Inter Regular",
          size: 14,
          color: "#ffffff",
          weight: 600,
        },
      };
    });

    const idealCoverage = data.map((xValue, index) => {
      return {
        yref: "y2",
        x: xValue + 0.4,
        y: this.dataGraphic.line2[index] + this.dataGraphic.line2[index] * 0.2, // Espaçamento abaixo dos pontos de linha
        text: `${this.dataGraphic.line2[index]?.toFixed().toString()} días`,
        showarrow: false,
        visible: this.dataGraphic.labels[2].visible,
        name: "",
        bordercolor: "#7D46EC",
       // borderwidth: 0,
        title: "Cobertura Ideal",
       // borderRadius: 10,
      //  borderpad: 10,
        bgcolor: "#7D46EC",
        opacity: 1,
        xanchor: "center",
        yanchor: "top",

        font: {
          family: "Inter Regular",
          size: 14,
          color: "#ffffff",
          weight: 600,
        },
      };
    });

    const titles = [
      {
        xref: 'paper',
        yref: 'paper',
        x: 0,  // Ajustar para fora da área visível
        y: 1.1,    // Acima da área visível
        text: 'Inventario(kg)',
        showarrow: false,
        font: {
          size: 14,
          family: 'Inter, sans-serif',
          color: '#666666'
        },
        xanchor: 'center',
        yanchor: 'top'
      },
      {
        xref: 'paper',
        yref: 'paper',
        x: 1,   // Ajustar para fora da área visível
        y: 1.1,    // Acima da área visível
        text: 'Cobertura(días)',
        showarrow: false,
        font: {
          size: 14,
          family: 'Inter, sans-serif',
          color: '#666666'
        },
        xanchor: 'center',
        yanchor: 'top'
      }
    ]
    const annotations = [];

    annotations.push(...familias);
    annotations.push(...currentCoverage);
    annotations.push(...idealCoverage);
    annotations.push(...titles)

    return annotations;
  }

  generateDataGrafic() {
    const arrayTraces = [];

    const traceBar = {
      x: this.generateXAxis(this.dataGraphic.label),
      y: this.dataGraphic.bar1,
      visible: true,
      type: "bar",
      name: "",
      title: { text: "Familias" },
      // visible: true,
      marker: {
        color: "#13A1B4",
        line: {
          color: "rgb(8,48,107)",
          // width: 1
        },
      },
      width: 0.2,
      hoverinfo: "none",
      // hovertemplate: this.tooltip,
      hoverlabel: {
        bgcolor: "#333333",
        font: {
          family: "Inter Regular",
          size: 16,
          color: "white",
        },
      },
    };

    const traceLine1 = {
      x: this.generateXAxis(this.dataGraphic.label),
      y: this.dataGraphic.line,
      name: "",
      visible: true,
      title: { text: "Cobertura Actual" },
      line: {
        // dash: 'dash',
        color: "#F37A19", //aqui
      },
      // mode: 'lines+markers',
      type: "scatter",
      yaxis: "y2",
    };

    const traceLine2 = {
      x: this.generateXAxis(this.dataGraphic.label),
      y: this.dataGraphic.line2,
      name: "",
      visible: true,
      title: { text: "Cobertura Ideal" },
      line: {
        // dash: 'dash',
        color: "#7D46EC",
      },
      // mode: 'lines+markers',
      type: "scatter",
      yaxis: "y2",
    };

    arrayTraces.push(traceBar);
    arrayTraces.push(traceLine1);
    arrayTraces.push(traceLine2);

    return arrayTraces;
  }

  generateXAxis(base, annot?) {
    const array = [];
    const baseCalc = annot ? base.length : base.length + 1;
    for (let index = 1; index <= baseCalc; index++) {
      array.push(index);
    }
    return array;
  }

  findHighestNumber() {
    const values = {
      obj1: this.dataGraphic.bar1,
      obj2: this.dataGraphic.line,
      obj3: this.dataGraphic.line2,
    };
    let maxNumber = -Infinity; // Inicia com o menor número possível
    for (let key in values) {
      let arrayMax = Math.max(...values[key]);
      if (arrayMax > maxNumber) {
        maxNumber = arrayMax;
      }
    }
    return maxNumber + 50;
  }

  //Gerando tooltip após o evento de hover ser disparado ao passar o mouse no gráfico
  generateTooltip(value) {
    if (
      value?.points[0]?.x.includes("-B-") ||
      value?.points[0]?.x.includes("-A-")
    ) {
      this.tooltip = "";
    } else {
      const resultX = this.label.indexOf(value?.points[0]?.x);

      //Adicionar valores para tooltip secundário
      this.value1 = this.line[resultX];
      this.value2 = this.bar1[resultX];
      // this.value3 = this.bar2[resultX]
      //---

      this.tooltip = `
      <br>
      Limite de Capacidade (t): ${this.line[resultX]
          .toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          .replace(",", ".")} <br><br>
      Produção Planejada (t): ${this.bar1[resultX]
          .toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          .replace(",", ".")} <br><br>
      `;
      // Produção Retroativa (t): ${this.bar2[resultX].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(',', '.')} <br><br>
    }
    this.plotlyService.upDateTooltip(this.tooltip, "chart1");
  }

  undoTooltip(value) {
    this.value1 = "-";
    this.value2 = "-";
    this.value3 = "-";
  }

  hidenData(e, id, dataGrafic) {
    dataGrafic.forEach((element, index) => {
      if (element.title.text === e?.dataSet.label) {
        const update = { visible: !element?.visible };
        e.dataSet.visible = update.visible;
        this.layout.annotations = this.haveAnnotation
          ? this.createAnnotations()
          : null;
        this.plotlyService.reStyle(update, index, id);
      }
    });
  }

  onClick(data, isAnnotations = false) {
    if (!isAnnotations) {
      this.click.emit(data);
    } else {
      const newData = {
        data,
        isAnnotations,
      };
      this.click.emit(newData);
    }
  }

  onScroll() {
    const element = this.chartGenericPlot.nativeElement;
    const isAtStart = element.scrollLeft === 0;
    const isAtEnd =
      element.scrollWidth - element.scrollLeft === element.clientWidth;

    if (isAtStart) {
      this.yaxis1.nativeElement.style.opacity = "1";
    } else {
      this.yaxis1.nativeElement.style.opacity = "0";
    }

    if (isAtEnd) {
      this.yaxis2.nativeElement.style.opacity = "1";
    } else {
      this.yaxis2.nativeElement.style.opacity = "0";
    }
  }

  formatNumberWithThousandSeparator(number) {
    try {

      number = Number(number)
      return number.toLocaleString('pt-BR');
    } catch {
      return `${number}`
    }
  }
  
  addBorderRadiusToBgClass(): void {
    //Verifica se a classe .bg existe na DOM element
    const elements = document.querySelectorAll('.bg');
    //Percorre todas as classes encontradas e adiciona o radius no svg
    elements.forEach((element: SVGElement) => {
      if (element.tagName === 'rect') {
        this.renderer.setStyle(element, 'rx', '4');
        this.renderer.setStyle(element, 'ry', '4');
      }
    });
  }
}
