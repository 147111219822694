import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-double-scroll",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./app-double-scroll.component.html",
  styleUrls: ["./app-double-scroll.component.scss"],
})
export class DoubleScrollComponent implements AfterViewInit {
  @Input() width;
  @ViewChild("wrapper1") wrapper1: ElementRef;
  @ViewChild("wrapper2") wrapper2: ElementRef;

  @ViewChild("div1") div1: ElementRef;
  @ViewChild("div2") div2: ElementRef;

  constructor(
    private readonly _r: Renderer2,
    private readonly _cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this._cd.detach();
    this.wrapper1.nativeElement.onscroll = (e) =>
      this.wrapper2.nativeElement.scroll(
        (e.target as HTMLElement).scrollLeft,
        0
      );
    this.wrapper2.nativeElement.onscroll = (e) =>
      this.wrapper1.nativeElement.scroll(
        (e.target as HTMLElement).scrollLeft,
        0
      );
  }

  ngOnChanges() {
    if (this.div2) {
      this._r.setStyle(
        this.div1.nativeElement,
        "width",
        this.div2.nativeElement.clientWidth + "px"
      );
    }
  }
}
