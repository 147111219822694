import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {

  @Input() text;
  @Input() isNumber: boolean;
  @Input() noDecimal = false;
  @Input() xPosition: 'after' | 'before' = 'after';

  formatText() {
    if (this.isNumber && this.text && !this.noDecimal) {
      this.text = parseFloat(this.text).toFixed(1);
    }
    let resultText = (this.text || this.text === 0 ? this.text.toString() : '');
    resultText = resultText.replace('.', ',');
    return resultText;
  }

}
