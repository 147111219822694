import { HeaderComponentComponent } from './../header-component/header-component.component';
import { Warning } from 'src/utils/models/input.interface';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment, { Moment} from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

export const YEAR_MODE_FORMATS_PERIOD = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const datePickerClass = {
  DAY: 'calendar-picker',
  MONTH: 'calendar-picker-month',
  YEAR: 'calendar-picker-year',
  PERIOD: 'calendar-picker-period'
}

@Component({
  selector: 'calendar-period',
  templateUrl: './calendar-period.component.html',
  styleUrls: ['./calendar-period.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS_PERIOD },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarPeriodComponent),
      multi: true,
    },
  ],
})
export class CalendarPeriodComponent implements ControlValueAccessor, AfterViewInit {

  headerPicker = HeaderComponentComponent;
  @Input() customFormFieldClass = '';
  @Input() label = '';
  @Input() styleLabel: any;
  @Input() placeholder: string;
  @Input() warning: Warning;
  @Output() selectedEverything = new EventEmitter();

  @ViewChild('datepicker') datepicker: MatDatepicker<any>;

  styleWarning = 
  `
    z-index: 2; 
    width: 16px; 
    height: 16px; 
    margin-right:1px; 
    align-self: flex-start; 
    position: relative; 
    top: 12px;
  `

  _maxPeriod: Moment;
  @Input()
  get max(): Date {
    return this._maxPeriod ? this._maxPeriod.toDate() : undefined;
  }
  set max(max: Date) {
    if (max) {
      const momentDatePeriod = moment(max);
      this._maxPeriod = momentDatePeriod.isValid() ? momentDatePeriod : undefined;
    }
  }

  _minPeriod: Moment;
  @Input()
  get min(): Date {
    return this._minPeriod ? this._minPeriod.toDate() : undefined;
  }
  set min(min: Date) {
    if (min) {
      const momentDateMinPeriod = moment(min);
      this._minPeriod = momentDateMinPeriod.isValid() ? momentDateMinPeriod : undefined;
    }
  }

  private _mode: 'PERIODO';
  @Input()
  get mode(): 'PERIODO' {
    return this._mode;
  }
  set mode(mode: 'PERIODO') {
    this._mode = mode;
    this._setupFilter();
  }

  @Input() touchUi = false;

  _customFilter: (d: Moment) => boolean;

  @ViewChild(MatDatepicker) _picker: MatDatepicker<Moment>;

  _startDate: FormControl = new FormControl();
  _endDate: FormControl = new FormControl();
  _panelClass = datePickerClass.PERIOD;
  _typeDate = 'start';
  formGroup
  _max
  _min
  required
  edited

  // Function to call when the date changes.
  onChange = (date: Date) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  /** send the focus away from the input so it doesn't open again */
  _takeFocusAway = (datepicker: MatDatepicker<Moment>) => {};

  constructor() {}

  onDatepickerOpened() {
 
    setTimeout(() => {
     this.updateDay();
    }, 100) 
  }

  ngAfterViewInit(): void {
    this.datepicker.stateChanges.subscribe((res) => {
    setTimeout(() => {
      this.updateDay();
      this.updateMonth();
    }, 100) 
    });
    
  }

  updateDay() {
    const daysWeek = document.querySelectorAll('.mat-calendar-table-header th span[aria-hidden="true"]');
    const headerTable = document.querySelectorAll('.mat-calendar-table-header');
    const daysMapping = {
      'do': 'Dom',
      '2ª': 'Seg',
      '3ª': 'Ter',
      '4ª': 'Qua',
      '5ª': 'Qui',
      '6ª': 'Sex',
      'sá': 'Sáb'
    };
    
    daysWeek.forEach((day) => {
      const currentText = day.textContent.trim();
      if (daysMapping[currentText]) {
        day.textContent = daysMapping[currentText];
      }
    });

    headerTable.forEach(element => {
      (element as HTMLElement).style.display = 'table-header-group';
    });
  }

  updateMonth() {
    let  monthMapping = {
      'Jan': 'Janeiro',
      'Fev': 'Fevereiro',
      'Mar': 'Março',
      'Abr': 'Abril',
      'Mai': 'Maio',
      'Jun': 'Junho',
      'Jul': 'Julho',
      'Ago': 'Agosto',
      'Set': 'Setembro',
      'Out': 'Outubro',
      'Nov': 'Novembro',
      'Dez': 'Dezembro'
    };
    const elements = Array.from(document.getElementsByClassName('mat-calendar-body-cell-content'));
    elements.forEach(element => {
      const monthAbbreviation = element.textContent.trim();
      if (monthMapping[monthAbbreviation]) {
        element.textContent = monthMapping[monthAbbreviation];
    }
    });
  }


  _writeValue(date: Date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      if (this._typeDate === 'start') {
        this._startDate.setValue(moment(date), { emitEvent: false });
      } else {
        this._endDate.setValue(moment(date), { emitEvent: false });
      }
    }
  }

  writeValue(date: Date): void {
    this._writeValue(date);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this._picker.disabled = isDisabled;
    isDisabled ? this._startDate.disable() : this._startDate.enable();
  }

  _dateChangeHandler(chosenDate: Moment, typeDate: string) {
    this._typeDate = typeDate;
    this.onChange(chosenDate?.toDate());
    this.onTouched();
    if (this._startDate.value) {
      this.selectedEverything.emit({
        'start_period': this._startDate.value,
        'end_period': this._endDate.value
      });
    }
  }

  _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
    if (!datepicker.opened) {
      this.setPanelClassPeriod(datePickerClass.PERIOD);
      datepicker.open();
      this.onTouched();
    }
  }

  private _setupFilter() {
    if(this.mode === 'PERIODO') {
      this._customFilter = (d: Moment) => {
        return !d.day();
      };
    }
  }
  setPanelClassPeriod(view) {
    if (this._panelClass === '') {
      this._panelClass = datePickerClass.PERIOD;
    } else {
      this._panelClass = view;
    }
  }

  get panelClass() {
    return this._panelClass;
  }

  capitalizeMonthPeriod() {
    const contentPeriod = document.getElementsByClassName('mat-calendar-body-cell-content') as HTMLCollectionOf<HTMLElement>;
    Array.from(contentPeriod).forEach((monthPeriod) => {
      monthPeriod.innerText = monthPeriod.innerText.toLowerCase();
      monthPeriod.innerText = monthPeriod.innerText.charAt(0).toUpperCase() + monthPeriod.innerText.slice(1);
    })
  }

  changePanelClassPeriod(eventPeriod) {
    if (eventPeriod === 'year') {
      this.setPanelClassPeriod(datePickerClass.MONTH);
      this.capitalizeMonthPeriod();
    } else if (eventPeriod === 'multi-year') {
      this.setPanelClassPeriod(datePickerClass.YEAR);
    } else {
      this.setPanelClassPeriod(datePickerClass.PERIOD);
    }
  }

  reset() {
    this._startDate.reset()
    this._endDate.reset()
  }

  changePanelClass(event){}
}
