import {
    ChangeDetectorRef,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    HostListener,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
  } from '@angular/core';
  import { LoadingComponent } from '../../components/loads/loading/loading.component';
  
  @Directive({
    selector: '[warningInputInduxtry]',
  })
  export class WarningInputInduxtryDirective implements OnInit, OnChanges {
    @Input() status: string;
    @Input() text: string;
    @Input() styleWarning: string
  
    constructor(
      private el: ElementRef,
      private renderer: Renderer2,
      private resolver: ComponentFactoryResolver,
      private injector: Injector,
      private cdr: ChangeDetectorRef
    ) {}
  
    ngOnInit(): void {}
  
    ngOnChanges(changes: SimpleChanges): void {
      this.createWarning()
    }
  
    createWarning() {
      const nativeElement: HTMLElement = this.el.nativeElement;
  
      // Remover elementos antigos
      this.removeOldElements(nativeElement);
  
      //Elementos que vão ser utilizados
      const containerIcon = nativeElement.getElementsByClassName(
        'mat-form-field-infix'
      );
      const containerBorder = nativeElement.getElementsByClassName(
        'mat-form-field-outline'
      );
      const containerText = nativeElement.getElementsByClassName(
        'mat-form-field-subscript-wrapper'
      );
  
  
      if (containerIcon?.length > 0 && containerText?.length > 0 && containerBorder?.length > 0) {
        let firstItemIcon = containerIcon.item(0) as HTMLElement;
        let firstItemText = containerText.item(0) as HTMLElement;
        let firstItemBorder = containerBorder.item(0) as HTMLElement;
  
        //Criar Ícone
        const imgElement = document.createElement('img');
        imgElement.setAttribute('class', 'dynamic-icon');
        imgElement.setAttribute(
          'style',
          this.styleWarning ? this.styleWarning :
          `z-index: 2; width: 16px; height: 16px; margin-right:10px; align-self: center;`
        );
  
        //Criar Texto
        const textElement = document.createElement('p');
        textElement.setAttribute('class', 'dynamic-text');
        textElement.setAttribute('style', `text-align: right; margin-top: 10px; font-size: 12px;`);
        textElement.textContent = this.text;
  
        if (firstItemIcon && firstItemText && firstItemBorder) {
          switch (this.status) {
            case 'success':
              imgElement.src = '../../../assets/icons/common/warning-sucess.svg';
  
              this.renderer.setStyle(firstItemBorder, 'color', 'var(--green-300)');
              firstItemIcon.insertAdjacentElement('afterend', imgElement);
  
              this.renderer.setStyle(textElement, 'color', 'var(--green-400)');
              firstItemText.insertAdjacentElement('afterend', textElement);
              break;
            case 'error':
              imgElement.src = '../../../assets/icons/common/warning-error.svg';
  
              this.renderer.setStyle(firstItemBorder, 'color', 'var(--red-300)');
              firstItemIcon.insertAdjacentElement('afterend', imgElement);
  
              this.renderer.setStyle(textElement, 'color', 'var(--red-400)');
              firstItemText.insertAdjacentElement('afterend', textElement);
              break;
            case 'info':
              imgElement.src = '../../../assets/icons/common/warning-neutral.svg';
  
              this.renderer.setStyle(firstItemBorder, 'color', 'var(--gray-300)');
              firstItemIcon.insertAdjacentElement('afterend', imgElement);
  
              this.renderer.setStyle(textElement, 'color', 'var(--blue-400)');
              firstItemText.insertAdjacentElement('afterend', textElement);
              break;
            case 'alert':
              imgElement.src =
                '../../../assets/icons/common/warning-circle-alert.svg';
  
              this.renderer.setStyle(firstItemBorder, 'color', 'var(--gray-300)');
              firstItemIcon.insertAdjacentElement('afterend', imgElement);
  
              this.renderer.setStyle(textElement, 'color', 'var(--yellow-500)');
              firstItemText.insertAdjacentElement('afterend', textElement);
              break;
            case 'loading':
  
              const factory =
                this.resolver.resolveComponentFactory(LoadingComponent);
  
              // Cria uma instância do componente de loading
              const loadingComponentRef = factory.create(this.injector);
              const loadingElement = loadingComponentRef.location.nativeElement;
              loadingElement.setAttribute('class', 'dynamic-loading');
              loadingElement.setAttribute(
                'style',
                this.styleWarning ? this.styleWarning :
                `z-index: 2; width: 20px; height: 20px; margin-right:5px; align-self: center;`
              );
  
  
              loadingComponentRef.instance.size = '23px';
              loadingComponentRef.changeDetectorRef.detectChanges();
  
              this.renderer.insertBefore(
                firstItemIcon.parentNode,
                loadingComponentRef.location.nativeElement,
                firstItemIcon.nextSibling
              );
  
              this.renderer.setStyle(firstItemBorder, 'color', 'var(--gray-300)');
  
              this.renderer.setStyle(
                textElement,
                'color',
                'var(--primary-color-dark)'
              );
              firstItemText.insertAdjacentElement('afterend', textElement);
              break;
            default:
              this.renderer.setStyle(firstItemBorder, 'color', 'var(--gray-300)');
              break
          }
        }
      }
    }
  
    private removeOldElements(nativeElement: HTMLElement): void {
      const oldIcons = nativeElement.getElementsByClassName('dynamic-icon');
      const oldTexts = nativeElement.getElementsByClassName('dynamic-text');
      const oldLoadings = nativeElement.getElementsByClassName('dynamic-loading');
  
      while (oldIcons?.length > 0) {
        oldIcons[0].parentNode.removeChild(oldIcons[0]);
      }
      while (oldTexts?.length > 0) {
        oldTexts[0].parentNode.removeChild(oldTexts[0]);
      }
      while (oldLoadings?.length > 0) {
        oldLoadings[0].parentNode.removeChild(oldLoadings[0]);
      }
    }
  
  
  }
  