import { HttpService } from 'src/app/service/http/http.service';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { RequestAttribute, RequestParams } from '../../../../../utils/models/http.interface';

import { mensagem } from '../../../../../utils/mensagens/mensagens';
import moment from 'moment';
import { openModalLoading } from 'src/app/shared/service/utils';


export class DynamicTableDataSource extends DataSource<any> {
  private readonly lessonsSubject = new BehaviorSubject<any[]>([]);
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  private readonly countSubject = new BehaviorSubject<number>(0);
  private readonly filterSubject = new BehaviorSubject<any>({});

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
  public data$ = this.lessonsSubject.asObservable();
  public filters$ = this.filterSubject.asObservable();

  dialogRef;
  loading = true;
  mensagens = mensagem;
  editDefault;

  mapTablesPostFiltersGet = {
    '/alert-histories/uniqueAttributes': true
  }


  constructor(private readonly httpService, public dialog: MatDialog, public external, public amountMergeRows?, public isPost?) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(collectionViewer: CollectionViewer): Observable<readonly any[]> {
    return this.lessonsSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadRequestOrder(data) {
    Promise.resolve(true).then(() => {
      return this.lessonsSubject.next(data);
    });
  }

  loadRequest(endpoint: string, requestParams: RequestParams, fixedAttribute?: any, filtersAttribute?: RequestAttribute[]) {
    this.dialogRef = openModalLoading(this.dialog);
    this.loadingSubject.next(true);
    this.loading = true;
    
    if(this.isPost){
      let body: any
      let url = `${endpoint}?page=${requestParams.page}&limit=${requestParams.limit}&order=${requestParams.order}&sort=${requestParams.sort}`
      if(filtersAttribute.length > 0){
        body = {}
        filtersAttribute.forEach((param, i) => {
          body[`${param.param}`] = param.value
        });
      }
      if(body){
        if(body?.allSelected){
          body.allSelected = body.allSelected === 'true'
        }
        fixedAttribute = body
      }
      this.httpService.genericPost(url, fixedAttribute, {}).pipe(catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe((result: any)=>{
        if(endpoint.includes('alert-histories')&& !endpoint.includes('getSuggestedAmountHistory')){
          result.result.forEach(rows => {
            const date = new Date(rows.day);
            // Adiciona as horas
            date.setHours(date.getHours() + 3);
            rows.day = date.toISOString();
          });
        }
        this.loading = false;
        this.dialogRef.close();
        this.countSubject.next(result.count);
        return this.lessonsSubject.next(result.result ?? result.rows);
      })
    } else {
      if (!this.external) {
        this.httpService
          .genericGetListTable(endpoint, requestParams, fixedAttribute, filtersAttribute)
          .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
          )
          .subscribe((result: any) => {
            this.loading = false;
            this.dialogRef.close();
            this.countSubject.next(result.count);
             if(endpoint.includes('alert-histories')){
              result?.result?.forEach(row => {              
                if(row.level){
                  if(row.level === 'red zone'){
                    row.level = 'Zona Roja'
                  }
                  if(row.level === 'green zone'){
                    row.level = 'Zona Verde'
                  }
                  if(row.level === 'yellow zone'){
                    row.level = 'Zona Amarilla'
                  }
              }
              });
            }
            const rows = result.rows ? result.rows : result.result 
            return this.lessonsSubject.next(rows);
          });
      } else {
        if (this.amountMergeRows) {
          // Adiciona a chave mergeGroupName nos dados da tabela,
          // a quantidade de linhas que sera feito o merge, vem da variavel amountMergeRows
          let mergeGroupName = 1;
  
          for (let i = 0; i < this.external.rows.length; i++) {
            if ((i + 1) % this.amountMergeRows === 0) {
              this.external.rows[i].mergeGroupName = mergeGroupName;
              mergeGroupName++;
            } else {
              this.external.rows[i].mergeGroupName = mergeGroupName;
            }
          }
          this.countSubject.next(this.external.count);
          this.dialogRef.close();
          return this.lessonsSubject.next(this.external.rows);
        } else {
          this.countSubject.next(this.external.count);
          this.dialogRef.close();
          return this.lessonsSubject.next(this.external.rows);
        }
      }
    }
  }

  formatDate = (date: string): string => {
    return moment(new Date(date)).format('DD/MM');
  };

  loadFilters(endpoint: string, requestParams: RequestParams, attribute?: RequestAttribute[]) {
    if (!this.external) {
      if(endpoint.includes('getSuggestedAmountHistory')){
        attribute = []
      }
      if(this.isPost && !this.mapTablesPostFiltersGet[endpoint]){
        let body: any
        let url = `${endpoint}?page=${requestParams.page}&limit=${requestParams.limit}&order=${requestParams.order}&sort=${requestParams.sort}`
        if(attribute.length > 0){
          body = {}
          attribute.forEach((param, i) => {
            body[`${param.param}`] = param.value
          });
        }
        if(body){
          if(body?.allSelected){
            body.allSelected = body.allSelected === 'true'
          }
        }

        this.httpService.genericPost(url, body, {}).pipe(catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe((lessons: any)=>{
        let result = [];
        if (lessons.length === undefined) {
          result.push(lessons);
        } else if (lessons[0] && lessons[0].user) {
          result = lessons;
        }
        return this.filterSubject.next(result);
      })
      }else{
        this.httpService.genericGetListTable(endpoint, requestParams, attribute).subscribe((lessons: any) => {
          let result = [];
          if (lessons.length === undefined) {
            result.push(lessons);
          } else if (lessons[0] && lessons[0].user) {
            result = lessons;
          }
          return this.filterSubject.next(result);
        });
      }
     
    }
  }

  returnLoading() {
    return this.loading;
  }

  setFilteredData(data: any[]) {
    this.lessonsSubject.next(data);
  }
}
