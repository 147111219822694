import { Component, Input,  EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PrefixInput, Warning } from '../../../../../utils/models/input.interface';
import { returnColorWarning, returnTextWarning, returnWarningIcon } from '../../../functions/utilities.service';

@Component({
  selector: 'prefix-input',
  templateUrl: './prefix-input.component.html',
  styleUrls: ['./prefix-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PrefixInputComponent,
    },
  ],
})
export class PrefixInputComponent implements  ControlValueAccessor {
  warningIconPrefixInput = returnWarningIcon;
  colorWarningPrefixInput = returnColorWarning;
  textWarningPrefixInput = returnTextWarning;

  @Input() prefixInput: PrefixInput;

  @Input() warning: Warning = {
    sucess: null,
    error: null,
    info: null,
    alert: null,
    load: null
  };

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();


  writeValue(value: any): void {
    this.prefixInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }


  setValue() {
    this.onChange(this.prefixInput.value);
    this.valueEmitter.emit(this.prefixInput.value);
  }

  getItemStyle(value){
    return { 'color': value? '#333333' : '#949494'}
  }
}
