import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import timelineAll from "../../../../../utils/constants/layout/timeLineAll.json";
import { HttpService } from "src/app/service/http/http.service";
import { GlobalDataService } from "src/app/shared/service/globalDataService";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-modalTimeLine",
  templateUrl: "./modalTimeLine.component.html",
  styleUrls: ["./modalTimeLine.component.scss"],
})
export class ModalTimeLineComponent implements OnInit {
  data = [];
  gapTime = 30000;
  messageFinish = "";
  jsonError: any = "";
  statusError = false;
  timelineOriginal = JSON.parse(JSON.stringify(timelineAll));
  confirmRequest = true;
  indexDelete;
  endpoint: any = "";
  fluxo = "";

  @Input() timeline: any;
  @Input() position = "vertical";
  dataRes: any;
  closeButton: boolean;
  scenarioId: any;
  dataResScenario: unknown;
  selectedFile = null;
  currentForm = null;
  index = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datas,
    public dialog: MatDialog,
    private readonly toastr: ToastrService,
    private readonly httpService: HttpService,
    public dialogRef: MatDialogRef<ModalTimeLineComponent>,
    public globalDataService: GlobalDataService
  ) {}

  ngOnInit(): void {
    this.data = this.timelineOriginal.otmCreate.rows;
    this.requestCenarioTimeLine();
  }

  requestCenarioTimeLine(index = 0) {
    const rows: any[] = this.data;
    this.selectedFile = this.datas?.bodyScenario?.file;
    this.currentForm = this.datas?.bodyScenario?.form;
    this.handleRequests(rows);
  }

  getScenario() {}

  handleClick() {
    this.dialogRef.close(false);
  }

  handleRequests(array) {
    this.handleFormRequest(array).then((res) => {
      this.handleJsonRequest(array, res);
    });
  }

  async handleFormRequest(array) {
    return new Promise((resolve, reject) => {
      array[this.index].status = "loading";
      const form: FormGroup = this.currentForm;
      const payload = {
        type: form.get("radio").value === "Entrada" ? "input" : "output",
        prefix: form.get("prefixo").value,
        description: form.get("description").value,
        observation: form.get("observations").value,
      };
      this.httpService
        .genericPost(array[this.index].endpoint, payload, "")
        .subscribe(
          (res) => {
            array[this.index].status = "concluded";
            this.index++;
            resolve(res);
          },
          (err) => {
            array[this.index].status = "failure";
            this.handleError("Error de creación del formulario");
            resolve(true);
          }
        );
    });
  }

  handleError(msg) {
    this.dialog.closeAll();

    if (!this.toastr.currentlyActive) {
      this.toastr.show(msg, "Error", {
        timeOut: 5000,
        messageClass: "error",
        titleClass: JSON.stringify({}),
      });
    }
  }

  async handleJsonRequest(array, response) {
    const responseId = response.id;
    const file: File = this.datas.bodyScenario.file;
    const formData = new FormData();
    formData.append("file", file);
    array[this.index].status = "loading";
    const endpoint =
      this.currentForm.get("radio").value === "Entrada"
        ? `simulations/uploadInput/${responseId}`
        : `simulations/uploadOutput/${responseId}`;
    array[this.index].endpoint = endpoint;

    return new Promise((resolve, reject) => {
      this.httpService
        .genericPost(array[this.index].endpoint, formData, "")
        .subscribe(
          (res) => {
            array[this.index].status = "concluded";
            this.index++;
            this.globalDataService.handleMockedData(res);
            this.dialog.closeAll();
            resolve(res);
          },
          (err) => {
            array[this.index].status = "failure";
            this.handleError("Error durante la creación de JSON");
            reject("error durante la creación de JSON");
          }
        );
    });
  }

  deletarCenarioCriado(type, dados?) {}

  openLoadError() {}

  getData(url?: any): void {
    this.data = this.timeline;
  }

  isData(data: any[]): boolean {
    return data != null && data.length > 0;
  }

  getIcon(status: string): string {
    if (status === "failure") {
      return "errorBig";
    }
    if (status === "concluded") {
      return "successBlue";
    }
    if (status === "inactive") {
      return "inactive";
    }
    if (status === "loading") {
      return "loading";
    }
  }
}

/* 
mtm rotas
  GET /scenarios (select)  -> select para escolher cenários
  POST /scenarios (formulario) -> post do formulário -> mandar objeto victor e devolver id
  ->muda de acordo com a caracteristica do json
  um ou outro
  POST /simulations/uploadInput/:idScenario (upload do json de entrada)
  POST /simulations/uploadOutput/:idScenario (upload do json de saida)  
  GET /simulations/downloadInput/:idScenario (download do json de entrada)
  GET /simulations/downloadOutput/:idScenario (download do json de saida)
  json  
*/
