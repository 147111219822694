import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  readonly newEvent= new EventEmitter();


  emitScroll(element) {
    this.newEvent.emit(element);
  }

}
