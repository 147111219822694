import {
  Component,
  Input,
  Output,
  EventEmitter,
 
} from "@angular/core";

@Component({
  selector: "legend-grafic",
  templateUrl: "./legend-grafic.component.html",
  styleUrls: ["./legend-grafic.component.scss"],
})
export class LegendGraficComponent   {
  @Input() labels: Array<Object>;

  @Input() width = "auto"; // Largura
  @Input() heigth = "auto"; // Altura
  @Input() direction = "row"; // row ou column
  @Input() position = "right"; // posição right, left, center
  @Input() enableHidden = true;
  @Input() dataSetName = "";
  @Input() titleGroup = null;

  @Output() hiddenData = new EventEmitter();

  clickLegend(e, index, label?) {
    if (this.labels.length > 1) {
      this.hiddenData.emit({ dataSet: e, dataSetIndex: index, label: label });
      const element = document.getElementById(this.dataSetName + index);
      if (element.classList.contains("disabled-icon")) {
        element.classList.remove("disabled-icon");
      } else {
        element.classList.add("disabled-icon");
      }
    }
  }

  getStyle() {
    return this.enableHidden === false
      ? { "pointer-events": "none" }
      : { "pointer-events": "all" };
  }



  removeUnecessaryLegends(query) {
    query = query?.toLowerCase();
    const output = [];
    if (
      query.includes("product") &&
      query.includes("saleschannel") &&
      !query.includes("dateStart") &&
      !query.includes("dateEnd") &&
      !query.includes("reasonWithdrawal")
    ) {
      this.labels.forEach((element: any) => {
        const label = element?.label?.toLowerCase()?.replace("&", "e");
        if (query.includes(label)) {
          output.push(element);
        }
      });
      this.labels = output;
      return;
    }

    if (
      query.includes("product") &&
      !query.includes("salesChannel") &&
      !query.includes("dateStart") &&
      !query.includes("dateEnd") &&
      !query.includes("reasonWithdrawal")
    ) {
      return;
    }
    this.labels.forEach((element: any) => {
      const label = element?.label?.toLowerCase()?.replace("&", "e");
      if (query.includes(label)) {
        output.push(element);
      }
    });
    if (output.length === 0) {
      return;
    } else {
      this.labels = output;
    }
  }
}
