import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';


@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimeLineComponent implements OnInit, AfterViewInit, OnChanges {

  data = [];
  gapTime = 30000;

  @Input() timeline: any;
  @Input() position: string;


  ngAfterViewInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getData();
  }

  ngOnInit(): void {
    setInterval(() => {
      this.getData();
    }, this.gapTime);
  }

  getData(url?: any): void {
    this.data = this.timeline
  }

  isData(data: any[]): boolean {
    return data != null && data.length > 0;
  }

  getIcon(status: string): string {
    let icon = 'next-step';
    if (status === 'failure') {
      icon = 'circle-cancel';
    } else if (status === 'concluded') {
      icon = 'circle-tick';
    }
    return icon;
  }
}
