import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  SwitchData,
  SwitchInput,
} from "../../../../../utils/models/input.interface";

@Component({
  selector: "switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SwitchComponent,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
  @Input() switch: SwitchInput;

  @Output() onChange: EventEmitter<SwitchData> = new EventEmitter<SwitchData>();

  ngOnInit(): void {
    this.switch.checked = false
  }

  switchHovered = false;

  onSwitchMouseEnter() {
    this.switchHovered = true;
  }

  onSwitchMouseLeave() {
    this.switchHovered = false;
  }

  writeValue(value: any): void {
    this.switch.checked = value;
  }

  _onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  changedSwitch() {
    const data: SwitchData = {
      value: !this.switch.checked,
      label: this.switch.label,
    };
    const value = !this.switch.checked;
    this._onChange(value);
    this.onChange.emit(data);
  }
}
