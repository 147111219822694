import {  Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InlineNotificationInterface } from '../../../../../utils/models/common.interface';

@Component({
  selector: 'inline-notification',
  templateUrl: './inline-notification.component.html',
  styleUrls: ['./inline-notification.component.scss']
})
export class InlineNotificationComponent implements OnInit{

  @Input() inline : InlineNotificationInterface;

  @Output() onClose: EventEmitter<string> = new EventEmitter();

  icon = {
    error : 'circle_cancel',
    info : 'circle_information',
    neutral : 'circle_alert',
    warning : 'circle_warning',
    success: 'circle_success',
    question: 'circle_question'
  }

  constructor(private readonly element: ElementRef) { }


  ngOnInit(): void {
    this.getIcon(this.inline.status)
  }



  getIcon(status) {
    return this.icon[status]
  }

  close(): void {

      this.element.nativeElement.remove();
      this.onClose.emit(this.inline.key);
  }

}
