import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

// Interface
import { Menu } from '../../../../../utils/models/layout.interface';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnChanges {

  @Input() menus: Menu[];
  @Output() linkClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() collapse;
  myCollapse = true;


  handleClick(event: boolean): void {
    this.linkClose.emit(event);
  }

  ngOnChanges(change) {
    if (change.collapse) {
      this.myCollapse = this.collapse;
    }
  }

}
