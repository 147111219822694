import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-topnavbar-user',
  templateUrl: './topnavbar-user.component.html',
  styleUrls: ['./topnavbar-user.component.scss']
})
export class TopnavbarUserComponent {

  @Input() breakpoint: boolean;
  openMenu = false;


  menuAction(validation: boolean): boolean {
    this.openMenu = validation;
    return validation;
  }

}
