import { DynamicSubTableComponent } from './../dynamic-sub-table/dynamic-sub-table.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe, DecimalPipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";
import moment from "moment";
import { Subject, Subscription } from "rxjs";

import { RequestAttribute } from "../../../../../utils/models/http.interface";
import { DynamicTable } from "../../../../../utils/models/table.interface";
import { NavigationService } from "../../../service/navigation.service";
import { PageSelectComponent } from "../../common/page-select/page-select.component";
import { ScrollService } from "../../scrolls/service/scroll.service";
import { DynamicTableDataSource } from "./dynamic-table-datasource";
import { RowsMerge, Span } from "./mergeRowsFunction";
import { HttpService } from "./service/httpService.service";
import { openModalLoading } from "src/app/shared/service/utils";
import { SelectInfiniteScrollSearchComponent } from "../../scrolls/select-infinite-scroll-search/select-infinite-scroll-search.component";
import { MatSelect } from "@angular/material/select";
import { ToastrService } from "ngx-toastr";
import { MatCheckbox } from '@angular/material/checkbox';

const NUMBER_ITENS_DEFAULT = 10;
@Component({
  selector: "dynamic-table",
  templateUrl: "./dynamic-table.component.html",
  styleUrls: ["./dynamic-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", border: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class DynamicTableComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;

  private readonly _unsubscribe$: Subject<any> = new Subject();

  @ViewChildren("filterTable") filterTables;
  @ViewChildren("selectInfiniteScroll")
  selectInfiniteScroll: QueryList<SelectInfiniteScrollSearchComponent>;
  @ViewChild("subTable")subTable:DynamicSubTableComponent;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild("pageSelect", { static: true }) pageSelect: PageSelectComponent;
  @ViewChildren("checkBox") checkBox: QueryList<any>;
  @ViewChildren("inputViewChild") inputViewChild: QueryList<Input>;
  @ViewChildren("matSelectViewChild") matSelectViewChild: QueryList<MatSelect>;

  @Input() dynamicTable: DynamicTable;
  @Input() id: string;
  @Input() attributes: RequestAttribute[];
  @Input() manyItens = NUMBER_ITENS_DEFAULT;
  @Input() hasPaginate = true;
  @Input() tableTitle: string;
  @Input() externalData;
  @Input() showCheckbox = false;
  @Input() hasSort = true;
  @Input() hasDelete = false;
  @Input() totalOnHead = false;
  @Input() titleOnHead = false;
  @Input() attributesSubtable: RequestAttribute[];
  @Input() salveExpand = false;
  @Input() compareWith = false;
  @Input() pageSizeOptions: number[];
  @Input() accordionRequest = false;
  @Input() timeoutLoading = 3000;
  @Input() stickyHeader = false;
  @Input() scrollTable = false;
  @Input() hasCumulativeFilter = false;
  @Input() maxCustomHeight = '984px'
  @Input() bodyToUpdate = { itensToUpdate: [] }
  @Input() subTableScroll = false



  // Inputs necessarios para fazer o merge nas linhas
  @Input() mergedCells = false;
  @Input() amoutMergedCells: number;
  @Input() modalMessage: "";
  @Input() modalTitle: "";


  // Output Action
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() view: EventEmitter<any> = new EventEmitter();
  @Output() idsOrders: EventEmitter<any> = new EventEmitter();
  @Output() getEdit: EventEmitter<any> = new EventEmitter();
  @Output() getAdd: EventEmitter<any> = new EventEmitter();
  @Output() modalEdit: EventEmitter<any> = new EventEmitter();
  @Output() subTableFilter: EventEmitter<any> = new EventEmitter();

  // Output Uteis
  @Output() loadedTable: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() indexEdited: EventEmitter<any> = new EventEmitter();

  dataSource: DynamicTableDataSource;
  pinSaves = {}

  _dynamicTable: DynamicTable;
  attributeParams: any;
  filterParams: RequestAttribute[] = [];

  data: any[] = [];
  editRow: boolean[] = [];
  checked = [];
  checks = new Array(NUMBER_ITENS_DEFAULT).fill(false);
  ords = [];
  ids2 = [];
  ids = [];
  haveSubIds;
  subTotalAttr: any = [];
  subTotal: any = [];

  filterOptions = {};

  dateFormat = "DD/MM/YYYY";
  dateHourFormat = "DD/MM/YYYY - HH:mm";
  flagFilter = true;
  width;
  dateForm: FormGroup;
  flagEditAll: boolean;
  expandedElement: any = null;
  expandedElements: any[] = [];
  // configApi: ConfigApiDynamicTable;

  subTableOpen = false;
  headerRowIds: string[];
  unSub: Subscription;
  rowSpanComputer = new RowsMerge();
  rowSpans: Array<Span[]>;
  dataRows: any = [];
  indexSubTable = 0;
  expandedItem = false;
  expandAll = false;
  notFirst: boolean;
  optionsSelect = [];
  isValueInvalid = false;



  endpointsUmaCasaDecimal = {
    'alert-histories': true
  }

  constructor(
    private readonly decimalPipe: DecimalPipe,
    private readonly datePipe: DatePipe,
    private readonly formBuilder: FormBuilder,
    public dialog: MatDialog,
    public httpService: HttpService,
    private readonly nav: NavigationService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly scrollService: ScrollService,
    private readonly toastr: ToastrService
  ) {

    this.dateForm = this.formBuilder.group({
      date: [null],
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event): void {
    this.width = event.target.innerWidth;
    this.reloadSticky();
  }

  ngOnInit(): void {
    if (this.hasDelete) {
      this.deleteTemporaryItemsTable()
    }
    const loadingModal = openModalLoading(this.dialog);
    setTimeout(() => {
      loadingModal.close();
    }, this.timeoutLoading);
    this.initialDynamicFunction();
  }

  initialDynamicFunction() {
    if (this.mergedCells) {
      this.dataSource = new DynamicTableDataSource(
        this.httpService,
        this.dialog,
        this.externalData,
        this.amoutMergedCells,
        this._dynamicTable.post
      );
    } else {
      this.dataSource = new DynamicTableDataSource(
        this.httpService,
        this.dialog,
        this.externalData,
        false,
        this._dynamicTable.post
      );
    }

    if (this.attributes !== undefined) {
      this.attributeParams = this.attributes;
    }

    this._dynamicTable.requestParams.limit = this.manyItens;
    // if (this._dynamicTable.post){
    //   this.dataSource.loadPost(
    //     this._dynamicTable.endpoint,
    //     this._dynamicTable.requestParams,
    //     this.attributeParams,
    //     this.filterParams
    //   );
    // }else{
      this.dataSource.loadRequest(
        this._dynamicTable.endpoint,
        this._dynamicTable.requestParams,
        this.attributeParams,
        this.filterParams
      );
    // }

    const hasSomeFilter = this._dynamicTable.displayedColumns.some(
      (column) => column.hasFilter === true
    );
    if (hasSomeFilter) {
      this.dataSource.loadFilters(
        this._dynamicTable.filterEndpoint,
        this._dynamicTable.requestParams,
        this.filterParams
      );
    }
    if (this.mergedCells) {
      this.rowsMerge();
    }
    if (this._dynamicTable.multipleTotals) {
      this.dataSource.data$.subscribe((data: any) => {
        this.convertDataMultipleTotais(data);
      });
    }
  }

  openedSelectTable(selectInterfaceColumn, Element?, view?): void {
    this.httpService
      .genericGetSelect(selectInterfaceColumn, Element)
      .subscribe((response) => {
        view.options = response.rows;
      });
  }

  getObjectbyElement(element, column) {
    const select = column.selectInterface;
    return (
      select.options.find(
        (op) => op[select.returnVariable] === element[column.attribute]
      ) || null
    );
  }

  addValuesTotalLine(data: any[], item: any): any {
    data = Array.from(new Set(data));
    const attributesContainsTotal =
      this._dynamicTable?.groupMultipleTotals.attributesContainsTotal;
    if (attributesContainsTotal) {
      attributesContainsTotal.forEach((attribute) => {
        let totalValue = 0;
        data.forEach((value) => {
          if (value[attribute]) {
            totalValue += parseInt(value[attribute]);
          }
        });
        item[attribute] = totalValue;
      });
      this._dynamicTable.columns.forEach((column) => {
        if (attributesContainsTotal.indexOf(column) === -1) {
          if (
            column === this._dynamicTable?.groupMultipleTotals.attributeLabel
          ) {
            item[column] = this._dynamicTable?.groupMultipleTotals.label;
          } else {
            item[column] = "";
          }
        }
      });
    }
    return item;
  }

  convertDataMultipleTotais(data: any): void {
    const propertyGroup = this._dynamicTable?.groupMultipleTotals.groupLabel;
    let nowGroup =
      data.length > 0 && data[0][propertyGroup] ? data[0][propertyGroup] : null;
    let indexInsert = [];
    if (nowGroup) {
      let nowItem: any;
      let beforeGroup = [];
      data.forEach((item, index) => {
        if (nowGroup !== item[propertyGroup]) {
          nowItem = { totalLine: true, group: nowGroup };
          nowItem = this.addValuesTotalLine(beforeGroup, nowItem);
          indexInsert.push({
            index: index,
            property: nowGroup,
            nowItem: nowItem,
          });
          nowGroup = item[propertyGroup];
          beforeGroup = [];
        }
        beforeGroup.push(item);
      });
      nowItem = { totalLine: true, group: nowGroup };
      nowItem = this.addValuesTotalLine(beforeGroup, nowItem);
      indexInsert.push({
        index: data.length,
        property: nowGroup,
        nowItem: nowItem,
      });
      indexInsert.forEach((item, index) => {
        const indexTotalElement = data.findIndex(
          (res) => res.totalLine === true && res.group === item.nowItem.group
        );
        if (indexTotalElement !== -1) {
          data[indexTotalElement] = item.nowItem;
        } else {
          Array.prototype.splice.apply(
            data,
            [item.index + index, 0].concat([item.nowItem])
          );
        }
      });
      this.removeMultipleTotalLines(data);
      data.filter(
        (res) =>
          !(
            (res.group === undefined || res.group === "") &&
            res.totalLine === true
          )
      );
      this.cdRef.markForCheck();
    }
  }

  removeMultipleTotalLines(data) {
    const arrayLines = data.filter(
      (res) => !res.group && res.totalLine === true
    );
    arrayLines.forEach((element) => {
      const indexLine = data.findIndex((res) => res === element);
      if (indexLine !== -1) {
        data.splice(indexLine, 1);
      }
    });
  }

  originalData: any;
  ngAfterViewInit() {
    this.tableContainer.nativeElement.addEventListener(
      "scroll",
      this.onScroll.bind(this)
    );
    this.unSub = this.scrollService.newEvent.subscribe((res) => {
      this.tableContainer.nativeElement.scrollLeft = res;
    });

    this.width = window.innerWidth;

    this.dataSource.filters$.subscribe((data) => {
      this.setFilterOptions(data[0]);
    });
    this.dataSource.data$.subscribe((data: any) => {
      if(data){
        data.forEach((item, index) => {
          if (this.checked.includes(item.id)) {
  
            this.checks[index] = true;
          } else {
            this.checks[index] = false;
          }
        });
      }

      if (data && data.length) {
        let aux = "";
        data.forEach((e) => {
          if (e.favoriteMachines) {
            e.favoriteMachines.forEach((f) => {
              aux += f + ",";
            });
            e.favoriteMachines = aux.replace(/,\s*$/, " ");
            aux = "";
          }
        });

        if (data[0]?.dates) {
          this._dynamicTable = JSON.parse(JSON.stringify(this.dynamicTable));
          data.forEach((e, index) => {
            data[index].dates.forEach((element, i) => {
              e[`dates${i}`] = element.value;
            });
          });

          const dynamicAux = this._dynamicTable.columns.pop();
          data[0].dates.forEach((e, i) => {
            this._dynamicTable.columns.push(`dates${i}`);
            this._dynamicTable.displayedColumns.push({
              attribute: `dates${i}`,
              title: e.date,
              type: "number",
              sticky: false,
              subtitle: {
                name: "",
                lock: true,
              },
            });
          });
          this._dynamicTable.columns.push(dynamicAux);
        }
      }
      this.data = data;
      this.originalData = JSON.parse(JSON.stringify(this.data))

      this.loadedTable.emit(data);
      this.reloadSticky();

      this.editRow = new Array(this.data.length).fill(false);
    });

    if (this.dynamicTable.headersColuns) {
      this.headerRowIds = this._dynamicTable.headersColuns.map((row) => row.id);
    }
    if (this.totalOnHead || this.titleOnHead) {
      this.getTotalValues(this.data);
    }
    this.cdRef.detectChanges();
  }

  resetTableData() {
    this.dataSource.loadRequestOrder(JSON.parse(JSON.stringify(this.originalData)))
    setTimeout(() => this.resetSelectInfiniteValue(), 100)
  }

  resetSelectInfiniteValue() {
    this.selectInfiniteScroll.forEach((e) => {
      e.resetValueToFirst()
    })
  }

  ngOnChanges(changes): void {
    if (this.dynamicTable) {
      this._dynamicTable = JSON.parse(JSON.stringify(this.dynamicTable));
    }
    if (!this.showCheckbox) {
      this.uncheckAll();
    }
    if (this.hasDelete) {
      this.ids = this.data.map((data) => data.id);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next("");
    this._unsubscribe$.complete();
    this.unSub.unsubscribe();
  }

  changePage(event): void {
    if (this.bodyToUpdate.itensToUpdate.length > 0) {
      this.updateTemporaryItemsTable()
    }
    this._dynamicTable.requestParams.page = event;
    this.loadTable();
  }

  changeManyItens(event): void {
    if (this.bodyToUpdate.itensToUpdate.length > 0) {
      this.updateTemporaryItemsTable()
    }
    this._dynamicTable.requestParams.limit = event;
    this._dynamicTable.requestParams.page = 1;
    this.loadTable();
  }

  updateTemporaryItemsTable() {
    this.httpService.genericPut(this._dynamicTable.updateEndpoint, this.bodyToUpdate).subscribe()
  }
  deleteTemporaryItemsTable() {
    this.httpService.genericDelete(this._dynamicTable.deleteEndpoint).subscribe()
  }
  trackArray(index): number {
    return index;
  }

  async loadTable(reloadFilter = false) {
    if (reloadFilter) {
      this.dataSource.loadFilters(
        this._dynamicTable.filterEndpoint,
        this._dynamicTable.requestParams,
        this.filterParams
      );
    }

    this.attributesSubtable = [];
    // if (this._dynamicTable.post){
    //   this.dataSource.loadPost(
    //     this._dynamicTable.endpoint,
    //     this._dynamicTable.requestParams,
    //     this.attributeParams,
    //     this.filterParams
    //   );
    // }else{
    this.dataSource.loadRequest(
      this._dynamicTable.endpoint,
      this._dynamicTable.requestParams,
      this.attributeParams,
      this.filterParams
    );
    // }


  }
  async reloadSticky() {
    await this.sleep(1);
    this.table.updateStickyColumnStyles();
  }

  sortFunctionData(a, b) {
    let lenghtSort = 0;
    const typeData = typeof a[this._dynamicTable.requestParams.sort];
    if (typeData === "undefined") {
      lenghtSort =
        a[this._dynamicTable.requestParams.sort] === undefined &&
          b[this._dynamicTable.requestParams.sort] !== undefined
          ? 1
          : -1;
    } else if (typeData === "object") {
      const strA = a[this._dynamicTable.requestParams.sort]
        .toString()
        .toLowerCase();
      const strB = b[this._dynamicTable.requestParams.sort]
        .toString()
        .toLowerCase();
      lenghtSort = this.validObjectTypeSort(strA, strB);
    } else if (
      this.validAllDate(
        a[this._dynamicTable.requestParams.sort],
        b[this._dynamicTable.requestParams.sort]
      )
    ) {
      const dates = this.attributeDateSort(
        a[this._dynamicTable.requestParams.sort],
        b[this._dynamicTable.requestParams.sort]
      );
      lenghtSort = this.validLenghtSort(dates.firstDate, dates.secondDate);
    } else if (!isNaN(parseFloat(a[this._dynamicTable.requestParams.sort]))) {
      const auxA = parseFloat(a[this._dynamicTable.requestParams.sort]);
      const auxB = parseFloat(b[this._dynamicTable.requestParams.sort]);
      lenghtSort = this.validLenghtSort(auxA, auxB);
    } else if (typeData === "number") {
      lenghtSort = this.validLenghtSort(
        a[this._dynamicTable.requestParams.sort],
        b[this._dynamicTable.requestParams.sort]
      );
    } else if (typeData === "string") {
      const strA = a[this._dynamicTable.requestParams.sort]
        .toString()
        .toLowerCase();
      const strB = b[this._dynamicTable.requestParams.sort]
        .toString()
        .toLowerCase();

      lenghtSort = this.validLenghtSort(strA, strB);
    }

    return lenghtSort;
  }

  validLenghtSort(a, b) {
    return a > b ? 1 : -1;
  }

  validObjectTypeSort(objectA, objectB) {
    if (objectA === "" && objectB !== "") {
      return 1;
    } else if (objectA !== "" && objectB === "") {
      return -1;
    }

    return this.validLenghtSort(objectA, objectB);
  }

  validDateSort(date, format) {
    return moment(date, format, true).isValid();
  }

  validAllDate(dateA, dateB) {
    return (
      (this.validDateSort(dateA, this.dateFormat) &&
        this.validDateSort(dateB, this.dateFormat)) ||
      (this.validDateSort(dateA, this.dateHourFormat) &&
        this.validDateSort(dateB, this.dateHourFormat))
    );
  }

  attributeDateSort(firstDate, secondDate) {
    let dateA;
    let dateB;
    if (this.validDateSort(firstDate, this.dateFormat)) {
      dateA = moment(firstDate, this.dateFormat, true);
      dateB = moment(secondDate, this.dateFormat, true);
    } else {
      dateA = moment(firstDate, this.dateHourFormat, true);
      dateB = moment(secondDate, this.dateHourFormat, true);
    }

    return { firstDate: dateA, secondDate: dateB };
  }

  handleSort(att: string): void {
    if (this._dynamicTable.requestParams.sort === att) {
      if (this._dynamicTable.requestParams.order === 'desc') {
        this._dynamicTable.requestParams.order = 'asc';
        this._dynamicTable.requestParams.sort = att;
      } else if (this._dynamicTable.requestParams.order === 'asc') {
        this._dynamicTable.requestParams.order = 'desc';
        this._dynamicTable.requestParams.sort = `-${att}`;
      }
    } else {
      this._dynamicTable.requestParams.order = 'asc';
      this._dynamicTable.requestParams.sort = att;
    }
    // this.data.sort((a, b) => this.sortFunctionData(a, b));

    // if (this._dynamicTable.requestParams.order === "desc") {
    //   this.data.reverse();
    // }
    this.dataSource.loadRequestOrder(this.data);
    this.loadTable(false)
  }

  emptyText(e: any, isInt = false, precision, attribute = '') {
    const elementType = typeof e
    let decimalPrecion = precision ? precision : 2
    const formatter = new Intl.NumberFormat('es-CO', {
      style: 'decimal',
      minimumFractionDigits: decimalPrecion,
      maximumFractionDigits: decimalPrecion,
    });
    if(elementType === 'number' && !isInt && attribute !== 'cod'){
      return formatter.format(e);
    }else if(elementType === 'number' && precision ){
      return formatter.format(e);
    }else if(elementType === 'number' && attribute !== 'cod'){
      const integerFormatter = new Intl.NumberFormat('es-CO');
      return integerFormatter.format(e);
    }
    return e != null && e !== "" ? e : "-";
  }


  optionsMask(){
    if(this.endpointsUmaCasaDecimal[this._dynamicTable.endpoint]){
      return { thousands: '.', decimal: ',', precision: 1 }
    }

    return { thousands: '.', decimal: ',' }
  }

  

  setFilterOptions(options: any) {
    this._dynamicTable?.displayedColumns?.forEach((column) => {
      this.filterOptions[column.attribute] = [];
    });
    if (options) {
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          const aux = this._dynamicTable.displayedColumns.find(
            (item) => item.attribute === key
          );
          if (aux) {
            const tipo = aux.type;
            options[key].forEach((element) => {
              this.updateFilterElements(key, element, tipo);
            });
          }
        }
      });
    }
  }

  updateFilterElements(key, element, tipo) {
    if (element === null) {
      this.filterElement(key, element, " ");
    } else if (tipo === "number" || tipo === "suffix") {
      this.filterElement(
        key,
        element,
        element
        // this.decimalPipe.transform(element, "1.0-2")
      );
    } else if (tipo === "text" || tipo === "array") {
      this.filterElement(key, element, element);
    } else if (tipo === "date") {
      this.filterElement(
        key,
        element,
        this.datePipe.transform(element, "dd/MM/yyyy")
      );
    } else if (tipo === "date-time") {
      this.filterElement(
        key,
        element,
        this.datePipe.transform(element, "dd/MM/yyyy 'às' HH:mm")
      );
    } else if (tipo === "bool") {
      this.filterElement(key, element, this.elementBoolValid(element));
    } else if (tipo === "select") {
      this.filterElement(key, element, element);
    }
  }

  elementBoolValid(element) {
    return element ? "Sim" : "Não";
  }

  filterElement(key, valueElement, friendlyElement) {
    this.filterOptions[key].push({
      value: valueElement,
      friendly: friendlyElement,
      check: false
    });
  }

  preventInfinite = false
  handleFilter(filters: any, att: string) {    
    const indexAllSelected = this.filterParams.findIndex(item => item.param === 'allSelected')
    if(filters.filter.includes('allOpt')){
      if(indexAllSelected !== -1){
        this.filterParams[indexAllSelected].value = 'true'
      }else{
        this.filterParams.push({param: 'allSelected', value: 'true'})
      }
    }else{

      if(indexAllSelected !== -1){
        this.filterParams[indexAllSelected].value = 'false'
      }
    
    }

    const index = this.filterParams.findIndex((item) => item.param === att);
    if (index > -1) {
      this.filterParams.splice(index, 1);
    }

    if (filters.filter.length > 0) {
      if (["activities", "routes", "carbonContent"].includes(att)) {
        this.filterParams.push({
          param: att,
          value: filters.filter.map((item) => item.value).join(","),
        });
      } else {
        let valueAtt = "";
        if (typeof filters.filter[0] === "object") {
          if (att === "user") {
            valueAtt = filters.filter
              .map((item) => item.value._id.replace(/,/g, "."))
              .join(",");             
          } 
            else {
              valueAtt = filters.filter           
              .map((item) => {
                if (item.value !== null && item.value !== undefined) {                       
                  return item.value;
                }
              });            
          }
        } else {
          valueAtt = filters.filter.join(",");
        }        
        this.filterParams.push({
          param: att,
          value: valueAtt,
        });
      }   
    }
    if(indexAllSelected !== -1 && this.filterParams.length === 1){
      this.filterParams = []
    }
    this.preventInfinite = this.filterParams.length > 0
    this.flagEditAll = this.filterParams.length > 0 ? true : false;
    this.filter.emit(this.filterParams);
    this.loadTable();
    if (this.hasCumulativeFilter) {
      this.dataSource.loadFilters(
        this._dynamicTable.filterEndpoint,
        this._dynamicTable.requestParams,
        this.filterParams
      );
    }

  }

  handlePin(column: any) {
    if (!column.sticky) {
      column["sticky"] = true;
    } else {
      column.sticky = !column.sticky;
    }
  }

  handleEdit(event: any, subTable?): void {
    let emitSubtable = event;
    if (subTable) {
      emitSubtable = {
        subTableEdit: true,
        obj: event,
      };
    }
    this.editRow = new Array(this.data.length).fill(false);
    this.getEdit.emit(this.editRow);
    this.edit.emit(emitSubtable);
  }

  handleDuplicate(event: any, subTable?): void {
    if (subTable) {
      const duplicateSub = {
        subTableDupli: true,
        obj: event,
      };
      this.duplicate.emit(duplicateSub);
    } else {
      this.duplicate.emit(event);
    }
  }

  handleDelete(event: any, subTable?, dataIndex?): void {
    if (subTable) {
      const deleteLineSub = {
        subTableDelete: true,
        obj: event,
      };
      this.delete.emit(deleteLineSub);
    } else {
      this.deleteAdd(event, dataIndex);
      this.delete.emit(event);
    }
  }

  handleView(event: any, subTable?): void {
    if (subTable) {
      const viewLineSub = {
        subTableView: true,
        obj: event,
      };
      this.view.emit(viewLineSub);
    } else {
      this.view.emit(event);
    }
  }

  modalEditElement(event: any, subTable?): void {
    if (subTable) {
      const modalEditLineSub = {
        subTableView: true,
        obj: event,
      };
      this.modalEdit.emit(modalEditLineSub);
    } else {
      this.modalEdit.emit(event);
    }
  }

  verifyErrorsMultipleTotalColumns(element, attribute): boolean {
    element = parseInt(element);
    const attributesContainsTotal =
      this._dynamicTable?.groupMultipleTotals.attributesContainsTotal;
    if (
      element >= 0 &&
      attribute &&
      attributesContainsTotal.indexOf(attribute) !== -1 &&
      (element < this._dynamicTable?.groupMultipleTotals.min ||
        element > this._dynamicTable?.groupMultipleTotals.max)
    ) {
      return true;
    }
    return false;
  }

  changeMultipleTotal(data, indexRow) {
    const propertyGroup = this._dynamicTable?.groupMultipleTotals.groupLabel;
    const element = data[indexRow];
    let nowItem: any;
    if (element) {
      const values = data.filter(
        (res) => res[propertyGroup] === element[propertyGroup]
      );
      nowItem = { totalLine: true, group: element[propertyGroup] };
      nowItem = this.addValuesTotalLine(values, nowItem);
      const totalElement = data.find(
        (res) => res.group === element[propertyGroup] && res.totalLine === true
      );
      const attributesContainsTotal =
        this._dynamicTable?.groupMultipleTotals.attributesContainsTotal;
      if (totalElement && attributesContainsTotal) {
        attributesContainsTotal.forEach((attribute) => {
          totalElement[attribute] = nowItem[attribute];
        });
      }
    }
  }

  selectValuesInfinitScroll(element, column) {
    return [element, column];
  }

  checkValue(value, column) {
    if (value === '' || Number.isNaN(value) || value === null) {
      column['isValueInvalid'] = true
      return true
    }
    return false
  }

  savePinColumn(column, row){

    if (!this.pinSaves[row.family]) {
      this.pinSaves[row.family]= {};
    } 
    this.pinSaves[row.family][column.attribute] = column.sticky;

  }

  setEdit(indexRow, subTable?, type?: string, attribute?: string) {
    if (subTable) {
      this.editRow[indexRow?.indexTableLine] = true;
      const editedItens = {
        row: indexRow,
        editRow: this.editRow,
        editSubRow: indexRow.subTableLine,
      };
      this.getEdit.emit(editedItens);
    } else {
      if(attribute === 'alpha' && this.data[indexRow][attribute] > 1){
        this.data[indexRow][attribute] = 1
      }
      if (this.data[indexRow] && (this.data[indexRow][attribute] === '' || Number.isNaN(this.data[indexRow][attribute]) || this.data[indexRow][attribute] === null)) {
        this.data[indexRow]['isValueInvalid'] = true
        if (this.toastr.toasts.length === 0) {
          this.toastr.show(
            'Por favor, escriba en el formato permitido.',
            'Error al editar el dato',
            {
              messageClass: 'error',
              timeOut: 2000,
            }
          );
        }
      } else {
        this.data[indexRow]['isValueInvalid'] = false
      }
      this.data[indexRow].row_edited = true;
      this.editRow[indexRow] = true;
      const editedTable = {
        row: this.data[indexRow],
        editRow: this.editRow,
      }
      this.getEdit.emit(editedTable);
    }

    if (this._dynamicTable.multipleTotals) {
      this.changeMultipleTotal(this.data, indexRow);
    }
    this.indexEdited.emit(this.data);
    this.edit.emit(this.data);
  }

  setEditSelect(indexRow, attribute, hasChangeAll, isInfinite = true) {
    if (hasChangeAll) {
      const valueChange = this.data[indexRow][attribute]
      this.data.forEach((e) => {
        e[attribute] = valueChange
      })
      // if(isInfinite) {
      //   this.selectInfiniteScroll.forEach((e) => {
      //     if(e.key === attribute) {
      //       const index = e._options.findIndex((option) => option[hasChangeAll.key] === valueChange[hasChangeAll.key])
      //       e.writeValue(valueChange)      
      //       e._options.splice(index, 1);
      //       e._options.unshift(valueChange)
      //     }
      //   })      
      // }
    }

    this.setEdit(indexRow);
  }

  setRowCol(row, col) {
    let input = document.getElementById(`[${row + 1}][${col}]`);
    if (!input) {
      input = document.getElementById(`[${0}][${col}]`);
    }
    input.focus();
  }

  decimalFilter(event: any) {
    const reg = /^\d*(?:[.,]\d{0,2})?$/;

    const input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  changeDate(event: any) {
    this.flagFilter = event ? false : true;
  }

  toggleDetails(row: any, index) {
    this.indexSubTable = index;
    this.expandedItem = true;
    this.attributesSubtable = [];
    const i = this.expandedElements.indexOf(row);
    if (i > -1) {
      this.expandedElements.splice(i, 1); // Remove a linha do array
    } else {
      this.expandedElements = Array(this.indexSubTable).fill(null);
      this.expandedElements[this.indexSubTable] = row;
    }
    this.subTableOpen = this.expandedElements.length > 0; // Verifica se há alguma linha expandida
    if(this.pinSaves[row.family]){
        this._dynamicTable.subTableConfig.displayedColumns.forEach(element => {
          if(this.pinSaves[row.family][element.attribute]){ element.sticky = true } 
          else if(element.attribute !== 'name'){
            element.sticky = false
          }
        });
    }

    setTimeout(() => {
      if(this.subTable && row?.checked){
        const checkBox = this.subTable.checkBox.toArray()
        checkBox.forEach((checkbox:MatCheckbox) => {
          checkbox.checked = true
        })
      }
    },500)
  
  }

  closedSubTable(event) {
    this.expandedElement = null;
    this.subTableOpen = false;
  }

  classExpanded(row) {
    return this.expandedElements.includes(row);
  }

  getCheckboxAll(checkbox) {
    if (checkbox._checked) {
      this.ords = [];
      this.checked = [];
      this.data.forEach((res) => {
        res.checked = checkbox._checked;
        if (checkbox._checked) {
          this.checked.push(res);
        }
      });
      this.ords = this.checked
      if (this.haveSubIds !== undefined) {
        const deleteIds = {
          tableIds: this.ords.toString(),
          subTable: this.haveSubIds,
        };
        this.idsOrders.emit(deleteIds);
      } else {
        this.idsOrders.emit(this.ords);
        const objId = {
          ids: this.ids,
          checked: checkbox._checked,
        };
        this.nav.changeReturnIds.emit(objId);
      }
    } else {
      this.data.forEach((res) => {
        res.checked = checkbox._checked;
      });
      this.checked = [];
      this.ords = []
      this.idsOrders.emit(this.ords);
      const objId = {
        ids: this.ords,
        checked: checkbox._checked,
      };
      this.nav.changeReturnIds.emit(objId);
    }
  }

  reciveIdsSubTable(event) {
    if (this.ords.length !== 0) {
      const deleteIds = {
        tableIds: this.ords.toString(),
        subTable: event,
      };
      this.idsOrders.emit(event);
      this.haveSubIds = event;
    } else {
      this.idsOrders.emit(event);
      this.haveSubIds = event;
    }
  }

  checkedAll = false;
  getCheckboxOne(index, element) {
    element.checked = !element.checked;
    this.checks[index] = !this.checks[index];
    this.checkedAll = this.data.every(objeto => {
      return objeto.checked;
    });
    const selectedValue = this.data[index]
    if (this.checks[index]) {
      this.checked.push(selectedValue);
      this.ords.push(selectedValue);
    } else {
      this.checked.splice(this.checked.indexOf(selectedValue), 1);
      this.ords.splice(this.ords.indexOf(selectedValue), 1);
    }


    this.idsOrders.emit(selectedValue);
    if(this.subTable){
      const checkBoxSub = this.subTable.checkBox.toArray()
      checkBoxSub.forEach((checkbox:MatCheckbox) => {
        if(this.indexSubTable === index){
          checkbox.checked = element.checked
        }
      })
    }
  }

  uncheckAll() {
    this.checked = [];
    this.ords = [];
    this.checks = [];
  }

  getTotalValues(data) {
    this._dynamicTable.displayedColumns.forEach((obj, i) => {
      if (obj.hasSubTotal) {
        const column = {
          total: data.reduce((a, b) => a + b[obj.attribute], 0),
          index: i.toString(),
        };
        this.subTotal.push(column);
      } else if (obj.hasSubTitle) {
        const column = {
          title: obj.subTitleHeader.title,
          index: i.toString()
        }
        this.subTotal.push(column);
      } else {
        const column = {
          total: "",
          index: i.toString(),
        };
        this.subTotal.push(column);
      }
    });

    if (this._dynamicTable.haveActions) {
      const index = this.subTotal.length.toString();
      const column = {
        total: "",
        index: index,
      };
      this.subTotal.push(column);
    }

    this.subTotalAttr = this.subTotal.map((value) => value.index);
  }

  onScroll(event: Event) {
    const scrollPosition = (event.target as HTMLElement).scrollLeft;
    this.scrollService.emitScroll(scrollPosition);
  }

  rowsMerge() {
    this.dataSource.data$.subscribe((data: any) => {
      this.dataRows = data;
    });
    this.rowSpans = this.rowSpanComputer.compute(
      this.dataRows,
      this._dynamicTable.displayedColumns
    );
  }

  emitFilter(event) {
    if (this.salveExpand) {
      if (event["filterParams"].length > 0) {
        event["filterParams"].forEach((item) => {
          const index = this.attributes.findIndex(
            (att) => att.param === item.param
          );
          if (index === -1) {
            this.attributes.push(item);
          } else {
            this.attributes[index].value = item.value;
          }
        });
      } else {
        const index = this.attributes.findIndex(
          (item) => item.param === event["att"]
        );
        if (index > -1) {
          this.attributes.splice(index, 1);
        }
      }
    }
    this.subTableFilter.emit(event["filterParams"]);
  }

  clearSubTable(reset = false): void {
    let itens = ["scenarioId", "monthRef"];
    const attAuxClear = [];
    itens.forEach((item) => {
      const index = this.attributes.findIndex((att) => att.param === item);
      if (index > -1) {
        attAuxClear.push(this.attributes[index]);
      }
    });
    if (attAuxClear.length > 0) {
      this.attributes = attAuxClear;
    }
    if (reset) {
      this.subTableOpen = false;
      itens = ["month", "year"];
      itens.forEach((e) => {
        const index = this.filterParams.findIndex((att) => att.param === e);
        if (index > -1) {
          this.filterParams.splice(index, 1);
        }
      });
      this.loadTable();
    }
    this.closedSubTable(null);
  }

  expandAllRows() {
    this.expandAll = !this.expandAll;
    if (this.expandAll) {
      const allRows = [];
      this.dataSource.data$.subscribe((res) => {
        res.forEach((element) => {
          if (element.subTable) {
            allRows.push(element);
          }
        });
      });
      this.expandedElements = [];
      this.expandedElements = allRows;
      // Atualize a propriedade que controla a exibição da sub-tabela
      this.subTableOpen = this.expandedElements.length > 0;
      // Verifique se há alguma linha expandida
    } else {
      this.expandedElements = [];
      this.subTableOpen = false;
    }
  }

  add(event: any, subTable?): void {
    // Cria uma cópia do evento
    const duplicatedEvent = { ...event };

    // Torna as chaves vazias
    this.makeKeysEmpty(duplicatedEvent);

    // Adiciona a chave 'edit' com o valor true
    duplicatedEvent["edit"] = true;

    // Encontra o índice do evento original na array
    // let index = this.dataSource?.external.rows.findIndex(item => item === event);
    const index = this.data.findIndex((item) => item === event);

    // Adiciona a cópia do evento na posição seguinte
    // this.dataSource?.external.rows.splice(index + 1, 0, duplicatedEvent);
    this.data.splice(index + 1, 0, duplicatedEvent);

    // Atualiza o contador
    // this.dataSource['external']['count'] = this.dataSource?.external.rows.length;

    // Atualiza a fonte de dados
    this.dataSource.loadRequestOrder(this.data);

    //Emitir novos valores
    this.issueNewData();
  }

  makeKeysEmpty(object) {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (typeof object[key] === "object" && object[key] !== null) {
          this.makeKeysEmpty(object[key]);
        } else {
          if (typeof object[key] === "string" && regex.test(object[key])) {
            object[key] = new Date().toISOString();
          } else {
            object[key] = typeof object[key] === "number" ? 0 : "-";
          }
        }
      }
    }
  }

  issueNewData() {
    const newValue = [];
    this.data.forEach((item, index) => {
      if (item.edit) {
        newValue.push({ ...item });

        newValue.forEach((item) => {
          delete item.edit;
        });
      }
    });

    this.getAdd.emit(newValue);
  }

  setAdd(indexRow) {
    this.issueNewData();
  }

  deleteAdd(row, dataIndex?) {
    let data;
    if (row?.edit) {
      data = this.data.filter((item, index) => index !== dataIndex);
      this.dataSource.loadRequestOrder(data);
      this.data = data;
    }

    this.issueNewData();
  }

  getSuffix(column, element) {
    if (column.suffixReference) {
      return ` ${element[column.suffixReference]}`;
    }

    return column?.suffixOrPrefixInterface?.single
      ? column?.suffixOrPrefixInterface?.single
      : "";
  }

  handleDisable(element) {
    return element["disableInput"] === true;
  }

  disableSelect(element) {
    return element["disableSelect"] === true;
  }

  tooltipLine(element) {
    return element["tooltipMsg"] || "";
  }

  getSubtitle(row: string) {
    let title = ''
    this.externalData[row].forEach(value => {
      title += ` ${value} horas;`;
    })
    return title
  }

  setIndicatorTooltipMessage(color) {
    if (color === 'green') {
      return 'Cerca de la entrega'
    } else if (color === 'yellow') {
      return 'Lejos de la entrega'
    }
    return 'En atraso'

  }
}
