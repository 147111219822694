import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyToastService {

  toastButtonClick = new EventEmitter();

  emitButtonClick(value:string){
    this.toastButtonClick.emit(value)
  }

}
