import { Component, Inject,  ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-modal-loading',
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.scss']
})
export class ModalLoadingComponent {
  @ViewChild('loading') loading: LoadingComponent;

  constructor(public dialogRef: MatDialogRef<ModalLoadingComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  close() {
    this.dialogRef.close();
  }

  handleClick(event) {
    this.dialogRef.close();
  }

  stopPropagate(event : Event) {
    event.stopPropagation();
    this.dialogRef.close(true)
  }

  handleKeyDown(event){}
}
