import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

// Services
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly urlsWithoutAuth: string[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly toastr: ToastrService,
  ) {
    this.urlsWithoutAuth = [
      environment.apiUrl + '/auth'
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.getAuthorizationToken();
    let request: HttpRequest<any> = req;
    if (this.urlsWithoutAuth.indexOf(request.url) === -1) {
      request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
      return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
    } else {
      return next.handle(request);
    }
  }

  public handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.toastr.show('¡Credenciales incorrectas!', '¡Error al acceder!', {
        timeOut: 5000,
        messageClass: 'error',
        titleClass: JSON.stringify({})
      });
      sessionStorage.clear();
      localStorage.clear();
      this.dialog.closeAll();
      this.router.navigate(['/autenticacao/login']);
      return of(err.message);
    }
    return throwError(err);
  }
}
